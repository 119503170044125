import React, { useState, useContext } from "react"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom"
import { P, H1, H2, Button, Input, Form, BodyWrapper, Spinner, CenteredDiv } from "../components"
import firebase from "../firebase.js"
import "firebase/database"
import { Card, CardContent, DialogTitle } from "@material-ui/core"
import styled from "styled-components"
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import { Checkmark } from 'react-checkmark'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import Fab from '@material-ui/core/Fab'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Slider from '@material-ui/core/Slider'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

class Cart extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      GBItems: [],
      GBQID: "GBQ" + Math.floor(100000000 + Math.random() * 900000000),
      OTPCode: Math.floor(1000 + Math.random() * 9000),
      inputCode: "",
      GrandTotal: 0,
      Quantity: "0",
      emptyCart: false,
      buyNow: false,
      deliveryDetailsIncomplete: false,
      supplyCustomerInfo: false,
      ProductID: "",
      userID: "CS" + Math.floor(100000000 + Math.random() * 900000000),
      address: "",
      neighborhood: "",
      customerName: "",
      customerType: "",
      ProductName: "",
      ProductPrice: "",
      GBID: "",
      pNo: "",
      qtySold: 0,
      redirect: false,
      supplyDeliveryDetails: false,
      imgIndex: { prodID: "", value: 0 },
      POD: false,
      codeError: false,
      orderAlreadyCreated: false,
      refresh: false,
      retrieving: false,
      supplyCustomerInfo: false,
      checkRecord: false,
      confirmGBDetails: false,
      detailsFound: false,
      detailsNotFound: false,
      requestSent: false,
      returnToDB: false,
      displayPopUp: true,
      Paid: false
    }

  }

  componentWillMount = () => {

    const date = new Date()

    const yesterdate = new Date()

    yesterdate.setDate(yesterdate.getDate() - 1)

    const StartToday = yesterdate.getFullYear() +
      '-' +
      ((yesterdate.getMonth() + 1) > 9 ? (yesterdate.getMonth() + 1) : '0' + (yesterdate.getMonth() + 1)) +
      '-' +
      (yesterdate.getDate() > 9 ? (yesterdate.getDate()) : '0' + (yesterdate.getDate())) +
      'T00:00'

    const today = date.getFullYear() +
      '-' +
      ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? (date.getDate()) : '0' + (date.getDate())) +
      'T' +
      (date.getHours() > 9 ? (date.getHours()) : '0' + (date.getHours())) +
      ":" +
      (date.getMinutes() > 9 ? (date.getMinutes()) : '0' + (date.getMinutes()))

    const yesterday = yesterdate.getFullYear() +
      '-' +
      ((yesterdate.getMonth() + 1) > 9 ? (yesterdate.getMonth() + 1) : '0' + (yesterdate.getMonth() + 1)) +
      '-' +
      (yesterdate.getDate() > 9 ? (yesterdate.getDate()) : '0' + (yesterdate.getDate())) +
      'T' +
      (yesterdate.getHours() > 9 ? (yesterdate.getHours()) : '0' + (yesterdate.getHours())) +
      ":" +
      (yesterdate.getMinutes() > 9 ? (yesterdate.getMinutes()) : '0' + (yesterdate.getMinutes()))

    const firestore = firebase.firestore()

    let list = [...this.state.GBItems]

    const fetchData = async () => {

      console.log("ANHAAAA")

      const GBData = await firestore.collection("GroupBuy").where("EndDate", ">", today)

      GBData.get().then((doc) => {

        doc.forEach((record) => {

          const { StartDate, EndDate, ProductID, TargetQty, SoldQty, Leaders, POD, Price } = record.data()

          if (today > StartDate && SoldQty < TargetQty) {
            if (Leaders[0] === this.props.match.params.leaderID) {

              firebase.database().ref('countDown/' + record.id).on('value', (snapshot) => {

                if (snapshot.exists()) {

                  list = []
                  const time = snapshot.val()


                  const product = firestore.collection("Products").doc(ProductID)

                  product.get().then((doc) => {

                    const { Name, Description, ImgUrl, ImgUrls, Quantities, OriginalPrice, QtySold, LimitedQty, GBLimit } = doc.data()

                    const obj = {
                      key: doc.id,
                      gbID: record.id,
                      name: Name,
                      desc: Description,
                      imgUrl: ImgUrl,
                      imgUrls: ImgUrls,
                      qtySold: SoldQty,
                      targetQty: TargetQty,
                      LimitedQty: LimitedQty,
                      start: StartDate,
                      end: EndDate,
                      hours: time.hours,
                      minutes: time.minutes,
                      totalHours: time.totalHours,
                      totalMinutes: time.totalMinutes,
                      price: Price,
                      originalPrice: OriginalPrice,
                      POD: POD,
                      GBLimit: GBLimit,
                      QtySold: QtySold,
                      array: Quantities.split(",")
                    }

                    checkTodaysQueues(obj)

                  })
                }
              })
            }
          }

        })
      })
    }

    const updateUserID = () => {

      try {

        if (this.props.location.state.userID !== undefined) {

          console.log("ID FOUND", this.props.location.state.userID)


          firestore.collection("Customers").doc(this.props.location.state.userID).get().then(doc => {
            const { PhoneNumber, Name, Type, Neighborhood, Address } = doc.data()

            if (Address === "" || Neighborhood === "" || Type === "End-Customer") {
              this.setState({
                userID: this.props.location.state.userID,
                pNo: PhoneNumber,
                customerName: Name,
                address: Address,
                neighborhood: Neighborhood,
                customerType: Type,
                deliveryDetailsIncomplete: true
              })
            }
          })

        } else if (this.props.location.state.userID !== "") {

          this.setState({
            userID: this.props.location.state.userID
          })

        } else {
          console.log("ID LOST")
        }

      } catch (error) {
        console.log(error)
        console.log("ID LOST")
      }
    }

    const checkTodaysQueues = (obj) => {

      try {
        firestore.collection("GBQueue")
          .where("CustomerID", "==", this.props.location.state.userID)
          .where("CreatedAt", ">=", StartToday)
          .where("ProductID", "==", obj.key)
          .where("Processed", "==", true)
          .get()
          .then((data) => {
            if (data.empty) {

              console.log("NOT PARTICIPATED IN THIS GROUPBUY")
              list.push(obj)

              this.setState({
                GBItems: list
              })
            } else {
              console.log("ALREADY PARTICIPATED IN THIS GROUP BUY")
            }
          })

      } catch (error) {
        console.log(error)
      }

    }

    fetchData()
    updateUserID()

  }

  render() {

    const leaderID = this.props.match.params.leaderID

    const imgStyle = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
      maxHeight: "350px",
      top: -100
    }

    const rowStyle = {
      textAlign: "center",
      width: "100%",
      marginTop: "auto"
    }

    const price = {
      textAlign: "right",
      width: "1%",
      color: "green",
      height: "5px",
      padding: "0px"
    }

    const handleCheck = (event) => {
      this.setState({
        Quantity: event.target.value
      })
    }

    const handleSelectGBItem = (e, prodID, gbID, qtySold, name, price, pod) => {
      const firestore = firebase.firestore()

      const fetchData = async () => {

        const myProduct = firestore.collection("Products").doc(prodID)
        const doc = await myProduct.get()
        if (!doc.exists) {
          console.log('No such document!')
        } else {

          if (this.state.Quantity === "0") {
            alert("Select Quantity")
          } else {

            const product = doc.data()

            const total = parseInt(price) * parseInt(this.state.Quantity)
            // console.log('Document data:', product["Price"]);
            const itemRef = firebase.database().ref('shoppingCarts/' + this.state.GBQID)


            itemRef.child(prodID).once('value').then((snapshot) => {
              if (snapshot.exists()) {
                itemRef.child(prodID).set({
                  productDesc: product["Description"],
                  productID: product["ProductID"],
                  productPrice: parseInt(price),
                  quantity: this.state.Quantity,
                  total: total
                })
                itemRef.child("grandTotal").set(parseInt(total))
                console.log("Item exists")

              } else {
                itemRef.child(prodID).set({
                  productDesc: product["Description"],
                  productID: product["ProductID"],
                  productPrice: parseInt(price),
                  quantity: this.state.Quantity,
                  total: total
                })

                itemRef.child("grandTotal").set(parseInt(total))

                console.log(prodID, " Added")
                console.log(this.state.GBQID)

                console.log("YOW")

                this.setState({
                  GBID: gbID,
                  POD: pod,
                  redirect: true
                })

              }
            })

          }

        }
      }

      fetchData()
    }

    // const PaymentPopUP = () => {
    //   return(

    //   )
    // }


    const cart = () => {

      const floatingButton2 = {
        margin: 0,
        top: 80,
        left: 20,
        bottom: 'auto',
        right: 'auto',
        position: 'fixed',
        zIndex: 1
      }

      const floatingButtonAdd2 = {
        marginTop: 10,
        position: "absolute",
        marginBottom: 10,
        right: 0,
        left: 'auto',
        marginLeft: 20,
        marginRight: 20,
        backgroundColor: "#fe7c19"
      }

      const ImgCard = styled.div`
          height: 350px;
        `

      const handleSelectNextImg = (e, key, length) => {

        if (length !== 1) {

          if (this.state.imgIndex.value + 1 < length && this.state.imgIndex.prodID === key) {

            this.setState({
              imgIndex: { prodID: key, value: ++this.state.imgIndex.value }
            })

          } else if (this.state.imgIndex.value + 1 === length && this.state.imgIndex.prodID === key) {

            this.setState({
              imgIndex: { prodID: key, value: 0 }
            })


          } else {

            this.setState({
              imgIndex: { prodID: key, value: 1 }
            })
          }

        }

      }

      const handleSelectPrevImg = (e, key, length) => {

        if (length !== 1) {

          if (this.state.imgIndex.prodID === key) {

            if (this.state.imgIndex.value === 0) {
              this.setState({
                imgIndex: { prodID: key, value: length - 1 }
              })
            } else {

              this.setState({
                imgIndex: { prodID: key, value: --this.state.imgIndex.value }
              })
            }

          } else {

            this.setState({
              imgIndex: { prodID: key, value: 1 }
            })
          }
        }

      }

      const promoPopUp = () => {

        const firestore = firebase.firestore()

        const loader = {
          margin: 0,
          top: "auto",
          right: "auto",
          bottom: "50%",
          left: "auto",
          position: "fixed",
          zIndex: 1,
        }

        const imgStyle = {
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "100%",
          maxHeight: "500px",
          top: -100,
        }

        const handleUpdateDownload = () => {
          firestore
            .collection("AppResponses")
            .doc(this.props.location.state.userID)
            .get()
            .then((doc) => {

              if (doc.exists) {

                const { download } = doc.data()

                firestore
                  .collection("AppResponses")
                  .doc(this.props.location.state.userID)
                  .update({
                    download: parseInt(download) + 1
                  })

              } else {

                firestore
                  .collection("AppResponses")
                  .doc(this.props.location.state.userID)
                  .set({
                    notNow: 0,
                    download: 1
                  })

              }
            })
        }

        const handleCloseGBInfo = () => {

          firestore
            .collection("AppResponses")
            .doc(this.props.location.state.userID)
            .get()
            .then((doc) => {

              if (doc.exists) {

                const { notNow } = doc.data()

                firestore
                  .collection("AppResponses")
                  .doc(this.props.location.state.userID)
                  .update({
                    notNow: parseInt(notNow) + 1
                  })

              } else {

                firestore
                  .collection("AppResponses")
                  .doc(this.props.location.state.userID)
                  .set({
                    notNow: 1,
                    download: 0
                  })

              }
            })
          this.setState({
            displayPopUp: false
          })
        }

        return (
          <Dialog
            open={this.state.displayPopUp}
            onClose={handleCloseGBInfo}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>
              <H2>Download our App today!</H2>
            </DialogTitle>
            <DialogContent>

              <div>
                <a href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1" target="_blank">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/utils%2FWhatsApp%20Image%202023-05-13%20at%2007.35.49.jpeg?alt=media&token=bc11e787-23ce-4c51-a3a8-5b911f7e6cfd"
                    style={imgStyle}
                    onClick={() => { handleUpdateDownload() }}
                  />
                </a>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseGBInfo}>NOT NOW</Button>
              <Button onClick={() => { handleUpdateDownload() }}>
                <a href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1" target="_blank">
                  DOWNLOAD NOW
                </a>
              </Button>
            </DialogActions>
          </Dialog>
        )
      }

      const bannerr = () => {

        const banner2 = {
          position: "relative",
          backgroundColor: "#efa83d",
          left: 0,
          width: "100%",
          textAlign: "center",
        }

        const welcome = {
          fontSize: "5px"
        }

        return (
          <>
            <div style={banner2}>
              <br />
              <H1 style={welcome}>Group Buy!</H1>
              <H2>Buy with friends to get even cheaper prices </H2>
              <P><i>(PS. We are closed on Sundays and public holidays)</i></P>
            </div>
          </>
        )
      }

      return (
        <BodyWrapper>
          {bannerr()}
          {promoPopUp()}
          <Fab onClick={e => { this.setState({ returnToDB: true }) }} color="primary" style={floatingButton2}>
            <HomeOutlinedIcon fontSize="large" />
          </Fab>
          <div class='row'>
            {this.state.GBItems.map((data, i) => {
              return (
                <div key={`${data.name}-${i}`} class='col-md-6'>
                  <Card style={{ paddingTop: "5px", paddingBottom: "5px", width: "100%", height: "750px" }}>
                    <CardContent>
                      <ImgCard>
                        <Fab variant="extended" size="small" style={floatingButtonAdd2}>
                          <LocalOfferIcon />SAVE {data.originalPrice - data.price}sh
                        </Fab>
                        {this.state.imgIndex.prodID === data.key ?
                          <img src={data.imgUrls[this.state.imgIndex.value]} style={imgStyle} />
                          :
                          <img src={data.imgUrls[0]} style={imgStyle} />
                        }
                      </ImgCard>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NavigateBeforeIcon onClick={e => { handleSelectPrevImg(e, data.key, data.imgUrls.length) }} fontSize="large" />

                        {data.LimitedQty ?
                          <>
                            <span style={{ flexGrow: 1 }} />
                            <H2 style={{ textAlign: "center" }}><p style={{ color: "red" }}>Limited quantity available!</p></H2>
                            <span style={{ flexGrow: 1 }} />
                          </>
                          :
                          <span style={{ flexGrow: 1 }} />
                        }
                        <NavigateNextIcon onClick={e => { handleSelectNextImg(e, data.key, data.imgUrls.length) }} fontSize="large" />
                      </div>
                      <hr />
                      <Slider
                        value={data.qtySold}
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        // disabled
                        min={0}
                        max={data.targetQty}
                        marks={[{ label: "0: " + data.originalPrice + "sh", value: 0 }, { label: data.targetQty.toString() + ": " + data.price + "sh", value: data.targetQty }]}
                        valueLabelDisplay="on"
                        color="primary"
                      />
                      <div class="row">
                        <div class="col-xs-9"><P><b>{data.desc}</b></P></div>
                        <div class="col-xs-3">
                          <P><b>{data.price}sh</b></P>
                          {data.originalPrice === "0" ?
                            <P>-</P>
                            :
                            <P style={{ color: "red" }}><del> {data.originalPrice}sh </del></P>
                          }
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-9">
                          <FormLabel>Select Quantity</FormLabel>
                          <RadioGroup
                            name="spacing"
                            aria-label="spacing"
                            defaultValue="0"
                            onChange={handleCheck}
                            row
                          >
                            {data.array.map((value) => (
                              <FormControlLabel
                                key={value}
                                value={value.toString()}
                                control={<Radio />}
                                label={value.toString()}
                              />
                            ))}
                          </RadioGroup>
                        </div>
                        <div class="col-xs-3">
                          <div style={{ backgroundColor: "red" }}>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <P style={{ color: "white" }}>Time Left</P>
                            </div>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <H2 style={{ color: "white" }}>{data.hours}h : {data.minutes}min</H2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Button style={{ width: "100%" }} onClick={e => handleSelectGBItem(e, data.key, data.gbID, data.qtySold, data.desc, data.price, data.POD)}>SELECT </Button>
                      <br />
                      <P><b>{data.qtySold}/{data.targetQty}</b> {data.name} already bought</P>
                    </CardContent>
                  </Card>
                  <br />
                </div>
              )
            })}
          </div>
        </BodyWrapper>
      )
    }


    try {

      if (this.props.location.state.userID !== undefined) {

        console.log("IDDDDDDDD: ", this.props.location.state.userID)

        if (this.state.redirect) {
          return <Redirect
            to={{
              pathname: "/paymentDetails",
              state: {
                cartID: this.state.GBQID,
                leaderID: this.props.match.params.leaderID,
                referralID: "",
                POD: this.state.POD,
                signedUp: this.props.location.state.signedUp,
                userID: this.props.location.state.userID,
                groupBuy: true,
                gbID: this.state.GBID
              }
            }}
          />

        } else if (this.state.returnToDB) {

          return <Redirect
            push
            to={{
              pathname: "/shop/" + this.props.match.params.leaderID,

              state: {
                userId: this.props.location.state.userID,
                userPNo: this.props.location.state.userPNo,
                userName: this.props.location.state.userName,
                cartItemsQty: this.props.location.state.cartItemsQty
              }
            }}
          />

        } else {

          return cart()

        }

      } else {

        console.log("YOWW")

        return <Redirect
          to={{
            pathname: "/signIn/" + this.props.match.params.leaderID,
            state: {
              groupBuy: true
            }
          }}
        />

      }

    } catch (error) {

      console.log("YOW 2: ")

      console.log(error)

      return <Redirect
        to={{
          pathname: "/signIn/" + this.props.match.params.leaderID,
          state: {
            groupBuy: true
          }
        }}
      />

    }


  }
};

export default Cart
