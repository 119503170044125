import React, { useState, useContext } from "react";
import { P, H1, Input, Form, BodyWrapper, Spinner, CenteredDiv } from "../components";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
  } from "react-router-dom";

import { MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import firebase from "../firebase.js";
import { makeStyles } from '@material-ui/core/styles';
import "firebase/database";
import { withRouter } from 'react-router-dom';
import { Button } from "@material-ui/core";

class VerifyOTP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          OTPCode: Math.floor(1000 + Math.random() * 9000),
          inputCode: "",
          PNo: "",
          codeError: false,
          loading: true

        };


    }

    

    componentWillMount(){

        const fetchData = async () => {

            console.log("INV ID: ", this.props.location.state.invoiceID)
    
            const firestore = firebase.firestore();
    
            const data = await firestore.collection("Invoice").doc(this.props.location.state.invoiceID)
            
            data.get().then((doc) => {
    
              if(doc.exists){
    
                const { fullNo } = doc.data()

                this.setState({
                    PNo: "+"+fullNo
                }, () => {
                    this.SendOTP()
                })

    
              }
    
            })
        }

        fetchData()

        
    }


    SendOTP = () => {
        const url = "https://api.tushop.io/api/v1/sms/OTP";

        fetch(url, 
        {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                OTP: this.state.OTPCode,
                to: this.state.PNo,
                CLID: this.props.location.state.leaderID,
            })
        }
        )
        .then(response => {
            if (response.status === 201) {
                return response.json()
            } else {
                throw new Error('Something went wrong on api server!');
            }
        })
        .then(response => {

            console.debug(response);

            this.setState({
                loading: false
            })
        }).catch(error => {
            console.error(error);
        });
    }

    handleChange = (e) => {
        this.setState({
            inputCode: e.target.value
        }, () => {
            if(this.state.inputCode.length === 4 && this.state.inputCode !== this.state.OTPCode){

                this.setState({
                    codeError: true
                })

            }else{

                this.setState({
                    codeError: false
                })
            }
        })
    }

    ResendOTP = () => {

        this.setState({

            OTPCode: Math.floor(1000 + Math.random() * 9000)
        }, () => {
            this.SendOTP()
        })

    }




    verifyOTPScreen = () => {

        return (
            <BodyWrapper>
                {this.state.loading?
                    <CenteredDiv vertical>
                        <Spinner large secondary style={{ margin: 0, top: 'auto', right: 'auto', bottom: "50%", left: 'auto', position: 'fixed', zIndex: 1 }}/>
                    </CenteredDiv>
                :
                    <>
                    </>
                }
                <H1>Verify OTP</H1>

                <P>A four digit verification code has been sent to {this.state.PNo}</P>

                <P><Button onClick={e => this.ResendOTP()}>CLICK HERE</Button> if you did not recieve an SMS</P>

                <P>Input the code below to proceed.</P>

                <TextField
                    id="standard-basic"
                    style={{width: "100%", marginTop: "20px", alignItems: "center"}}
                    error={this.state.codeError}
                    inputProps={{ maxLength: 4 }}
                    helperText={this.state.codeError? 'Invalid Code!' : ' '}
                    onChange={e => this.handleChange(e)}
                    name="OTP"
                    placeholder="OTP"
                    variant="standard" />
            </BodyWrapper>
        );



    }

    

    render(){

        if(this.state.inputCode === this.state.OTPCode.toString()){

            return <Redirect
                        push
                        to={{
                            pathname: "/paymentPage",
                            state:  { 
                              invoiceID: this.props.location.state.invoiceID,
                              orderID: this.props.location.state.orderID,
                              returnC: this.props.location.state.returnC,
                              userID: this.props.location.state.userID,
                              referralID: this.props.location.state.referralID
                            }
                        }}
                    />

        }else{

            return this.verifyOTPScreen()      
        
        }

    }
};

export default VerifyOTP;
