import React, { useState, useContext, useEffect } from "react"
import { H1, P, H2, Button, BodyWrapper, Spinner, CenteredDiv } from "../components"
import { ToastContext } from "../contexts/toastContext"
import {
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom"
import Badge from '@material-ui/core/Badge'
import { Card, CardContent } from "@material-ui/core"
import styled from "styled-components"
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import TableRow from '@material-ui/core/TableRow'
import Fab from '@material-ui/core/Fab'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import { UserContext } from "../contexts/userContext"
import firebase from "../firebase.js"
import "firebase/firestore"
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

// console.log("PRODUCT ID: ", prodID)

// console.log("GROUP BUY ID: ", gbID)

const Dashboard = (props) => {

  const [items, setItems] = useState([])
  const [quantity, setQuantity] = useState("0")
  const [cartItems, setCartItems] = useState(0)
  const [imgIndex, setImgIndex] = useState({ prodID: "", value: 0 })

  const [openCart, setOpenCart] = useState(false)
  const [proceedToSignUp, setProceedToSignUp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [POD, setPOD] = useState(false)

  const [cartId, setCartId] = useState("CTBD" + Math.floor(100000000 + Math.random() * 900000000))
  const [customerID, setCustomerID] = useState("")




  const { sendMessage } = useContext(ToastContext)

  const { userState, userDispatch } = useContext(UserContext)

  const firestore = firebase.firestore()

  useEffect(() => {


    const fetchData = async (regDate) => {
      const data = await firestore.collection("Bundles").orderBy("date_updated", "desc").get()

      const list = []

      data.forEach(doc => {
        const { Name, Description, ImgUrl, Price, QtySold, TargetQty, productsArray } = doc.data()

        const remainingQty = parseInt(TargetQty) - parseInt(QtySold)


        console.log(remainingQty)
        if (remainingQty > 0) {

          let origi = 0

          productsArray.forEach((data) => {

            origi = origi + parseInt(data.productPrice) * parseInt(data.quantity)

          })

          list.push({
            key: doc.id,
            name: Name,
            desc: Description,
            imgUrl: ImgUrl,
            qtySold: QtySold,
            price: Price,
            originalPrice: origi,
            TargetQty,
            productsArray,
            remainingQty
          })
        }

      })

      setItems(list)

    }

    const setData = () => {

      try {

        setCustomerID(props.location.state.userId)
        setPOD(props.location.state.POD)

      } catch (error) {
        setProceedToSignUp(true)
      }

    }

    fetchData()
    setData()

  }, [])

  const handleOpenCart = () => {

    if (cartItems > 0) {

      setOpenCart(true)

    } else {
      sendMessage("Empty Cart! Select Items to proceed.")
    }



  }

  const handleCheck = (event, key) => {
    setQuantity(event.target.value)

    handleAddtoCart(event, key, event.target.value)

  }

  const handleAddtoCart = (e, key, quantity) => {

    setLoading(true)

    console.log("Hellooo")

    console.log(key, cartId)

    window.localStorage.setItem("Tour", true)
    const firestore = firebase.firestore()

    const fetchData = async () => {

      const myProduct = firestore.collection("Products").doc(key)
      const doc = await myProduct.get()

      if (!doc.exists) {
        console.log('No such document!')
      } else {

        if (quantity === "0") {
          sendMessage("Select Quantity")
          setLoading(false)
        } else {

          const product = doc.data()

          const total = product["Price"] * quantity
          // console.log('Document data:', product["Price"]);
          const itemRef = firebase.database().ref('shoppingCarts/' + cartId + '/' + key)

          itemRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
              itemRef.set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: product["Price"],
                quantity: quantity,
                total: total
              })
              console.log("Item exists")
              setLoading(false)

              sendMessage("Item Quantity Updated!")

              setQuantity("0")
            } else {
              itemRef.set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: product["Price"],
                quantity: quantity,
                total: total
              })

              setCartItems(cartItems + 1)

              setLoading(false)

              sendMessage("Item added to cart!")

              setQuantity("0")

            }
          })

        }

      }
    }

    fetchData()
  }

  const dashboard = () => {
    const imgStyle = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
      maxHeight: "350px",
      top: -100
    }

    const ImgCard = styled.div`
      height: 350px;
    `

    const banner = {
      position: "relative",
      backgroundColor: "#efa83d",
      left: 0,
      width: "100%",
      textAlign: "center",
    }

    const table = {
      paddingTop: "60px"
    }

    const price = {
      textAlign: "right",
      width: "1%",
      color: "green",
      height: "5px",
      padding: "0px"
    }

    const floatingButton1 = {
      margin: 0,
      top: 80,
      right: 20,
      bottom: 'auto',
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const floatingButton2 = {
      margin: 0,
      top: 80,
      left: 20,
      bottom: 'auto',
      right: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const floatingButtonAdd2 = {
      marginTop: 10,
      position: "absolute",
      marginBottom: 10,
      right: 0,
      left: 'auto',
      marginLeft: 20,
      marginRight: 20,
      backgroundColor: "#fe7c19"
    }

    const loader = {
      margin: 0,
      top: 'auto',
      right: 'auto',
      bottom: "50%",
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const welcome = {
      fontSize: "5px"
    }

    const load = () => {
      if (loading) {
        return (
          <CenteredDiv vertical>
            <Spinner large secondary style={loader} />
          </CenteredDiv>
        )
      }
    }

    const bannerr = () => {
      return (
        <div style={banner}>
          <br />
          <H1 style={welcome}>BUNDLE BUY!</H1>
          <br />
        </div>
      )
    }

    const floatingButtons = () => {

      return (
        <>
          <Fab onClick={handleOpenCart} color="primary" style={floatingButton1}>
            <Badge color="secondary" badgeContent={cartItems.toString()}>
              <ShoppingCartOutlinedIcon fontSize="large" />
            </Badge>
          </Fab>

          <Fab onClick={e => { window.history.back() }} color="primary" style={floatingButton2}>
            <HomeOutlinedIcon fontSize="large" />
          </Fab>
        </>
      )

    }

    const handleBuyBundle = (e, array, origi, price, name) => {

      setLoading(true)

      let cartArray = []

      const discount = parseInt(origi) - parseInt(price)

      cartArray.push({
        productDesc: name,
        productID: "T883215044",
        productPrice: 0,
        quantity: 1,
        total: 0,
      })

      array.forEach((data) => {
        cartArray.push({
          productDesc: data.productDesc,
          productID: data.productID,
          productPrice: data.productPrice,
          quantity: data.quantity,
          total: parseInt(data.productPrice) * parseInt(data.quantity),
        })
      })

      cartArray.push({
        productDesc: "Bundle Discount",
        productID: "T726177843",
        productPrice: -discount,
        quantity: 1,
        total: -discount,
      })

      createCart(cartArray)

    }

    const createCart = (array) => {

      array.forEach((data) => {
        const key = data.productID

        const itemRef = firebase.database().ref('shoppingCarts/' + cartId + '/' + key)

        itemRef.set(data)

      })

      setLoading(false)
      setOpenCart(true)

    }



    return (

      <>
        <BodyWrapper>
          {load()}
          {bannerr()}
          {floatingButtons()}
          <div class='row'>
            {items.map((data, i) => {
              return (
                <div key={`${data.name}-${i}`} class='col-md-6'>
                  <Card style={{ paddingTop: "5px", paddingBottom: "5px", width: "100%" }}>
                    <CardContent>
                      <div class="row">
                        <div class="col-xs-12"><P style={{ textAlign: "center" }}><b>{data.name}</b></P></div>
                      </div>
                      <ImgCard>
                        <Fab variant="extended" size="small" style={floatingButtonAdd2}>
                          <LocalOfferIcon />SAVE {data.originalPrice - data.price}sh
                        </Fab>
                        <img src={data.imgUrl} style={imgStyle} />
                      </ImgCard>
                      <hr />
                      <div class="row">
                        <div class="col-xs-12"><P><b>{data.desc}</b></P></div>
                      </div>
                      {data.productsArray.map((data2, i) =>
                        <div class="row">
                          <div class="col-xs-10"><P><b>* </b>{data2.quantity} X {data2.productDesc}</P></div>
                          <div class="col-xs-2"><P style={{ textAlign: "right" }}><b>{parseInt(data2.productPrice) * parseInt(data2.quantity)}sh</b></P></div>
                        </div>
                      )}
                      <div class="row">
                        <div class="col-xs-10"><P style={{ color: "red", textAlign: "right" }}><b><del>WAS</del></b></P></div>

                        <div class="col-xs-2">
                          {data.originalPrice === "0" ?
                            <P>-</P>
                            :
                            <P style={{ color: "red" }}><del> {data.originalPrice}sh </del></P>
                          }
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-10"><P style={{ color: "green", textAlign: "right" }}><b>BUNDLE PRICE</b></P></div>

                        <div class="col-xs-2">
                          <P style={price}><b>{data.price}sh</b></P>
                        </div>
                      </div>
                      <Button onClick={e => handleBuyBundle(e, data.productsArray, data.originalPrice, data.price, data.name)}>BUY BUNDLE</Button>
                    </CardContent>
                  </Card>
                  <br />
                </div>
              )
            })}
          </div>
        </BodyWrapper>

      </>
    )
  }

  if (openCart) {

    return <Redirect
      push
      to={{
        pathname: "/shoppingCart",
        state: {
          cartItemsQty: cartItems,
          cartID: cartId,
          leaderID: props.match.params.leaderID,
          POD: props.location.state.POD,
          userID: props.location.state.userId
        }
      }}
    />
  } else if (proceedToSignUp) {

    console.log("SIGN UP")
    return <Redirect
      push
      to={{
        pathname: "/signin/" + props.match.params.leaderID,
        state: {
          groupBuy: false
        }
      }}
    />

  } else {
    return dashboard()
  }


}

export default Dashboard