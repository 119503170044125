import React, { useState, useContext } from "react"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  Link
} from "react-router-dom"
import { P, H1, H2, Button, Input, Form, BodyWrapper } from "../components"
import TextField from '@material-ui/core/TextField'
import firebase from "../firebase.js"
import { CardContent, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import Fab from '@material-ui/core/Fab'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import Paper from "@material-ui/core/Paper"

import "firebase/database"

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      leaderID: "",
      zones: [],
      addresses: [],
      zoneSelected: "",
      redirectToSignIn: false,
      newUser: true,
      phoneNumber: ""
    }
  }

  componentWillMount() {

    let array = []

    const db = firebase.firestore()

    db.collection("Zones").get().then((data) => {
      data.forEach((doc) => {
        const { Areas } = doc.data()

        const obj = { id: doc.id, area: Areas }

        array.push(obj)

      })
    }).then(() => {
      this.setState({
        zones: array
      })
    })


  }



  render() {

    const db = firebase.firestore()

    const btn = { paddingLeft: "5px", paddingRight: "5px" }

    const welcome = () => {

      const handleClickZone = (e, id) => {

        let array = []
        const array2 = []

        db.collection("CommunityLeaders").where("Zone", "==", id).where("Verified", "==", true).get().then((data) => {
          data.forEach((doc) => {
            const { Address } = doc.data()

            const address = Address.split(" ")[0]

            if (array[address] === undefined) {
              array[address] = { address: Address, id: [doc.id] }
            } else {
              array[address].id.push(doc.id)
            }

          })
        }).then(() => {

          for (var key in array) {

            const obj = {
              ids: array[key].id,
              address: array[key].address
            }

            array2.push(obj)

          }

        }).then(() => {

          this.setState({
            zoneSelected: id,
            addresses: array2
          })

        })

      }

      return (

        <BodyWrapper>
          <H1>Welcome</H1>

          <P><p style={{ color: "blue" }} onClick={e => { this.setState({ newUser: false }) }}>I already have an account</p> New to Tushop? </P>

          <P>Select the area you live as per the options below</P>

          <TableContainer component={Paper} >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell><H2>Area</H2></TableCell>
                  <TableCell><H2>Select</H2></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.zones.map(data => {
                  return (
                    <TableRow key={data.id}>
                      <TableCell><H2>{data.area}</H2></TableCell>
                      <TableCell><Button style={btn} onClick={(e) => { handleClickZone(e, data.id) }}>SELECT</Button></TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

        </BodyWrapper>
      )
    }

    const CLAddresses = () => {

      const handleClickAddress = (e, ids) => {
        const randomIndex = Math.floor(Math.random() * ids.length)
        this.setState({
          leaderID: ids[randomIndex]
        }, () => {
          this.setState({
            redirectToSignIn: true
          })
        })
      }

      const floatingButton = {
        margin: 0,
        top: 80,
        right: 20,
        bottom: 'auto',
        left: 'auto',
        position: 'fixed',
        zIndex: 1
      }

      const handleGoBack = () => {
        this.setState({
          zoneSelected: "",
          addresses: []
        })
      }

      return (
        <BodyWrapper>
          <H1>Almost there!</H1>
          <Fab
            onClick={handleGoBack}
            color="primary"
            style={floatingButton}
          >
            <HomeOutlinedIcon fontSize="large" />
          </Fab>
          <P>Select the address closest to you</P>

          <TableContainer component={Paper} >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell><H2>Address</H2></TableCell>
                  <TableCell><H2>Select</H2></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.addresses.map(data => {
                  return (
                    <TableRow key={data.id}>
                      <TableCell><H2>{data.address}</H2></TableCell>
                      <TableCell><Button onClick={e => { handleClickAddress(e, data.ids) }} style={btn}>SELECT</Button></TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

        </BodyWrapper>
      )

    }

    const retrieveCL = () => {

      const handlePhoneChange = (e) => {

        if (e.target.value.length === 10) {

          this.setState({
            phoneNumber: e.target.value,
          }, () => {

            const fullNo = "254" + this.state.phoneNumber.substring(1)

            db.collection("Customers").where("PhoneNumber", "==", fullNo).get().then((data) => {
              data.forEach((doc) => {
                const { LeaderID } = doc.data()

                this.setState({
                  leaderID: LeaderID
                }, () => {
                  this.setState({
                    redirectToSignIn: true
                  })
                })
              })
            })

            // }
          })
        } else {
          this.setState({
            phoneNumber: e.target.value,
          })
        }
      }
      return (
        <BodyWrapper>
          <H1>Almost there!</H1>

          <P>Enter your phone number to retrieve your community leader's details </P>

          <TextField
            id="standard-basic"
            style={{ width: "100%", marginTop: "20px" }}
            onChange={e => handlePhoneChange(e)}
            name="phoneNumber"
            placeholder="Phone Number (07xx / 01xx)"
            inputProps={{ maxLength: 10 }}
            error={this.state.pNoError}
            helperText={this.state.pNoError ? 'Input Valid Phone Number!' : ' '}
            variant="standard"
          />

        </BodyWrapper>
      )

    }

    if (!this.state.redirectToSignIn) {

      if (this.state.zoneSelected === "") {

        if (this.state.newUser) {

          return welcome()


        } else {

          return retrieveCL()
        }


      } else {
        return CLAddresses()
      }

    } else {
      return (
        <Redirect
          push
          to={{
            pathname: "/order/" + this.state.leaderID,
          }}
        />
      )
    }

  }
};

export default Home