import React, { Component } from 'react'
import firebase from "../firebase.js";
import "firebase/database";

import ResponsePage from "../containers/PaymentDetails"

export default class Timer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        }
    }

    componentWillMount() {

        const realtime = firebase.database();

        const gameId = this.props.gameId;

        const gameName = this.props.gameName

        const durationMinutes = this.props.minutes

        realtime.ref('gameSession/' + gameId).once('value').then((snapshot) => {
            if (snapshot.exists()){
                const QData = snapshot.val();
            
                if(QData.Hours === "00" && QData.Minutes === "00" && QData.Seconds === "00"){

                    realtime.ref('gameQuiz/' + gameName + '/1').once('value').then((snapshot) => {
                        if (snapshot.exists()){
                            const QData2 = snapshot.val();
                            this.setState({
                                hours: QData2.hours,
                                minutes: QData2.minutes
                            })
                        }else{
                            console.log("Not found!!!!!!!!!!!!", gameName)
                        }
                    });

                }else{
                    this.setState({
                        hours : QData.Hours,
                        minutes: QData.Minutes,
                        seconds: QData.Seconds
                    })
                }
            }

        })
    }
    

    componentDidMount() {

        const gameId = this.props.gameId;

        const gameName = this.props.gameName

        const realtime = firebase.database();

        this.myInterval = setInterval(() => {
            const { seconds, minutes, hours } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))

                realtime.ref('gameSession/' + gameId).update({
                    Seconds: seconds - 1
                })  
            }
            if (seconds === 0 || seconds === "0") {
                if (minutes === 0 || minutes === "00" || minutes === "0") {
                    if (hours === 0 || hours === "00" || hours === "0") {
                        clearInterval(this.myInterval)
                        realtime.ref('gameSession/' + gameId).update({
                            GameFinished: false,
                            GameLost: true
                        })
                    }else {
                        this.setState(({ hours }) => ({
                            hours: hours - 1,
                            minutes: 59,
                            seconds: 59
                        }))

                        realtime.ref('gameSession/' + gameId).update({
                            Hours: hours - 1,
                            Minutes: 59,
                            Seconds: 59
                        })

                    }
                }else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))

                    realtime.ref('gameSession/' + gameId).update({
                        Minutes: minutes - 1,
                        Seconds: 59
                    })
                }
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { hours, minutes, seconds } = this.state
        return (
            <div>
                { (hours === "00" || hours === 0) && (minutes === "00" || minutes === 0) && (seconds === 0 || seconds === "00")
                    ? <h1>Time Is up!</h1>
                    : <h1>Time Remaining: {hours}:{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
                }
            </div>
        )
    }
}