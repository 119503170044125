import React, { useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { UserContext } from '../contexts/userContext'
import { P, H1, H2, Button, Input, Form, BodyWrapper } from '../components'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import firebase from '../firebase.js'
import 'firebase/database'
class Lander extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userID: 'CS' + Math.floor(100000000 + Math.random() * 900000000),
      phoneNumber: '',
      fullNo: '',
      customerName: '',
      firstName: '',
      lastName: '',
      city: 'Nairobi',
      // Switches
      detailsNotFound: false,
      fullName: true,
      shopNow: false,
      groupBuy: false,
      newSignIn: false,
      SignUpOTP: false,
      otpConfirmed: false,
      OTPCode: Math.floor(1000 + Math.random() * 9000),
      DBPin: '0',
      inputPin: '',
      confirmPin: '',
      inputCode: '',
      loading: false,
    }
  }

  componentWillMount() {
    // const savedOTP = window.localStorage.getItem("OTP")
    // this.setState({
    //   inputCode: savedOTP
    // })
  }


  handleSignUp = () => {
    const date = new Date()
    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    const db = firebase.firestore()

    const nameArray2 = this.state.customerName.split(' ')

    const secondName = nameArray2[1]

    if (this.state.firstName === '') {
      alert('Please fill in your name before you proceed')
    } else if (this.state.lastName === '') {
      alert('Please enter your valid last name')
    } else {
      this.setState({ loading: true })
      db.collection('Customers')
        .doc(this.state.userID)
        .set({
          UserID: this.state.userID,
          Name: `${this.state.firstName} ${this.state.lastName}`,
          Address: '',
          City: this.state.city,
          Neighborhood: '',
          PhoneNumber: this.state.fullNo,
          LeaderID: this.props.match.params.leaderID,
          DeliverySMS: false,
          Type: 'End-Customer',
          Referrals: 0,
          LedgerBalance: 0,
          OTP: 0,
          PIN: '0',
          ReferredIDs: [],
          ReferralLink:
            'https://shop.tushop.io/referral/CL735461/' + this.state.userID,
          CreatedAt: today,
          UpdatedAt: today,
        })
        .then(() => {
          this.setState(
            {
              userID: this.state.userID,
              customerName: this.state.customerName,
              fullNo: this.state.fullNo,
              city: this.state.city,
              detailsNotFound: false,
              newSignIn: true,
            },
            () => {
              if (this.props.location.state.groupBuy) {
                this.setState({
                  groupBuy: true,
                })
              } else {
                this.setState({
                  shopNow: true,
                })
              }
            }
          )
        })
    }
  }

  handleCodeSubmit = e => {
    console.log('PNO!', this.state.phoneNumber)

    const firestore = firebase.firestore()

    console.log('SUBMIIIITTTTT!!', this.state.phoneNumber)

    const pNo = this.state.phoneNumber

    const fullNo = '254' + pNo.substring(1)

    const fetchData = async () => {
      console.log('FULL NOOOOO!', fullNo)
      this.setState({ loading: true })
      const data = await firestore
        .collection('Customers')
        .where('PhoneNumber', '==', fullNo)

      data.get().then(doc => {
        console.log('DOC!')
        console.log(this.state.loading)
        if (!doc.empty) {
          doc.forEach(record => {
            const { Name, City, OTP, PIN } = record.data()

            console.log('EXISTS')

            const nameArray = Name.split(' ')

            console.log('NAME ARRAY 0 ', nameArray[0])

            console.log('NAME ARRAY 1', nameArray[1])

            console.log('OYOOOOO', PIN, '0')

            this.setState(
              {
                userID: record.id,
                customerName: Name,
                fullNo: fullNo,
                city: City,
                detailsNotFound: false,
                newSignIn: true,
              },
              () => {
                if (this.props.location.state.groupBuy) {
                  this.setState({
                    groupBuy: true,
                  })
                } else {
                  this.setState({
                    shopNow: true,
                  })
                }
              }
            )
            console.log('Match')
          })
        } else {
          this.setState({
            detailsNotFound: true,
            fullNo: fullNo,
          })
          this.setState({ loading: false })
          console.log('Mismatch')
        }
      })
    }

    fetchData()
  }

  handlePhoneChange = e => {
    if (e.target.value === '') {
      this.setState({
        userID: 'CS' + Math.floor(100000000 + Math.random() * 900000000),
        customerName: '',
        customerType: '',
        city: '',
        address: '',
        neighborhood: '',
        detailsNotFound: false,
      })
    } else if (e.target.value.length === 10) {
      this.setState(
        {
          phoneNumber: e.target.value,
        },
        () => {
          this.handleCodeSubmit()
        }
      )
    } else {
      this.setState({
        phoneNumber: e.target.value,
        customerName: '',
      })
    }
  }

  render() {
    const resendOTP = e => {
      this.setState({ loading: true })
      const firestore = firebase.firestore()

      firestore
        .collection('Customers')
        .doc(this.state.userID)
        .update({
          PIN: '0',
        })
        .then(() => {
          this.setState({ loading: true })
          this.handleCodeSubmit()
        })
    }

    const welcome = () => {
      const banner1 = {
        height: window.innerWidth < 450 ? '45vh' : '100vh',
        marginTop: window.innerWidth < 450 ? '-70px' : '-220px',
        fontSize: '50px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          window.innerWidth < 450
            ? 'url("https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/Landing%20Page%2Flanding%20page%20FA-01.png?alt=media&token=5a488421-f95b-4c30-9913-9d5922ebbd97")'
            : 'url("https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/Landing%20Page%2Flanding%20page%20FA%20desktop-01.png?alt=media&token=a29049b1-37c7-4fa5-9799-1b692160f969")',
        width: '100%',
        textAlign: 'center',
      }

      const signin = {
        height: window.innerWidth < 450 ? '55vh' : '65vh',
        marginTop: window.innerWidth < 450 ? '0' : '-80px',
        textAlign: 'center',
        overflowY: 'scroll',
        paddingBottom: '200px',
      }

      return (
        <div style={{ height: '120vh' }}>
          <div style={banner1}></div>
          <div style={signin}>
            <H1>Sign In</H1>

            <P>Enter phone number to retrieve details</P>

            <TextField
              id="standard-basic"
              style={{ width: '100%', marginTop: '20px' }}
              onChange={e => this.handlePhoneChange(e)}
              name="phoneNumber"
              placeholder="Phone Number (07xx / 01xx)"
              inputProps={{ maxLength: 10 }}
              error={this.state.pNoError}
              helperText={
                this.state.pNoError ? 'Input Valid Phone Number!' : ' '
              }
              variant="standard"
            />
            {!this.state.fullName ? (
              <>
                <TextField
                  id="standard-basic"
                  style={{ width: '100%', marginTop: '20px' }}
                  error={true}
                  helperText={'Please enter your valid first and last name!'}
                  onChange={e =>
                    this.setState({ customerName: e.target.value })
                  }
                  name="customerName"
                  placeholder="Customer Name"
                  value={this.state.customerName}
                  variant="standard"
                />
              </>
            ) : (
              <></>
            )}
            {!this.state.detailsNotFound ? (
              <>

              </>
            ) : (
              <>
                <P>
                  Account does not exist. Please Sign Up to continue shopping
                </P>
                <TextField
                  id="standard-basic"
                  style={{ width: '100%', marginTop: '20px' }}
                  error={this.state.cNameError}
                  helperText={this.state.cNameError ? 'Input Valid Name!' : ' '}
                  onChange={e =>
                    this.setState({
                      firstName: e.target.value,
                      cNameError: false,
                    })
                  }
                  name="firstName"
                  placeholder="First Name"
                  value={this.state.firstName}
                  variant="standard"
                />
                <TextField
                  id="standard-basic"
                  style={{ width: '100%', marginTop: '20px' }}
                  error={this.state.cNameError}
                  helperText={this.state.cNameError ? 'Input Valid Name!' : ' '}
                  onChange={e =>
                    this.setState({
                      lastName: e.target.value,
                      cNameError: false,
                    })
                  }
                  name="lastName"
                  placeholder="Last Name"
                  value={this.state.lastName}
                  variant="standard"
                />
                <Button onClick={this.handleSignUp}>
                  {this.state.loading ? (
                    <CircularProgress
                      size={16}
                      style={{ marginRight: '1em' }}
                      color="#fff"
                    />
                  ) : null}
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </div>
      )
    }

    if (this.state.shopNow) {
      return (
        <Redirect
          push
          to={{
            pathname: '/shop/' + this.props.match.params.leaderID,

            state: {
              userId: this.state.userID,
              userPNo: this.state.fullNo,
              userName: this.state.customerName,
              cartItemsQty: 0,
            },
          }}
        />
      )
    } else if (this.state.groupBuy) {
      return (
        <Redirect
          to={{
            pathname: '/groupBuy/' + this.props.match.params.leaderID,

            state: {
              userID: this.state.userID,
              userPNo: this.state.fullNo,
              userName: this.state.customerName,
              cartItemsQty: 0,
            },
          }}
        />
      )
    } else {
      return welcome()
    }
  }
}

export default Lander
