import React, { useState, useContext, useEffect } from "react"
import {
  H1,
  P,
  H2,
  Button,
  BodyWrapper,
  Spinner,
  CenteredDiv,
} from "../components"
import { ToastContext } from "../contexts/toastContext"
import { UserContext } from '../contexts/userContext'
import { BrowserRouter as Router, Redirect } from "react-router-dom"
import styled, { createGlobalStyle } from "styled-components"
import Badge from "@material-ui/core/Badge"
import Paper from "@material-ui/core/Paper"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Fab from "@material-ui/core/Fab"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import LocalOfferIcon from "@material-ui/icons/LocalOffer"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Joyride from "react-joyride"
import firebase from "../firebase.js"
import "firebase/firestore"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Slider from "@material-ui/core/Slider"
import { Card, CardContent, DialogTitle } from "@material-ui/core"
import Blink from 'react-blink-text'

// console.log("PRODUCT ID: ", prodID)

// console.log("GROUP BUY ID: ", gbID)

const Dashboard = props => {
  const [items, setItems] = useState([])
  const [GBitems, setGBItems] = useState([])
  const [GBAbleitems, setGBAbleitems] = useState([])
  const [GBProduct, setGBProduct] = useState([])
  const [GBID, setGBID] = useState("")
  const [GBQID, setGBQID] = useState(
    "GBQ" + Math.floor(100000000 + Math.random() * 900000000)
  )
  const [skuQttys, setSkuQttys] = useState([])
  const [quantity, setQuantity] = useState("0")
  const [category, setCategory] = useState("RKU000")
  const [tabArray, setTabArray] = useState([])
  const [cartItems, setCartItems] = useState(0)
  const [imgIndex, setImgIndex] = useState({ prodID: "", value: 0 })
  const [redirectGroupBuyPD, setRedirectGroupBuyPD] = useState(false)
  const [value, setValue] = useState({ prodID: "", value: 0 })
  const [openCart, setOpenCart] = useState(false)
  const [proceedToSignUp, setProceedToSignUp] = useState(false)
  const [displayGBPopUp, setDisplayGBPopUp] = useState(false)
  const [displayPopUp, setDisplayPopUp] = useState(true)
  const [popUpArray, setPopUpArray] = useState([])
  const [popUpImageIndex, setPopUpImageIndex] = useState(0)
  const [proceedToBulkBuy, setProceedToBulkBuy] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const [proceedToBundle, setProceedToBundle] = useState(false)
  const [proceedToHist, setProceedToHist] = useState(false)
  const [GBActions, setGBActions] = useState(false)
  const [hva, setHVA] = useState(false)
  const [HVAParams, setHVAParams] = useState([])
  const [displayHVAParams, setDisplayHVAParams] = useState(false)
  const [HVAProdID, setHVAProdID] = useState("")
  const [verifyURL, setVerifyURL] = useState(true)
  const [verifyKYC, setVerifyKYC] = useState(true)
  const [loading, setLoading] = useState(false)
  const [CLexists, setCLexists] = useState(false)
  const [CLInactive, setCLInactive] = useState(false)
  const [selectGroupBuy, setSelectGroupBuy] = useState(false)
  const [POD, setPOD] = useState(false)
  const [GroupBuyEnabled, setGroupBuy] = useState(false)

  const [cartId, setCartId] = useState(
    "CT" + Math.floor(100000000 + Math.random() * 900000000)
  )
  const [leaderID, setLeaderID] = useState("")
  const [leaderName, setLeaderName] = useState("")
  const [leaderPNo, setLeaderPNo] = useState("")

  const { sendMessage } = useContext(ToastContext)

  const { userState, userDispatch } = useContext(UserContext)

  const date = new Date()

  const yesterdate = new Date()

  const tomorrowDate = new Date()

  tomorrowDate.setDate(tomorrowDate.getDate() + 1)

  yesterdate.setDate(yesterdate.getDate() - 1)

  const today =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1)) +
    "-" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "T" +
    (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
    ":" +
    (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

  const yesterday =
    yesterdate.getFullYear() +
    "-" +
    (yesterdate.getMonth() + 1 > 9
      ? yesterdate.getMonth() + 1
      : "0" + (yesterdate.getMonth() + 1)) +
    "-" +
    (yesterdate.getDate() > 9
      ? yesterdate.getDate()
      : "0" + yesterdate.getDate()) +
    "T" +
    (yesterdate.getHours() > 9
      ? yesterdate.getHours()
      : "0" + yesterdate.getHours()) +
    ":" +
    (yesterdate.getMinutes() > 9
      ? yesterdate.getMinutes()
      : "0" + yesterdate.getMinutes())

  const tomorrow =
    tomorrowDate.getFullYear() +
    "-" +
    (tomorrowDate.getMonth() + 1 > 9
      ? tomorrowDate.getMonth() + 1
      : "0" + (tomorrowDate.getMonth() + 1)) +
    "-" +
    (tomorrowDate.getDate() > 9
      ? tomorrowDate.getDate()
      : "0" + tomorrowDate.getDate()) +
    "T" +
    (tomorrowDate.getHours() > 9
      ? tomorrowDate.getHours()
      : "0" + tomorrowDate.getHours()) +
    ":" +
    (tomorrowDate.getMinutes() > 9
      ? tomorrowDate.getMinutes()
      : "0" + tomorrowDate.getMinutes())

  const firestore = firebase.firestore()

  useEffect(() => {

    console.log("getWindowSize() ", window.innerWidth)

    const fetchData = async (regDate, specialOffer) => {
      const data = await firestore
        .collection("Products")
        .where("Category", "==", category)
        .orderBy("Order", "asc")
        .get()

      console.log(cartId)

      console.log("REG DATE: ", regDate)

      console.log("YESTERDAY DATE: ", yesterday)

      console.log("TODAY DATE: ", today)

      const list = []
      const specialList = []

      data.forEach(doc => {

        const {
          Name,
          Description,
          DailyLimit,
          ImgUrl,
          ImgUrls,
          Price,
          OriginalPrice,
          QtySold,
          LimitedQty,
          Quantities,
          Active,
          POD,
          Special,
          SpecialOffer,
          GBProdID,
          GBPrice,
          GBID,
          TargetQty,
        } = doc.data()

        const remainder = parseInt(TargetQty) - QtySold

        const qttys = []


        Quantities.split(",").forEach((qty) => {
          if (parseInt(qty) <= remainder) {
            qttys.push(qty)
          }
        })

        const obj = {
          key: doc.id,
          name: Name,
          desc: Description,
          imgUrl: ImgUrl,
          imgUrls: ImgUrls,
          qtySold: QtySold,
          price: Price,
          gbPrice: GBPrice,
          gbProdID: GBProdID,
          gbID: GBID,
          LimitedQty: LimitedQty,
          remainingQty: remainder,
          TargetQty: TargetQty,
          tabPrice: "Buy Alone: " + Price + "sh",
          tabPrice2: "🔥Buy Pamoja: " + GBPrice + "sh",
          originalPrice: OriginalPrice,
          POD,
          array: qttys,
        }

        if (regDate >= yesterday && regDate <= today && Special) {
          //New CL special offer
          list.push(obj)
        }

        if (SpecialOffer && specialOffer) {
          console.log("ADD SPECIAL OFFER")

          list.push(obj)
        }

        if (doc.id === "T982959273" && props.match.params.leaderID === "CL104239349" ||
          doc.id === "T982959273" && props.match.params.leaderID === "CL384219072" ||
          doc.id === "T982959273" && props.match.params.leaderID === "CL259104544") {
          list.push(obj)

        }

        if (doc.id === "T672405070" && props.match.params.leaderID === "CL940135745") {
          list.push(obj)
        }

        if (Active && doc.id !== "T408938") {

          if (props.match.params.leaderID === "CL104239349" && doc.id === "T152114" ||
            props.match.params.leaderID === "CL384219072" && doc.id === "T152114") {
            console.log("IGNORE")
          } else {


            // if (hva) {

            //   list.push(obj)

            // } else {

            if (skuQttys[doc.id] === undefined || DailyLimit === undefined || DailyLimit === "") {
              list.push(obj)
            } else if (skuQttys[doc.id] < parseInt(DailyLimit)) {

              list.push(obj)

            }

            // }

          }

        } else if (props.match.params.leaderID === "CL202728" && Active && doc.id === "T408938") {
          list.push(obj)
        }
      })

      setItems(list)

      setTimeout(() => {


        if (!dontShowAgain) {

          displayPopUp()

        }

      }, 3000)
    }

    const displayPopUp = () => {

      // const array = []
      // firestore.collection("PopUps").orderBy("Date", "desc").get().then((data) => {
      //   data.forEach((doc) => {

      //     const { ImgUrl } = doc.data()
      //     array.push(ImgUrl)
      //   })
      // }).then(() => {
      //   setPopUpArray(array)

      //   if (array.length > 0) {
      setDisplayPopUp(true)

      //   }
      // })
    }

    const GBlist = []

    const fetchGroupBuyData = async () => {
      const GBData = await firestore
        .collection("GroupBuy")
        .where("EndDate", ">", today)

      GBData.get().then(doc => {
        doc.forEach(record => {
          const { StartDate, TargetQty, SoldQty, Leaders } = record.data()

          if (
            today > StartDate &&
            SoldQty < TargetQty &&
            Leaders[0] === props.match.params.leaderID
          ) {
            GBlist.push({
              key: record.id,
            })

            setGBItems(GBlist)
          }
        })
      })
    }

    const GBAbleData = []
    const fetchGroupBuyableData = async () => {
      const Products = await firestore.collection("Products")

      Products.get().then(doc => {
        doc.forEach(record => {
          const {
            Description,
            Price,
            TargetQty,
            OriginalPrice,
            GBPOD,
            GBLimit,
            GroupBuy,
            QtySold,
          } = record.data()

          // if (props.match.params.leaderID === "CL989844890") {
          //   if (today > "2022-08-14T23:00" && today < "2022-08-16T23:00") {
          //     if (
          //       record.id === "T347741845" ||
          //       record.id === "T672160184" ||
          //       record.id === "T824713628"
          //     ) {
          //       populateGroupBuy(
          //         record.id,
          //         QtySold,
          //         Description,
          //         Price,
          //         TargetQty,
          //         OriginalPrice,
          //         GBPOD,
          //         GBLimit
          //       )
          //     }
          //   } else if (
          //     today > "2022-08-16T23:00" &&
          //     today < "2022-08-18T23:00"
          //   ) {
          //     if (
          //       record.id === "T297056703" ||
          //       record.id === "T246989882" ||
          //       record.id === "T769108323"
          //     ) {
          //       populateGroupBuy(
          //         record.id,
          //         QtySold,
          //         Description,
          //         Price,
          //         TargetQty,
          //         OriginalPrice,
          //         GBPOD,
          //         GBLimit
          //       )
          //     }
          //   } else if (
          //     today > "2022-08-18T23:00" &&
          //     today < "2022-08-21T23:00"
          //   ) {
          //     if (
          //       record.id === "T590152249" ||
          //       record.id === "T644570917" ||
          //       record.id === "T463558958"
          //     ) {
          //       populateGroupBuy(
          //         record.id,
          //         QtySold,
          //         Description,
          //         Price,
          //         TargetQty,
          //         OriginalPrice,
          //         GBPOD,
          //         GBLimit
          //       )
          //     }
          //   }
          // } else {
          if (GroupBuy) {

            console.log("YOW", record.id)
            populateGroupBuy(
              record.id,
              QtySold,
              Description,
              Price,
              TargetQty,
              OriginalPrice,
              GBPOD,
              GBLimit
            )
          }
          // }
        })
      })
    }

    const populateGroupBuy = (
      prodID,
      QtySold,
      Description,
      Price,
      TargetQty,
      OriginalPrice,
      GBPOD,
      GBLimit
    ) => {
      const today =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "T" +
        (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
        ":" +
        (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

      let totalQty = QtySold

      firestore
        .collection("GroupBuy")
        .where("ProductID", "==", prodID)
        .where("EndDate", ">", today)
        .get()
        .then(dataGB => {
          if (!dataGB.empty) {
            dataGB.forEach(docGB => {

              console.log("docGB", prodID)
              const { TargetQty, StartDate, SoldQty } = docGB.data()

              console.log("VARRRRRRRR!", TargetQty)

              if (StartDate <= today && SoldQty < parseInt(TargetQty)) {
                totalQty = totalQty + parseInt(TargetQty)
              }
            })
          }
        })
        .then(() => {
          if (totalQty < parseInt(GBLimit)) {
            GBAbleData.push({
              key: prodID,
              Description: Description,
              OriginalPrice: OriginalPrice,
              GBPrice: Price,
              TargetQty: TargetQty,
              GBPOD: GBPOD,
            })

            setGBAbleitems(GBAbleData)
          }
        })
    }

    const getSKUsBought = (DateCreated, SpecialOffer) => {

      let array1 = []
      const array2 = []
      const yesterdate = new Date()

      yesterdate.setDate(yesterdate.getDate() - 1)

      const yesterday =
        yesterdate.getFullYear() +
        "-" +
        (yesterdate.getMonth() + 1 > 9
          ? yesterdate.getMonth() + 1
          : "0" + (yesterdate.getMonth() + 1)) +
        "-" +
        (yesterdate.getDate() > 9
          ? yesterdate.getDate()
          : "0" + yesterdate.getDate()) +
        " T " +
        (yesterdate.getHours() > 9
          ? yesterdate.getHours()
          : "0" + yesterdate.getHours()) +
        ":" +
        (yesterdate.getMinutes() > 9
          ? yesterdate.getMinutes()
          : "0" + yesterdate.getMinutes())

      const orders = firestore
        .collection("Orders")
        .where("DateOfOrder", ">=", yesterday)
        .where("LeaderID", "==", props.match.params.leaderID)

      orders.get().then(data => {
        if (!data.empty) {

          data.forEach((doc) => {
            const { CartID } = doc.data()

            const shoppingCartRef = firebase
              .database()
              .ref('shoppingCarts/' + CartID)

            shoppingCartRef.on('value', snapshot => {
              if (snapshot.exists()) {
                snapshot.forEach(function (childSnapshot) {
                  if (childSnapshot.key !== 'grandTotal') {
                    const data = childSnapshot.val()

                    if (array1[childSnapshot.key] === undefined) {
                      array1[childSnapshot.key] = parseInt(data.quantity)
                    } else {
                      array1[childSnapshot.key] = array1[childSnapshot.key] + parseInt(data.quantity)
                    }

                  }
                })

                // Code for adding grandtotal to cart record
              } else {
                console.log('No data available')
                // this.setState({
                //   emptyCart: true
                // })
              }
            })


          })

        }
      }).then(() => {

        setSkuQttys(array1)

      }).then(() => {

        if(today > "2023-05-17T09:00"){
          fetchCategoryTabs()
          fetchData(DateCreated, SpecialOffer)
          fetchGroupBuyData()
          fetchGroupBuyableData()
        }else{
          fetchGroupBuyData()
          fetchGroupBuyableData()
        }
      })
    }

    const verifyMyUrl = async () => {

      const CLdata = await firestore
        .collection("CommunityLeaders")
        .doc(props.match.params.leaderID)

      if (props.referral === "true") {
        const RefData = await firestore
          .collection("Customers")
          .doc(props.match.params.customerID)

        if (props.match.params.leaderID === "CL735461") {
          CLdata.get().then(doc => {
            if (doc.exists) {
              const {
                Name,
                PhoneNumber,
                DateCreated,
                HVA,
                SpecialOffer,
              } = doc.data()

              setLeaderID(props.match.params.leaderID)

              if (props.goBack === "true") {
                try {
                  setCartId(props.location.state.cartID)
                  setCartItems(props.location.state.cartItemsQty)

                } catch (error) {
                  console.log("Starting Page")
                }
              }

              console.log("REG DATE1 : ", DateCreated)

              setLeaderName(Name)
              setLeaderPNo(PhoneNumber)
              setHVA(HVA)

              RefData.get().then(doc2 => {
                if (doc2.exists) {
                  console.log("IMEPATIKANAAAAAAAAAAA!!!!")

                  setCLexists(true)

                  fetchData(DateCreated, SpecialOffer)
                  fetchGroupBuyData()
                  fetchGroupBuyableData()
                } else {
                  setCLexists(false)
                  setVerifyURL(false)
                }
              })
            } else {
              setCLexists(false)
              setVerifyURL(false)
            }
          })
        }
      } else if (props.referral === "false") {
        CLdata.get().then(doc => {
          if (doc.exists) {
            const {
              Active,
              Name,
              PhoneNumber,
              DateCreated,
              POD,
              LedgerBalance,
              LedgerLimit,
              SpecialOffer,
              Verified,
              HVA,
            } = doc.data()

            if (Active && Verified) {
              setLeaderID(props.match.params.leaderID)

              setLeaderName(Name)
              setLeaderPNo(PhoneNumber)
              setHVA(HVA)

              console.log("Starting Page")

              if (props.goBack === "true") {
                try {
                  setCartId(props.location.state.cartID)
                  setCartItems(props.location.state.cartItemsQty)
                } catch (error) {
                  console.log("Starting Page")
                }
              }

              setCLexists(true)

              if (HVA && props.match.params.leaderID !== "CL104239349" ||
                HVA && props.match.params.leaderID !== "CL384219072" ||
                HVA && props.match.params.leaderID !== "CL259104544") {
                if(today > "2023-05-17T09:00"){
                  fetchCategoryTabs()
                  fetchData(DateCreated, SpecialOffer)
                  fetchGroupBuyData()
                  fetchGroupBuyableData()
                }else{
                  fetchGroupBuyData()
                  fetchGroupBuyableData()
                }
              } else {
                getSKUsBought(DateCreated, SpecialOffer)
              }

              if (
                props.signedUp === "false" &&
                props.goBack === "false"
              ) {
                setProceedToSignUp(true)
                setVerifyKYC(Verified)
              } else if (props.match.params.leaderID === "CL384219072") {

                setPOD(true)
                setProceedToBulkBuy(true)
              } else if (props.match.params.leaderID === "CL259104544") {

                if (
                  props.location.state.userPNo === "254726572395" ||
                  props.location.state.userPNo === "254703106050" ||
                  props.location.state.userPNo === "254710292389" ||
                  props.location.state.userPNo === "254791824634" ||
                  props.location.state.userPNo === "254727266718" ||
                  props.location.state.userPNo === "254799573106" ||
                  props.location.state.userPNo === "254741391845" ||
                  props.location.state.userPNo === "254725837931" ||
                  props.location.state.userPNo === "254701966490" ||
                  props.location.state.userPNo === "254707157797" ||
                  props.location.state.userPNo === "254700405744" ||
                  props.location.state.userPNo === "254704738148" ||
                  props.location.state.userPNo === "254706349705" ||
                  props.location.state.userPNo === "254701744606" ||
                  props.location.state.userPNo === "254728634348" ||
                  props.location.state.userPNo === "254748611693" ||
                  props.location.state.userPNo === "254713904524" ||
                  props.location.state.userPNo === "254758196655" ||
                  props.location.state.userPNo === "254711224913" ||
                  props.location.state.userPNo === "254711344327"
                ) {

                  console.log("EMPLOYEEEEEEEEEEEEEE")
                  setPOD(true)
                }

              } else {
                console.log("Continue Shopping")



                try {
                  console.log(props.location.state.userId)
                } catch (error) {
                  setProceedToSignUp(true)
                  setVerifyKYC(Verified)
                }
              }
            } else if (Active && !Verified) {
              setCLexists(true)
              setVerifyKYC(false)
            } else {
              setCLInactive(true)
            }
          } else {
            console.log("PIMBII")
            setCLexists(false)
            setVerifyURL(false)
          }
        })
      }
    }

    const fetchCategoryTabs = async () => {
      const data = await firestore.collection("SKUCategories").get()

      const tabList = []

      data.forEach(doc => {
        const { Name } = doc.data()

        tabList.push(<Tab label={Name} value={doc.id} />)
      })

      setTabArray(tabList)
    }

    verifyMyUrl()
  }, [category])

  const handleOpenCart = () => {
    window.localStorage.setItem("Tour", true)


    if (cartItems > 0) {
      userDispatch({ type: 'userId', payload: props.location.state.userId })
      userDispatch({ type: 'cartId', payload: cartId })
      userDispatch({ type: 'leaderId', payload: props.match.params.leaderID })
      userDispatch({ type: 'cartItems', cartItems })
      userDispatch({ type: 'cartItemsQty', payload: cartItems })
      console.log("POD", POD)
      setOpenCart(true)

    } else {
      sendMessage("Empty Cart! Select Items to proceed.")
    }
  }

  const createQueue = async (e, prodID, gbID, qtySold, name, price) => {
    const myProduct = firestore.collection("Products").doc(prodID)
    setLoading(true)
    const doc = await myProduct.get()
    if (!doc.exists) {
      console.log("No such document!")
    } else {
      if (quantity === "0") {
        alert("Select Quantity")
      } else {
        const product = doc.data()

        const total = product["Price"] * quantity
        // console.log('Document data:', product["Price"]);
        const itemRef = firebase.database().ref("shoppingCarts/" + GBQID)

        itemRef
          .child(prodID)
          .once("value")
          .then(snapshot => {
            if (snapshot.exists()) {
              itemRef.child(prodID).set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: product["Price"],
                quantity: quantity,
                total: total,
              })
              itemRef.child("grandTotal").set(parseInt(total))
              console.log("Item exists")
            } else {
              itemRef.child(prodID).set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: product["Price"],
                quantity: quantity,
                total: total,
              })

              itemRef.child("grandTotal").set(parseInt(total))

              console.log(prodID, " Added")
              console.log(GBQID)

              console.log("YOW")

              setGBID(gbID)
              setLoading(false)
              setRedirectGroupBuyPD(true)
            }
          })
      }
    }
  }

  const handleSelectGBItem = (
    e,
    prodID,
    targetQty,
    GBPOD,
    homePage,
    prodIDUpdate,
    newValue
  ) => {
    // if (hva) {
    firestore
      .collection("Products")
      .doc(prodID)
      .get()
      .then(doc => {
        const { HVAGBParams } = doc.data()

        setHVAParams(HVAGBParams)
        setDisplayHVAParams(true)
        setHVAProdID(prodID)
      })
    // } else {
    //   setLoading(true)

    //   const url = "https://api.tushop.io/api/v1/createGroupBuy"

    //   fetch(url, {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       prodID: prodID,
    //       targetQty: targetQty,
    //       GBPOD: !POD ? false : GBPOD,
    //       prodIDUpdate: prodIDUpdate,
    //       Leaders: [props.match.params.leaderID],
    //     }),
    //   })
    //     .then(response => {
    //       if (response.status === 201) {
    //         return response.json()
    //       } else if (response.status === 500) {
    //         setLoading(false)
    //         alert(response.message)
    //       } else {
    //         setLoading(false)
    //         alert("System Error")

    //         throw new Error("Something went wrong on api server!")
    //       }
    //     })
    //     .then(response => {
    //       if (homePage) {
    //         console.log("HOMEPAGE!")
    //         fillGroupBuyData(response.GBID, prodIDUpdate, newValue, e, prodID)
    //       } else {
    //         setLoading(false)

    //         alert(response.message)
    //       }
    //     })
    //     .catch(error => {
    //       console.error(error)
    //     })
    // }
  }

  const handleStartGroupBuy = () => {
    window.localStorage.setItem("Tour", true)
    console.log("GB started")

    setSelectGroupBuy(true)

    console.log(GBAbleitems)
  }

  const handleProceedToGroupBuy = () => {
    window.localStorage.setItem("Tour", true)
    setDisplayGBPopUp(true)
  }

  const handleProceedToBulkBuy = () => {
    window.localStorage.setItem("Tour", true)
    setProceedToBulkBuy(true)
  }

  const handleProceedToBundles = () => {
    window.localStorage.setItem("Tour", true)
    setProceedToBundle(true)
  }

  const handleProceedToHistory = () => {
    setProceedToHist(true)
  }

  const handleTabSelect = (e, newVal, gbPrice, gbProdID, gbID, key) => {
    setLoading(true)

    if (newVal === 0) {
      setValue({ prodID: key, value: newVal })
      setLoading(false)
    } else if (newVal === 1) {
      try {
        firestore
          .collection("Products")
          .doc(gbProdID)
          .get()
          .then(doc => {
            const { TargetQty } = doc.data()
            if (gbID === []) {
              handleSelectGBItem(
                e,
                gbProdID,
                TargetQty,
                false,
                true,
                key,
                newVal
              )
            } else {
              let groupBuyID = ""

              gbID.forEach(data => {
                if (data.leaderID === props.match.params.leaderID) {
                  groupBuyID = data.GBID
                }
              })

              if (groupBuyID === "") {
                handleSelectGBItem(
                  e,
                  gbProdID,
                  TargetQty,
                  false,
                  true,
                  key,
                  newVal
                )
              } else {
                fillGroupBuyData(groupBuyID, key, newVal, e, gbProdID)
              }
            }
          })
      } catch (error) {
        sendMessage("No Group Buy exists for the product")
        setValue({ prodID: "", value: 0 })
        setLoading(false)
      }
    }
  }

  const fillGroupBuyData = (groupBuyID, key, value, e, gbProdID) => {
    firestore
      .collection("GroupBuy")
      .doc(groupBuyID)
      .get()
      .then(doc => {
        const {
          StartDate,
          EndDate,
          ProductID,
          TargetQty,
          SoldQty,
          Leaders,
          POD,
        } = doc.data()

        if (today > EndDate) {
          handleSelectGBItem(e, gbProdID, TargetQty, false, true, key, value)
        } else {
          const list = []

          const product = firestore.collection("Products").doc(ProductID)

          product.get().then(doc2 => {
            const {
              Name,
              Description,
              ImgUrl,
              Price,
              OriginalPrice,
            } = doc2.data()

            list.push({
              key: doc2.id,
              gbID: doc.id,
              name: Name,
              desc: Description,
              imgUrl: ImgUrl,
              qtySold: SoldQty,
              targetQty: TargetQty,
              start: StartDate,
              end: EndDate,
              price: Price,
              originalPrice: OriginalPrice,
              POD: POD,
              array: [1, 2],
            })

            setGBProduct(list)
            setValue({ prodID: key, value: value })
            setLoading(false)
          })
        }
      })
  }

  const handleCheck = event => {
    window.localStorage.setItem("Tour", true)
    setQuantity(event.target.value)
  }

  const handleCheckNormal = (e, key, pod) => {
    window.localStorage.setItem("Tour", true)
    setQuantity(e.target.value)

    handleAddtoCart(e, key, e.target.value, pod)
  }

  const handleAddtoCart = (e, key, quantity, pod) => {
    setLoading(true)

    console.log("Hellooo")

    console.log(key, cartId)

    window.localStorage.setItem("Tour", true)
    const firestore = firebase.firestore()

    const fetchData = async () => {
      const myProduct = firestore.collection("Products").doc(key)
      const doc = await myProduct.get()

      if (!doc.exists) {
        console.log("No such document!")
      } else {
        if (quantity === "0") {
          sendMessage("Select Quantity")
          setLoading(false)
        } else {
          const product = doc.data()

          const total = product["Price"] * quantity
          // console.log('Document data:', product["Price"]);
          const itemRef = firebase
            .database()
            .ref("shoppingCarts/" + cartId + "/" + key)

          itemRef
            .once("value")
            .then(snapshot => {
              if (snapshot.exists()) {
                itemRef.set({
                  productDesc: product["Description"],
                  productID: product["ProductID"],
                  productPrice: product["Price"],
                  quantity: quantity,
                  total: total,
                })
                console.log("Item exists")
                setLoading(false)

                sendMessage("Item Quantity Updated!")

                setQuantity("0")
              } else {
                itemRef.set({
                  productDesc: product["Description"],
                  productID: product["ProductID"],
                  productPrice: product["Price"],
                  quantity: quantity,
                  total: total,
                })

                setCartItems(cartItems + 1)

                setLoading(false)

                sendMessage("Item added to cart!")

                setQuantity("0")
              }
            })
          // .then(() => {
          //   if (POD) {
          //     setPOD(pod)
          //   }
          // })
        }
      }
    }

    fetchData()
  }

  const addGroupBuy = () => {
    const loader = {
      margin: 0,
      top: "auto",
      right: "auto",
      bottom: "50%",
      left: "auto",
      position: "fixed",
      zIndex: 1,
    }

    const handleCloseGBInfo = () => {
      setSelectGroupBuy(false)
    }

    if (loading) {
      return (
        <CenteredDiv vertical>
          <Spinner large secondary style={loader} />
        </CenteredDiv>
      )
    } else {
      return (
        <Dialog
          fullScreen={true}
          open={selectGroupBuy}
          onClose={handleCloseGBInfo}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <H2>Start Group Buy</H2>
            <P>
              Select product to initiate group buy for{" "}
              {leaderName.split(" ")[0]}'s shop & get crazy prices
            </P>
            <TableContainer component={Paper} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    style={{
                      height: "5px",
                      width: "100%",
                      backgroundColor: "#d3d3d3",
                    }}
                  >
                    <TableCell>
                      <b>PRODUCT</b>
                    </TableCell>
                    <TableCell>
                      <b>ORIGINAL PRICE</b>
                    </TableCell>
                    <TableCell>
                      <b>GROUP BUY PRICE</b>
                    </TableCell>
                    <TableCell>
                      <b>TARGET QTY</b>
                    </TableCell>
                    <TableCell>
                      <b>START</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {GBAbleitems.map(data => (
                    <TableRow>
                      <TableCell>
                        <P>{data.Description}</P>
                      </TableCell>
                      <TableCell>
                        <P>{data.OriginalPrice}</P>
                      </TableCell>
                      <TableCell>
                        <P>{data.GBPrice}</P>
                      </TableCell>
                      <TableCell>
                        <P>{data.TargetQty}</P>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={e => {
                            handleSelectGBItem(
                              e,
                              data.key,
                              data.TargetQty,
                              data.GBPOD,
                              false,
                              null,
                              null
                            )
                          }}
                        >
                          <PlayArrowIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseGBInfo}>CANCEL</Button>
          </DialogActions>
        </Dialog>
      )
    }
  }


  const promoPopUp = () => {
    const loader = {
      margin: 0,
      top: "auto",
      right: "auto",
      bottom: "50%",
      left: "auto",
      position: "fixed",
      zIndex: 1,
    }

    const imgStyle = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
      maxHeight: "500px",
      top: -100,
    }

    const handleUpdateDownload = () => {
      firestore
        .collection("AppResponses")
        .doc(props.location.state.userId)
        .get()
        .then((doc) => {

          if (doc.exists) {

            const { download } = doc.data()

            firestore
              .collection("AppResponses")
              .doc(props.location.state.userId)
              .update({
                download: parseInt(download) + 1
              })

          } else {

            firestore
              .collection("AppResponses")
              .doc(props.location.state.userId)
              .set({
                notNow: 0,
                download: 1
              })

          }
        })
    }

    const handleCloseGBInfo = () => {

      firestore
        .collection("AppResponses")
        .doc(props.location.state.userId)
        .get()
        .then((doc) => {

          if (doc.exists) {

            const { notNow } = doc.data()

            firestore
              .collection("AppResponses")
              .doc(props.location.state.userId)
              .update({
                notNow: parseInt(notNow) + 1
              })

          } else {

            firestore
              .collection("AppResponses")
              .doc(props.location.state.userId)
              .set({
                notNow: 1,
                download: 0
              })

          }
        })
      setDontShowAgain(true)
      setDisplayPopUp(false)
    }

    // const handleSelectNextImg = (e) => {

    //   if (popUpImageIndex === popUpArray.length - 1) {

    //     setPopUpImageIndex(0)

    //   } else {
    //     setPopUpImageIndex(popUpImageIndex + 1)
    //   }
    // }

    // const handleSelectPrevImg = (e) => {

    //   if (popUpImageIndex === 0) {

    //     setPopUpImageIndex(popUpArray.length - 1)

    //   } else {

    //     setPopUpImageIndex(popUpImageIndex - 1)
    //   }

    // }

    if (loading) {
      return (
        <CenteredDiv vertical>
          <Spinner large secondary style={loader} />
        </CenteredDiv>
      )
    } else {
      // return (
      //   <Dialog
      //     open={displayPopUp}
      //     onClose={handleCloseGBInfo}
      //     aria-labelledby="form-dialog-title"
      //   >
      //     <DialogTitle>
      //       <H1>Hey there!</H1>
      //     </DialogTitle>
      //     <DialogContent>

      //       <div>
      //         <img src={popUpArray[popUpImageIndex]} style={imgStyle} onClick={handleProceedToBundles} />
      //       </div>
      //       <div style={{ display: "flex", alignItems: "center" }}>
      //         <NavigateBeforeIcon
      //           onClick={e => {
      //             handleSelectPrevImg(e)
      //           }}
      //           fontSize="large"
      //         />
      //         <span style={{ flexGrow: 1 }} />
      //         <NavigateNextIcon
      //           onClick={e => {
      //             handleSelectNextImg(e)
      //           }}
      //           fontSize="large"
      //         />
      //       </div>

      //     </DialogContent>
      //     <DialogActions>
      //       <Button onClick={handleCloseGBInfo}>CLOSE</Button>
      //       <Button onClick={handleProceedToBundles}>OPEN BUNDLES</Button>
      //     </DialogActions>
      //   </Dialog>
      // )
      return (
        <Dialog
          open={displayPopUp}
          onClose={handleCloseGBInfo}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>
            <H2>Download our App today!</H2>
          </DialogTitle>
          <DialogContent>

            <div>
              <a href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/WhatsApp%20Image%202023-04-02%20at%2023.41.48.jpeg?alt=media&token=d1accf50-8c9d-47b2-8fdf-0ac326bcd81e"
                  style={imgStyle}
                  onClick={() => { handleUpdateDownload() }}
                />
              </a>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseGBInfo}>NOT NOW</Button>
            <Button onClick={() => { handleUpdateDownload() }}>
              <a href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1">
                DOWNLOAD NOW
              </a>
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  }
  const hvaParams = () => {
    const loader = {
      margin: 0,
      top: "auto",
      right: "auto",
      bottom: "50%",
      left: "auto",
      position: "fixed",
      zIndex: 1,
    }

    const handleCloseHVAGBInfo = () => {
      setDisplayHVAParams(false)
    }

    const handleCreateHVA = (e, targetQty, price) => {
      const url = "https://api.tushop.io/api/v1/createHVAGroupBuy"

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prodID: HVAProdID,
          targetQty: targetQty,
          Price: price,
          Leaders: [props.match.params.leaderID],
        }),
      })
        .then(response => {
          if (response.status === 201 || response.status === 500) {
            return response.json()
          } else {
            alert("System Error")

            throw new Error("Something went wrong on api server!")
          }
        })
        .then(response => {
          alert(response.message)
        })
        .catch(error => {
          console.error(error)
        })
    }

    if (loading) {
      return (
        <CenteredDiv vertical>
          <Spinner large secondary style={loader} />
        </CenteredDiv>
      )
    } else {
      return (
        <Dialog
          open={displayHVAParams}
          onClose={handleCloseHVAGBInfo}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <H2>Start Group Buy</H2>
            <P>
              Select Target quantity to initiate group buy for{" "}
              {leaderName.split(" ")[0]}'s shop & get crazy prices
            </P>
            <TableContainer component={Paper} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    style={{
                      height: "5px",
                      width: "100%",
                      backgroundColor: "#d3d3d3",
                    }}
                  >
                    <TableCell>
                      <P>Target Qty</P>
                    </TableCell>
                    <TableCell>
                      <P>Price</P>
                    </TableCell>
                    <TableCell>
                      <P>Start</P>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {HVAParams.map(data => (
                    <TableRow>
                      <TableCell>
                        {" "}
                        <P
                          style={{
                            color:
                              data.targetQty >= 30
                                ? "green"
                                : data.targetQty >= 15
                                  ? "blue"
                                  : "red",
                          }}
                        >
                          {data.targetQty}
                        </P>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <P
                          style={{
                            color:
                              data.targetQty >= 30
                                ? "green"
                                : data.targetQty >= 15
                                  ? "blue"
                                  : "red",
                          }}
                        >
                          {data.price}
                        </P>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={e => {
                            handleCreateHVA(e, data.targetQty, data.price)
                          }}
                        >
                          <PlayArrowIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseHVAGBInfo}>CANCEL</Button>
          </DialogActions>
        </Dialog>
      )
    }
  }

  const dashboard = () => {
    const imgStyle = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
      maxHeight: "350px",
      top: -100,
    }

    const ImgCard = styled.div`
      height: 350px;
    `

    const rowStyle = {
      textAlign: "center",
      width: "100%",
      marginTop: "auto",
    }

    const banner1 = {
      position: "relative",
      left: 0,
      paddingTop: 0,
      top: -5,
      bottom: "auto",
      backgroundColor: "#232f3e",
      width: "100%",
      textAlign: "center",
    }

    const banner2 = {
      position: "relative",
      backgroundColor: "#efa83d",
      height: "100%",
      left: 0,
      width: "100%",
      textAlign: "center",
    }

    const table = {
      position: "absolute",
      top: 270,
    }

    const price = {
      textAlign: "right",
      width: "1%",
      color: "green",
      height: "5px",
      padding: "0px",
    }

    const floatingButtonCart = {
      margin: 0,
      top: 80,
      right: 10,
      bottom: "auto",
      left: "auto",
      position: "fixed",
      zIndex: 1,
    }

    const floatingButtonAdd = {
      marginTop: 10,
      marginBottom: 10,
      left: 0,
      right: "auto",
      marginLeft: 20,
      marginRight: 20,
    }

    const floatingButtonAdd2 = {
      marginTop: 10,
      position: "absolute",
      marginBottom: 10,
      right: 0,
      left: "auto",
      marginLeft: 20,
      marginRight: 20,
      backgroundColor: "#fe7c19",
    }

    const floatingButton = {
      marginTop: 10,
      marginBottom: 10,
    }

    const floatingButtonBB = {
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: "#A7CFD5",
    }

    const floatingButtonBD = {
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: "#B1D8B7",
    }

    const floatingButtonHist = {
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: "#A9A9A9",
    }

    const floatingButtonPrev = {
      backgroundColor: "#A9A9A9",
    }

    const floatingButtonNext = {
      backgroundColor: "#A9A9A9",
    }

    const loader = {
      margin: 0,
      top: "auto",
      right: "auto",
      bottom: "50%",
      left: "auto",
      position: "fixed",
      zIndex: 1,
    }

    const welcome = {
      fontSize: "5px",
    }

    const load = () => {
      if (loading) {
        return (
          <CenteredDiv vertical>
            <Spinner large secondary style={loader} />
          </CenteredDiv>
        )
      }
    }

    const bannerr = () => {
      return (
        <>
          <div style={banner2}>
            <br />
            <H1 style={welcome}>Welcome!</H1>
            <H2>To {leaderName.split(" ")[0]}'s Shop </H2>
            <H2>
              For inquiries, please call{" "}
              <a
                href="#"
                onClick={() => {
                  navigator.clipboard.writeText(leaderPNo)
                }}
              >
                {leaderPNo}
              </a>{" "}
            </H2>

            <P style={{ color: "#BF40BF" }}>
              <b>Get 100sh OFF your third order</b>
            </P>
            <P style={{ color: "blue" }}>
              <b>WE NOW DO SUNDAY DELIVERIES!</b>
            </P>
            <H2>
              <a href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1">Download our new app here</a>
            </H2>
          </div>
          <div style={banner1}>
            <div class="row">
              {/* {GroupBuyEnabled && hva ? (
                <>
                  {props.match.params.leaderID === "CL965615" ? (
                    <></>
                  ) : ( */}
              <>
                <div class="col-xs-1">
                </div>
                <div class="col-xs-2">
                  <Fab
                    onClick={handleProceedToGroupBuy}
                    color="secondary"
                    id="id-1"
                    style={floatingButton}
                  >
                    <Badge
                      color="secondary"
                      badgeContent={GBitems.length.toString()}
                    >
                      <img src="https://img.icons8.com/ios/50/000000/fire-element--v2.png" />
                    </Badge>
                  </Fab>
                </div>
                <div class="col-xs-2">
                  <Fab
                    onClick={handleStartGroupBuy}
                    color="primary"
                    id="id-2"
                    style={floatingButton}
                  >
                    <AddOutlinedIcon fontSize="large" />
                  </Fab>
                </div>
                <div class="col-xs-2">
                  <Fab
                    onClick={handleProceedToBulkBuy}
                    id="id-3"
                    style={floatingButtonBB}
                  >
                    <img
                      style={{ maxWidth: "60%" }}
                      src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/bulk.png?alt=media&token=ae48d072-ddc3-4638-af06-5568a0e3a26d"
                    />
                  </Fab>
                </div>
                <div class="col-xs-2">
                  <Fab
                    onClick={handleProceedToBundles}
                    id="id-3"
                    style={floatingButtonBD}
                  >
                    <img
                      style={{ maxWidth: "60%" }}
                      src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/3424183-200.png?alt=media&token=e5ef33cd-ed72-49c5-a5fa-d4b63e4e69cd"
                    />
                  </Fab>
                </div>
                <div class="col-xs-2">
                  <Fab
                    onClick={handleProceedToHistory}
                    id="id-4"
                    style={floatingButtonHist}
                  >
                    <AccessTimeIcon fontSize="large" />
                  </Fab>
                </div>
                <div class="col-xs-1">
                </div>
              </>
              {/*  )}
                 </>
              ) : (
                <>
                  <div class="col-xs-4">
                    <Fab
                      onClick={handleProceedToBulkBuy}
                      id="id-3"
                      style={floatingButtonBB}
                    >
                      <img
                        style={{ maxWidth: "60%" }}
                        src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/bulk.png?alt=media&token=ae48d072-ddc3-4638-af06-5568a0e3a26d"
                      />
                    </Fab>
                  </div>
                  <div class="col-xs-4">
                    <Fab
                      onClick={handleProceedToBundles}
                      id="id-3"
                      style={floatingButtonBD}
                    >
                      <img
                        style={{ maxWidth: "60%" }}
                        src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/3424183-200.png?alt=media&token=e5ef33cd-ed72-49c5-a5fa-d4b63e4e69cd"
                      />
                    </Fab>
                  </div>
                  <div class="col-xs-4">
                    <Fab
                      onClick={handleProceedToHistory}
                      id="id-4"
                      style={floatingButtonHist}
                    >
                      <AccessTimeIcon fontSize="large" />
                    </Fab>
                  </div>
                </>
              )} */}
            </div>
          </div>
        </>
      )
    }

    const floatingButtons = () => {
      const steps = [
        {
          target: "#id-1",
          disableBeacon: true,
          content: "Click Here for Hot Group Buy Sales!",
        },
      ]

      return (
        <>
          {GBitems.length > 0 &&
            props.GB === "false" &&
            props.match.params.leaderID !== "CL965615" &&
            GroupBuyEnabled ? (
            <>
              <Joyride steps={steps} />
            </>
          ) : (
            <></>
          )}
          <Fab
            onClick={handleOpenCart}
            color="primary"
            style={floatingButtonCart}
          >
            <Badge color="secondary" badgeContent={cartItems.toString()}>
              <ShoppingCartOutlinedIcon fontSize="large" />
            </Badge>
          </Fab>
        </>
      )
    }

    const tabs = () => {
      const handleSelectCategory = (e, newVal) => {
        setCategory(newVal)
      }

      return (
        <Tabs
          onChange={(e, newVal) => {
            handleSelectCategory(e, newVal)
          }}
          value={category}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="sticky scrollable auto tabs example"
        >
          {tabArray}
        </Tabs>
      )
    }

    const handleSelectNextImg = (e, key, length) => {
      if (length !== 1) {
        if (imgIndex.value + 1 < length && imgIndex.prodID === key) {
          setImgIndex({
            ...imgIndex,
            prodID: key,
            value: ++imgIndex.value,
          })
        } else if (imgIndex.value + 1 === length && imgIndex.prodID === key) {
          setImgIndex({
            prodID: key,
            value: 0,
          })
        } else {
          setImgIndex({
            prodID: key,
            value: 1,
          })
        }
      }
    }

    const handleSelectPrevImg = (e, key, length) => {
      if (length !== 1) {
        if (imgIndex.prodID === key) {
          if (imgIndex.value === 0) {
            setImgIndex({
              prodID: key,
              value: length - 1,
            })
          } else {
            setImgIndex({
              ...imgIndex,
              prodID: key,
              value: --imgIndex.value,
            })
          }
        } else {
          setImgIndex({
            prodID: key,
            value: 1,
          })
        }
      }
    }

    return (
      <>
        <BodyWrapper>
          {bannerr()}
          {load()}
          {floatingButtons()}
          {addGroupBuy()}
          {promoPopUp()}
          {tabs()}
          {hvaParams()}

          {
            today <  "2023-05-17T09:00"?
            <div class="row">
              {downloadApp()}
            </div>
            :
            <div class="row">
            {items.map((data, i) => {
              return (
                <div key={`${data.name}-${i}`} class="col-md-6">
                  <Card
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      width: "100%",
                      height: "800px",
                    }}
                  >
                    <CardContent>
                      <ImgCard>
                        <Fab
                          variant="extended"
                          size="small"
                          style={floatingButtonAdd2}
                        >
                          <LocalOfferIcon />
                          SAVE {data.originalPrice - data.price}sh
                        </Fab>
                        {imgIndex.prodID === data.key ? (
                          <img
                            src={data.imgUrls[imgIndex.value]}
                            style={imgStyle}
                          />
                        ) : (
                          <img src={data.imgUrls[0]} style={imgStyle} />
                        )}
                      </ImgCard>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <NavigateBeforeIcon
                          onClick={e => {
                            handleSelectPrevImg(
                              e,
                              data.key,
                              data.imgUrls.length
                            )
                          }}
                          fontSize="large"
                        />

                        {data.LimitedQty ? (
                          <>
                            <span style={{ flexGrow: 1 }} />
                            <H2 style={{ textAlign: "center" }}>
                              <p style={{ color: "red" }}>
                                Limited quantity available!
                              </p>
                            </H2>
                            <span style={{ flexGrow: 1 }} />
                          </>
                        ) : (
                          <span style={{ flexGrow: 1 }} />
                        )}
                        <NavigateNextIcon
                          onClick={e => {
                            handleSelectNextImg(
                              e,
                              data.key,
                              data.imgUrls.length
                            )
                          }}
                          fontSize="large"
                        />
                      </div>
                      <hr />
                      {data.gbProdID === "" ? (
                        <></>
                      ) : (
                        <Tabs
                          onChange={(e, newVal) => {
                            handleTabSelect(
                              e,
                              newVal,
                              data.gbPrice,
                              data.gbProdID,
                              data.gbID,
                              data.key
                            )
                          }}
                          value={value.prodID === data.key ? value.value : 0}
                          aria-label="Tabs where each tab needs to be selected manually"
                          variant="fullWidth"
                        >
                          <Tab
                            style={{ backgroundColor: "#fe7c19" }}
                            label={data.tabPrice}
                            value={0}
                          />
                          <Tab
                            style={{ backgroundColor: "red", color: "white" }}
                            label={data.tabPrice2}
                            value={1}
                          />
                        </Tabs>
                      )}
                      {value.prodID === data.key && value.value === 1 ? (
                        <>
                          {GBProduct.map(data2 => (
                            <>
                              <P>
                                <i>
                                  Buy with friends to get even cheaper prices
                                </i>
                              </P>
                              <br />
                              <Slider
                                value={data2.qtySold}
                                aria-labelledby="discrete-slider-always"
                                step={1}
                                // disabled
                                min={0}
                                max={parseInt(data2.targetQty)}
                                marks={[
                                  {
                                    label: "0: " + data2.originalPrice + "sh",
                                    value: 0,
                                  },
                                  {
                                    label:
                                      data2.targetQty.toString() +
                                      ": " +
                                      data2.price +
                                      "sh",
                                    value: data2.targetQty,
                                  },
                                ]}
                                valueLabelDisplay="on"
                                color="primary"
                              />
                              <div class="row">
                                <div class="col-xs-9">
                                  <P>
                                    <b>{data2.desc}</b>
                                  </P>
                                </div>
                                <div class="col-xs-3">
                                  <P>
                                    <b>{data2.price}sh</b>
                                  </P>
                                  {data2.originalPrice === "0" ? (
                                    <P>-</P>
                                  ) : (
                                    <P style={{ color: "red" }}>
                                      <del> {data2.originalPrice}sh </del>
                                    </P>
                                  )}
                                </div>
                              </div>
                              <FormLabel>Select Quantity</FormLabel>
                              <RadioGroup
                                name="spacing"
                                aria-label="spacing"
                                defaultValue="0"
                                onChange={handleCheck}
                                row
                              >
                                {data2.array.map(value => (
                                  <FormControlLabel
                                    key={value}
                                    value={value.toString()}
                                    control={<Radio />}
                                    label={value.toString()}
                                  />
                                ))}
                              </RadioGroup>
                              <Button
                                style={{ width: "100%" }}
                                onClick={e =>
                                  createQueue(
                                    e,
                                    data2.key,
                                    data2.gbID,
                                    data2.qtySold,
                                    data2.desc,
                                    data2.price
                                  )
                                }
                              >
                                SELECT{" "}
                              </Button>
                              <hr />
                              <P>
                                <i>
                                  <b>{data.qtySold}</b> {data.name} already
                                  bought & delivered
                                </i>
                              </P>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <br />
                          <div class="row">
                            <div class="col-xs-9">
                              <P>
                                <b>{data.desc}</b>
                              </P>
                            </div>
                            <div class="col-xs-3">
                              <P style={price}>
                                <b>{data.price}sh</b>
                              </P>
                              {data.originalPrice === "0" ? (
                                <P>-</P>
                              ) : (
                                <P style={{ color: "red" }}>
                                  <del> {data.originalPrice}sh </del>
                                </P>
                              )}
                            </div>
                          </div>

                          {parseInt(data.TargetQty) > data.qtySold ? (
                            <>
                              <P>
                                Only <b>{data.remainingQty}</b> {data.name}{" "}
                                remaining in stock
                              </P>
                              <FormLabel>Select Quantity</FormLabel>
                              <RadioGroup
                                name="spacing"
                                aria-label="spacing"
                                defaultValue="0"
                                onChange={e => {
                                  handleCheckNormal(e, data.key, data.POD)
                                }}
                                row
                              >
                                {data.array.map(value => (
                                  <FormControlLabel
                                    key={value}
                                    value={value.toString()}
                                    control={<Radio />}
                                    label={value.toString()}
                                  />
                                ))}
                              </RadioGroup>
                              <hr />
                              <P>
                                <i>
                                  <b>{data.qtySold}</b> {data.name} already
                                  bought & delivered
                                </i>
                              </P>
                            </>
                          ) : (
                            <H2 style={{ textAlign: "center" }}>
                              <p style={{ color: "red" }}>Out of stock!</p>
                            </H2>
                          )}
                        </>
                      )}
                    </CardContent>
                  </Card>
                  <br />
                </div>
              )
            })}
          </div>
          }
         
        </BodyWrapper>
      </>
    )
  }

  const noCL = () => {
    return (
      <BodyWrapper>
        <H2>Link does not exist</H2>
        <P>Contact support for assistance</P>
      </BodyWrapper>
    )
  }

  const noActiveCL = () => {
    return (
      <BodyWrapper>
        <H2>CL has been deactivated</H2>
        <P>Contact support for assistance</P>
      </BodyWrapper>
    )
  }

  const closedForElections = () => {
    return (
      <BodyWrapper>
        <H1>🇰🇪 Hey Kenyans!</H1>
        <H2>
          🇰🇪 We are temporarily closing operations due to the Kenya 2022
          National elections
        </H2>
        {/* <H2>🇰🇪 We shall resume operations on Tuesday August 09, 2022, 10:01 PM</H2> */}
        <H2>
          🇰🇪 In the mean time we urge all Kenyans to stay safe and promote peace
          until further notice...
        </H2>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/Elections%2Fimages.jpeg?alt=media&token=67bfaf74-9ad1-4171-b5f1-40d1dae478a4"
          style={{ width: "100%" }}
        />
      </BodyWrapper>
    )
  }

  const noKYCVerification = () => {
    return (
      <BodyWrapper>
        <H2>CL has not uploaded their details for complete registration</H2>
        <P>
          As soon as your CL completes the upload and verification process you
          will be able to continue to shop.
        </P>
        <P>We apologize for any inconveniences caused</P>
      </BodyWrapper>
    )
  }

  const verifyTheURL = () => {
    return (
      <BodyWrapper>
        <CenteredDiv vertical>
          <H2>Verifying URL...</H2>
          <P>Please wait</P>
          <Spinner large secondary />
        </CenteredDiv>
      </BodyWrapper>
    )
  }

  const downloadApp = () => {

    const imgStyle = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
      maxHeight: "700px",
      }

      const handleUpdateDownload = () => {
        console.log(props.location.state.userId)
        firestore
          .collection("AppResponses")
          .doc(props.location.state.userId)
          .get()
          .then((doc) => {
  
            if (doc.exists) {
                  firestore
                .collection("AppResponses")
                .doc(props.location.state.userId)
                .update({
                  download2: true
                })
  
            } else {
  
              firestore
                .collection("AppResponses")
                .doc(props.location.state.userId)
                .set({
                  download2: true
                })
  
            }
          })
      }

    return(
      <>
        <div>
          <a 
            href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/WhatsApp%20Image%202023-04-02%20at%2023.41.48.jpeg?alt=media&token=d1accf50-8c9d-47b2-8fdf-0ac326bcd81e"
              style={imgStyle}
              onClick={() => handleUpdateDownload()}
            />
          </a>
        </div>
      </>
    )

  }

  if (CLexists) {
    if (openCart) {
      if (props.referral === "true") {
        return (
          <Redirect
            // push
            to={{
              pathname: "/shoppingCart",
              state: {
                cartID: cartId,
                leaderID: leaderID,
                referral: props.match.params.customerID,
              },
            }}
          />
        )
      } else if (props.referral === "false") {
        if (props.signedUp === "true") {
          console.log("YUZA ID2 ", props.location.state.userId)

          return (
            <Redirect
              push
              to={{
                pathname: "/shoppingCart",
                state: {
                  cartItemsQty: cartItems,
                  cartID: cartId,
                  leaderID: leaderID,
                  referral: "",
                  hva: hva,
                  POD: POD,
                  userID: props.location.state.userId,
                },
              }}
            />
          )
        } else {
          try {
            return (
              <Redirect
                // push
                to={{
                  pathname: "/shoppingCart",
                  state: {
                    cartItemsQty: cartItems,
                    cartID: cartId,
                    leaderID: leaderID,
                    referral: "",
                    hva: hva,
                    POD: POD,
                    userID: props.location.state.userId,
                  },
                }}
              />
            )
          } catch (error) {
            return (
              <Redirect
                // push
                to={{
                  pathname: "/shoppingCart",
                  state: {
                    cartItemsQty: cartItems,
                    cartID: cartId,
                    leaderID: leaderID,
                    referral: "",
                    hva: hva,
                    POD: POD,
                    userID: "",
                  },
                }}
              />
            )
          }
        }
      }
    } else if (displayGBPopUp) {
      try {
        return (
          <Redirect
            push
            to={{
              pathname: "/groupBuy/" + leaderID,

              state: {
                userID: props.location.state.userId,
                userPNo: props.location.state.userPNo,
                userName: props.location.state.userName,
                cartItemsQty: props.location.state.cartItemsQty,
              },
            }}
          />
        )
      } catch (error) {
        console.log("OHAA")

        console.log(error)

        return (
          <Redirect
            push
            to={{
              pathname: "/groupBuy/" + leaderID,

              state: {
                userID: "",
              },
            }}
          />
        )
      }
    } else if (proceedToBulkBuy) {
      return (
        <Redirect
          push
          to={{
            pathname: "/bulkBuy/" + leaderID,

            state: {
              userId: props.location.state.userId,
              userPNo: props.location.state.userPNo,
              userName: props.location.state.userName,
              POD: POD,
              cartItemsQty: 0,
            },
          }}
        />
      )
    } else if (proceedToBundle) {
      return (
        <Redirect
          push
          to={{
            pathname: "/bundle/" + leaderID,

            state: {
              userId: props.location.state.userId,
              userPNo: props.location.state.userPNo,
              userName: props.location.state.userName,
              POD: POD,
              cartItemsQty: 0,
            },
          }}
        />
      )
    } else if (proceedToHist) {
      return (
        <Redirect
          push
          to={{
            pathname: "/purchaseHistory",
            state: {
              userId: props.location.state.userId,
              userPNo: props.location.state.userPNo,
            },
          }}
        />
      )
    } else if (proceedToSignUp && verifyKYC) {
      console.log("SIGN UP")
      return (
        <Redirect
          push
          to={{
            pathname: "/signin/" + leaderID,
            state: {
              groupBuy: false,
            },
          }}
        />
      )
    } else if (redirectGroupBuyPD) {
      return (
        <Redirect
          push
          to={{
            pathname: "/paymentDetails",
            state: {
              cartID: GBQID,
              leaderID: props.match.params.leaderID,
              referralID: "",
              POD: false,
              signedUp: props.signedUp === "false" ? false : true,
              userID: props.location.state.userId,
              groupBuy: true,
              gbID: GBID,
            },
          }}
        />
      )
    } else if (!verifyKYC) {
      console.log("YOW")

      return noKYCVerification()
    } else {
      // console.log("TUDEI", today)

      // if (today > "2022-08-05T22:00" && today < "2022-08-09T22:01") {

      // return closedForElections()

      // } else {
      return dashboard()
      // }

      // return dashboard()
    }
  } else {
    if (verifyURL && !CLInactive) {
      return verifyTheURL()
    } else if (CLInactive) {
      return noActiveCL()
    } else {
      return noCL()
    }
  }
}

export default Dashboard
