import React, { useState, useContext, useEffect } from "react"
import { H1, P, H2, Button, BodyWrapper, Spinner, CenteredDiv } from "../components"
import { ToastContext } from "../contexts/toastContext"
import {
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom"
import Badge from '@material-ui/core/Badge'
import { Card, CardContent, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core"
import styled from "styled-components"
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import TableRow from '@material-ui/core/TableRow'
import Fab from '@material-ui/core/Fab'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import { UserContext } from "../contexts/userContext"
import firebase from "../firebase.js"
import "firebase/firestore"
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

// console.log("PRODUCT ID: ", prodID)

// console.log("GROUP BUY ID: ", gbID)

const Dashboard = (props) => {

  const [items, setItems] = useState([])
  const [PODitems, setPODItems] = useState([])
  const [quantity, setQuantity] = useState("0")
  const [cartItems, setCartItems] = useState(0)
  const [imgIndex, setImgIndex] = useState({ prodID: "", value: 0 })
  const [category, setCategory] = useState("PrePay")

  const [openCart, setOpenCart] = useState(false)
  const [proceedToSignUp, setProceedToSignUp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [POD, setPOD] = useState(false)
  const [displayPopUp, setDisplayPopUp] = useState(true)

  const [cartId, setCartId] = useState("CTBB" + Math.floor(100000000 + Math.random() * 900000000))
  const [customerID, setCustomerID] = useState("")




  const { sendMessage } = useContext(ToastContext)

  const { userState, userDispatch } = useContext(UserContext)

  const firestore = firebase.firestore()

  useEffect(() => {

    setCartId("CTBB" + Math.floor(100000000 + Math.random() * 900000000))
    setCartItems(0)

    const fetchData = async (regDate) => {
      const data = await firestore.collection("Products").where("Bulk", "==", true).orderBy("Order", "asc").get()

      const list = []

      const PODlist = []

      data.forEach(doc => {
        const { Name, Description, ImgUrl, ImgUrls, Price, Quantities, OriginalPrice, QtySold, TargetQty, LimitedQty, BBPODPrice } = doc.data()

        const remainingQty = parseInt(TargetQty) - parseInt(QtySold)

        const initArray = Quantities.split(",")

        initArray.forEach((data, index) => {

          if (parseInt(data) > remainingQty && index !== 0) {
            initArray.splice(index, 1)
          }
        })

        if (BBPODPrice === undefined && props.match.params.leaderID === "CL384219072" ||
          BBPODPrice === "" && props.match.params.leaderID === "CL384219072" ||
          BBPODPrice === "0" && props.match.params.leaderID === "CL384219072") {

          list.push({
            key: doc.id,
            name: Name,
            desc: Description,
            imgUrl: ImgUrl,
            imgUrls: ImgUrls,
            qtySold: QtySold,
            price: Price,
            TargetQty: TargetQty,
            originalPrice: OriginalPrice,
            LimitedQty: LimitedQty,
            remainingQty: remainingQty,
            array: initArray
          })
        } else {

          list.push({
            key: doc.id,
            name: Name,
            desc: Description,
            imgUrl: ImgUrl,
            imgUrls: ImgUrls,
            qtySold: QtySold,
            price: Price,
            TargetQty: TargetQty,
            originalPrice: OriginalPrice,
            LimitedQty: LimitedQty,
            remainingQty: remainingQty,
            array: initArray
          })

          PODlist.push({
            key: doc.id,
            name: Name,
            desc: Description,
            imgUrl: ImgUrl,
            imgUrls: ImgUrls,
            qtySold: QtySold,
            price: BBPODPrice,
            TargetQty: TargetQty,
            originalPrice: OriginalPrice,
            LimitedQty: LimitedQty,
            remainingQty: remainingQty,
            array: initArray

          })
        }

      })

      if (category === "POD") {
        setItems(PODlist)
        setPOD(true)
      } else {
        setItems(list)
        setPOD(false)
      }

    }

    const setData = () => {

      try {

        setCustomerID(props.location.state.userId)
        setPOD(props.location.state.POD)

      } catch (error) {
        setProceedToSignUp(true)
      }

    }

    fetchData()
    setData()

  }, [category])

  const handleOpenCart = () => {

    if (cartItems > 0) {

      setOpenCart(true)

    } else {
      sendMessage("Empty Cart! Select Items to proceed.")
    }



  }

  const handleCheck = (event, key) => {
    setQuantity(event.target.value)

    handleAddtoCart(event, key, event.target.value)

  }

  const handleAddtoCart = (e, key, quantity) => {

    setLoading(true)

    console.log("Hellooo")

    console.log(key, cartId)

    window.localStorage.setItem("Tour", true)
    const firestore = firebase.firestore()

    const fetchData = async () => {

      const myProduct = firestore.collection("Products").doc(key)
      const doc = await myProduct.get()

      if (!doc.exists) {
        console.log('No such document!')
      } else {

        if (quantity === "0") {
          sendMessage("Select Quantity")
          setLoading(false)
        } else {

          const product = doc.data()

          let total

          if (category === "PrePay") {

            total = product["Price"] * quantity

          } else {

            total = product["BBPODPrice"] * quantity

          }
          // console.log('Document data:', product["Price"]);
          const itemRef = firebase.database().ref('shoppingCarts/' + cartId + '/' + key)

          itemRef.once('value').then((snapshot) => {
            if (snapshot.exists()) {
              itemRef.set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: category === "PrePay" ? product["Price"] : product["BBPODPrice"],
                quantity: quantity,
                total: total
              })
              console.log("Item exists")
              setLoading(false)

              sendMessage("Item Quantity Updated!")

              setQuantity("0")
            } else {
              itemRef.set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: category === "PrePay" ? product["Price"] : product["BBPODPrice"],
                quantity: quantity,
                total: total
              })

              setCartItems(cartItems + 1)

              setLoading(false)

              sendMessage("Item added to cart!")

              setQuantity("0")

            }
          })

        }

      }
    }

    fetchData()
  }

  const dashboard = () => {
    const imgStyle = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
      maxHeight: "350px",
      top: -100
    }

    const ImgCard = styled.div`
      height: 350px;
    `

    const banner = {
      position: "relative",
      backgroundColor: "#efa83d",
      left: 0,
      width: "100%",
      textAlign: "center",
    }

    const table = {
      paddingTop: "60px"
    }

    const price = {
      textAlign: "right",
      width: "1%",
      color: "green",
      height: "5px",
      padding: "0px"
    }

    const floatingButton1 = {
      margin: 0,
      top: 80,
      right: 20,
      bottom: 'auto',
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const floatingButton2 = {
      margin: 0,
      top: 80,
      left: 20,
      bottom: 'auto',
      right: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const floatingButtonAdd2 = {
      marginTop: 10,
      position: "absolute",
      marginBottom: 10,
      right: 0,
      left: 'auto',
      marginLeft: 20,
      marginRight: 20,
      backgroundColor: "#fe7c19"
    }

    const loader = {
      margin: 0,
      top: 'auto',
      right: 'auto',
      bottom: "50%",
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const welcome = {
      fontSize: "5px"
    }

    const load = () => {
      if (loading) {
        return (
          <CenteredDiv vertical>
            <Spinner large secondary style={loader} />
          </CenteredDiv>
        )
      }
    }

    const bannerr = () => {
      return (
        <div style={banner}>
          <br />
          <H1 style={welcome}>BULK BUY!</H1>
          <br />
        </div>
      )
    }

    const floatingButtons = () => {

      return (
        <>
          <Fab onClick={handleOpenCart} color="primary" style={floatingButton1}>
            <Badge color="secondary" badgeContent={cartItems.toString()}>
              <ShoppingCartOutlinedIcon fontSize="large" />
            </Badge>
          </Fab>

          <Fab onClick={e => { window.history.back() }} color="primary" style={floatingButton2}>
            <HomeOutlinedIcon fontSize="large" />
          </Fab>
        </>
      )

    }

    const promoPopUp = () => {
      const loader = {
        margin: 0,
        top: "auto",
        right: "auto",
        bottom: "50%",
        left: "auto",
        position: "fixed",
        zIndex: 1,
      }

      const imgStyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100%",
        maxHeight: "500px",
        top: -100,
      }

      const handleUpdateDownload = () => {
        firestore
          .collection("AppResponses")
          .doc(props.location.state.userId)
          .get()
          .then((doc) => {

            if (doc.exists) {

              const { download } = doc.data()

              firestore
                .collection("AppResponses")
                .doc(props.location.state.userId)
                .update({
                  download: parseInt(download) + 1
                })

            } else {

              firestore
                .collection("AppResponses")
                .doc(props.location.state.userId)
                .set({
                  notNow: 0,
                  download: 1
                })

            }
          })
      }

      const handleCloseGBInfo = () => {

        firestore
          .collection("AppResponses")
          .doc(props.location.state.userId)
          .get()
          .then((doc) => {

            if (doc.exists) {

              const { notNow } = doc.data()

              firestore
                .collection("AppResponses")
                .doc(props.location.state.userId)
                .update({
                  notNow: parseInt(notNow) + 1
                })

            } else {

              firestore
                .collection("AppResponses")
                .doc(props.location.state.userId)
                .set({
                  notNow: 1,
                  download: 0
                })

            }
          })
        setDisplayPopUp(false)
      }

        return (
          <Dialog
            open={displayPopUp}
            onClose={handleCloseGBInfo}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>
              <H2>Download our App today!</H2>
            </DialogTitle>
            <DialogContent>

              <div>
                <a href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1" target="_blank">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/utils%2FWhatsApp%20Image%202023-04-24%20at%2020.22.35.jpeg?alt=media&token=8d6740b7-833c-4fcb-b744-7c910e191c36"
                    style={imgStyle}
                    onClick={() => { handleUpdateDownload() }}
                  />
                </a>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseGBInfo}>NOT NOW</Button>
              <Button onClick={() => { handleUpdateDownload() }}>
                <a href="https://play.google.com/store/apps/details?id=com.tushop.tushop_mobile&pli=1" target="_blank">
                  DOWNLOAD NOW
                </a>
              </Button>
            </DialogActions>
          </Dialog>
        )
    }
    
    
    const handleSelectNextImg = (e, key, length) => {

      if (length !== 1) {

        if (imgIndex.value + 1 < length && imgIndex.prodID === key) {

          setImgIndex({
            ...imgIndex,
            prodID: key,
            value: ++imgIndex.value
          })
        } else if (imgIndex.value + 1 === length && imgIndex.prodID === key) {
          setImgIndex({
            prodID: key,
            value: 0
          })

        } else {
          setImgIndex({
            prodID: key,
            value: 1
          })
        }

      }

    }

    const handleSelectPrevImg = (e, key, length) => {

      if (length !== 1) {

        if (imgIndex.prodID === key) {

          if (imgIndex.value === 0) {
            setImgIndex({
              prodID: key,
              value: length - 1
            })
          } else {
            setImgIndex({
              ...imgIndex,
              prodID: key,
              value: --imgIndex.value
            })
          }

        } else {
          setImgIndex({
            prodID: key,
            value: 1
          })
        }
      }

    }

    const handleSelectCategory = (e, newVal) => {
      setCategory(newVal)
    }
    

    return (

      <>
        {promoPopUp()}
        <BodyWrapper>
          {load()}
          {bannerr()}
          {floatingButtons()}
          {props.match.params.leaderID === "CL384219072" ?
            <Tabs
              variant="fullWidth"
              onChange={(e, newVal) => {
                handleSelectCategory(e, newVal)
              }}
              value={category}
              aria-label="Payment Category"
            >
              <Tab label={"Pre-pay"} value={"PrePay"} />
              <Tab label={"Pay On Delivery"} value={"POD"} />
            </Tabs>

            :
            <></>
          }
          <div class='row'>
            {items.map((data, i) => {
              return (
                <div key={`${data.name}-${i}`} class='col-md-6'>
                  <Card style={{ paddingTop: "5px", paddingBottom: "5px", width: "100%", height: "670px" }}>
                    <CardContent>
                      <ImgCard>
                        <Fab variant="extended" size="small" style={floatingButtonAdd2}>
                          <LocalOfferIcon />SAVE {data.originalPrice - data.price}sh
                        </Fab>
                        {imgIndex.prodID === data.key ?
                          <img src={data.imgUrls[imgIndex.value]} style={imgStyle} />
                          :
                          <img src={data.imgUrls[0]} style={imgStyle} />
                        }
                      </ImgCard>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NavigateBeforeIcon onClick={e => { handleSelectPrevImg(e, data.key, data.imgUrls.length) }} fontSize="large" />

                        {data.LimitedQty ?
                          <>
                            <span style={{ flexGrow: 1 }} />
                            <H2 style={{ textAlign: "center" }}><p style={{ color: "red" }}>Limited quantity available!</p></H2>
                            <span style={{ flexGrow: 1 }} />
                          </>
                          :
                          <span style={{ flexGrow: 1 }} />
                        }
                        <NavigateNextIcon onClick={e => { handleSelectNextImg(e, data.key, data.imgUrls.length) }} fontSize="large" />
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-xs-9"><P><b>{data.desc}</b></P></div>
                        <div class="col-xs-3">
                          <P><b>{data.price}sh</b></P>
                          {data.originalPrice === "0" ?
                            <P>-</P>
                            :
                            <P style={{ color: "red" }}><del> {data.originalPrice}sh </del></P>
                          }
                        </div>
                      </div>
                      {data.qtySold < parseInt(data.TargetQty) ?
                        <>
                          <FormLabel>Select Quantity</FormLabel>
                          <RadioGroup
                            name="spacing"
                            aria-label="spacing"
                            defaultValue="0"
                            onChange={e => handleCheck(e, data.key)}
                            row
                          >
                            {data.array.map((value) => (
                              <FormControlLabel
                                key={value}
                                value={value.toString()}
                                control={<Radio />}
                                label={value.toString()}
                              />
                            ))}
                          </RadioGroup>

                          <br />

                          <H2>{data.qtySold} {data.name} already bought & delivered</H2>
                        </>
                        :
                        <>
                          <H2 style={{ textAlign: "center" }}><p style={{ color: "red" }}>Out of stock!</p></H2>
                        </>
                      }

                    </CardContent>
                  </Card>
                  <br />
                </div>
              )
            })}
          </div>
        </BodyWrapper>

      </>
    )
  }

  if (openCart) {

    return <Redirect
      push
      to={{
        pathname: "/shoppingCart",
        state: {
          cartItemsQty: cartItems,
          cartID: cartId,
          leaderID: props.match.params.leaderID,
          POD: POD,
          userID: props.location.state.userId
        }
      }}
    />
  } else if (proceedToSignUp) {

    console.log("SIGN UP")
    return <Redirect
      push
      to={{
        pathname: "/signin/" + props.match.params.leaderID,
        state: {
          groupBuy: false
        }
      }}
    />

  } else {
    return dashboard()
  }


}

export default Dashboard