import React, { useState, useContext, useEffect } from "react"
import { H1, BodyWrapper, P, Button, Spinner, CenteredDiv } from "../components"
import { UserContext } from "../contexts/userContext"
import firebase from "../firebase.js"
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'



const PurchaseHistory = (props) => {

  const [pendingOrderList, setPendingOrderList] = useState([])
  const [displayCart, setDisplayCart] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cartID, setCartID] = useState("")
  const [refresh, setRefresh] = useState(0)
  const [grandTotal, setGrandTotal] = useState("")
  const [inputCode, setInputCode] = useState("")
  const [OTPCode, setOTPCode] = useState(Math.floor(1000 + Math.random() * 9000))
  const [cartItems, setCartItems] = useState([])
  const firestore = firebase.firestore()
  const realtime = firebase.database()

  useEffect(() => {

    const sendOTP = () => {
      const url = 'https://api.tushop.io/api/v1/sms/OTP'
      setLoading(true)
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          OTP: OTPCode,
          to: '+' + props.location.state.userPNo,
          CLID: props.location.state.leaderID,
        }),
      })
        .then(response => {
          setLoading(false)
          if (response.status === 201) {
            return response.json()
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(response => {
          console.debug(response)
          setLoading(false)
          alert("OTP code sent")

        })
        .catch(error => {
          console.error(error)
        })
    }

    if (otpVerified) {

      fetchOrderData()

    } else {

      sendOTP()

    }

  }, [refresh])

  const handleDisplayCart = (e, cartID, Total) => {

    setLoading(true)


    const fetchData = async () => {
      const data = await firestore.collection("Products").get()


      const listCart = []

      data.forEach(async (doc) => {

        const shoppingCartRef = await realtime.ref('shoppingCarts/' + cartID + '/' + doc.id)
        shoppingCartRef.on('value', (snapshot) => {
          const data = snapshot.val()

          if (snapshot.exists()) {
            listCart.push({
              key: doc.id,
              desc: data.productDesc,
              price: data.productPrice,
              quantity: data.quantity,
              total: data.total
            })

          } else {
            console.log("No data available")
            // this.setState({
            //   emptyCart: true
            // })
          }

        })
      })

      setTimeout(() => {

        setCartItems(listCart)
        setGrandTotal(Total)
        setCartID(cartID)
        setDisplayCart(true)
        setLoading(false)

      }, 2000)
    }
    fetchData()

  }

  const handleCloseCart = () => {

    setDisplayCart(false)
    setCartID("")
    setCartItems([])
    setGrandTotal("")

  }

  const load = () => {

    const loader = {
      margin: 0,
      top: 'auto',
      right: 'auto',
      bottom: "50%",
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    if (loading) {
      return (
        <CenteredDiv vertical>
          <Spinner large secondary style={loader} />
        </CenteredDiv>
      )
    }
  }

  const DialogCart = () => {
    return (
      <Dialog open={displayCart} onClose={handleCloseCart} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">CartID: {cartID}</DialogTitle>

        <DialogContent>
          <Table aria-label="simple table" >
            <TableHead>
              <TableRow style={{ height: "5px", width: "100%", backgroundColor: "#d3d3d3" }}>
                <TableCell><b><h2>Description</h2></b></TableCell>
                <TableCell><b><h2>Price</h2></b></TableCell>
                <TableCell></TableCell>
                <TableCell><b><h2>Qty</h2></b></TableCell>
                <TableCell><b><h2>Total</h2></b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map(data =>
                <TableRow key="row1">
                  <TableCell>{data.desc}</TableCell>
                  <TableCell>{data.price}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell><b>{data.total}</b></TableCell>
                </TableRow>
              )}
              <TableRow key="row2">
                <TableCell colSpan={3}><h1>GrandTotal</h1></TableCell>
                <TableCell colSpan={2}><h1>{grandTotal}</h1></TableCell>

              </TableRow>
            </TableBody>
          </Table>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>

    )
  }

  const fetchOrderData = async () => {
    setLoading(true)
    const data = await firestore.collection("Orders").where("CustomerPNo", "==", props.location.state.userPNo).orderBy("DateOfOrder", "desc").get()

    const pendingList = []

    data.forEach(doc => {
      const { CustomerID, CustomerName, CartID, CustomerPNo, DeliveryAddress, LeaderID, LeaderName, TotalPrice, DateOfOrder, CDelivery, POD, Return, Delivered } = doc.data()


      pendingList.push({
        key: doc.id,
        cartID: CartID,
        address: DeliveryAddress,
        leaderID: LeaderID,
        leaderName: LeaderName,
        Total: TotalPrice,
        date: DateOfOrder,
        delivered: Delivered ? "green" : "red",
        pod: POD
      })
    })

    setPendingOrderList(pendingList)
    setLoading(false)

  }

  const verification = () => {

    const handleCheck = (e) => {
      setInputCode(e.target.value)

      if (e.target.value === OTPCode.toString()) {

        setOtpVerified(true)
        fetchOrderData()

      }

    }

    return (
      <BodyWrapper>
        <H1>Verify your identity</H1>
        <P>A four digit OTP code has been sent to your phone number to verify your identity before showing your purchase history</P>
        <P>Please enter the four digit OTP sent to your phone below.</P>

        <TextField
          id="standard-basic"
          style={{
            width: '100%',
            marginTop: '20px',
            alignItems: 'center',
          }}
          value={inputCode}
          inputProps={{ maxLength: 4 }}
          onChange={e => { handleCheck(e) }}
          name="OTP"
          placeholder="OTP"
        />
      </BodyWrapper>

    )
  }

  const purchaseHistory = () => {


    return (
      <BodyWrapper>
        {DialogCart()}
        {load()}
        <H1><p style={{ lineHeight: "1" }}><b>Purchase History:</b> {pendingOrderList.length}</p></H1>
        <TableContainer component={Paper} style={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ background: "#fe7c19" }}>
                <TableCell><P>Order ID</P></TableCell>
                <TableCell><P>Cart ID</P></TableCell>
                <TableCell><P>Delivery Address</P></TableCell>
                <TableCell><P>Date</P></TableCell>
                <TableCell><P>Status</P></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingOrderList.map(data =>
                <TableRow key={data.key} style={{ backgroundColor: data.delivered }}>
                  <TableCell>{data.key}</TableCell>
                  <TableCell><Button onClick={e => handleDisplayCart(e, data.cartID, data.Total)}>{data.cartID}</Button></TableCell>
                  <TableCell>{data.address}</TableCell>
                  <TableCell>{data.date}</TableCell>
                  <TableCell> {data.pod ? "POD" : "Paid"}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </BodyWrapper>
    )
  }

  if (otpVerified) {

    return purchaseHistory()

  } else {
    return verification()
  }

}

export default PurchaseHistory
