import React, { useState, useContext, useEffect } from "react";
import { H1, P, H2, Button, BodyWrapper, Spinner, CenteredDiv } from "../components";
import { ToastContext } from "../contexts/toastContext";
import { BrowserRouter as Router,
          Redirect, } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import firebase from "../firebase.js";
import "firebase/firestore";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const Dashboard = (props) => {

  const [items, setItems] = useState([]);

  const [specialItems, setSpecialItems] = useState([]);

  const [openCart, setOpenCart] = useState(false);

  const [verifyURL, setVerifyURL] = useState(true)

  const [loading, setLoading] = useState(false)

  const [CLexists, setCLexists] = useState(false);

  const [cartId, setCartId] = useState("");

  const [leaderID, setLeaderID] = useState("");

  const [leaderName, setLeaderName] = useState("");

  const [leaderPNo, setLeaderPNo] = useState("");

  const [quantity, setQuantity] = useState("0");

  const [cartItems, setCartItems] = useState(0);

  const { sendMessage } = useContext(ToastContext);

  useEffect(() => {
    const firestore = firebase.firestore();

    const fetchData = async () => {
      const data = await firestore.collection("Products").orderBy("Order", "asc").get()

      console.log(cartId)
      
      const list = []
      const specialList = []

      data.forEach(doc => {
        const { Name, Description, ImgUrl, Price,QtySold, Active, Special} = doc.data();

        if(Special){
          specialList.push({
            key: doc.id,
            name: Name,
            desc: Description,
            imgUrl: ImgUrl,
            price: Price,
            array: [1,2]
          })

        }

        if(props.location.state.leaderID === "CL205564"){

          if(doc.id === "T337989" || doc.id === "T857688" || doc.id === "T968719" || doc.id === "T447232" || doc.id === "T714645" || doc.id === "T978442"){

            list.push({
              key: doc.id,
              name: Name,
              desc: Description,
              imgUrl: ImgUrl,
              qtySold: QtySold,
              price: Price,
              array: [1,2,3,4,5,10]
            });

          }

        }else if(Active){

            if(doc.id === "T152114"){

              list.push({
                key: doc.id,
                name: Name,
                desc: Description,
                imgUrl: ImgUrl,
                qtySold: QtySold,
                price: Price,
                array: [1,2,3,4,5,10]
              });

            }else{
              list.push({
                key: doc.id,
                name: Name,
                desc: Description,
                imgUrl: ImgUrl,
                qtySold: QtySold,
                price: Price,
                array: [1,2,3,4,5,10]
              });
  
              specialList.push({
                key: doc.id,
                name: Name,
                desc: Description,
                imgUrl: ImgUrl,
                qtySold: QtySold,
                price: Price,
                array: [1,2,3,4,5,10]
              });
            }


          
        }

        
        
      });

      setItems(list)
      setSpecialItems(specialList)

    }

    const updateLeaderInfo = () => {

      firestore.collection("CommunityLeaders").doc(props.location.state.LeaderID).get().then((doc) => {

        if(doc.exists){

          const { Name, PhoneNumber } = doc.data()

          setLeaderID(props.location.state.LeaderID)
          setLeaderName(Name)
          setLeaderPNo(PhoneNumber)
          setCLexists(true)
          setCartItems(props.location.state.cartItems)
          setCartId(props.location.state.cartID)
          
          fetchData()
        }


      })

    }

    updateLeaderInfo()

  }, []);

  const handleOpenCart = () => {

    setOpenCart(true)
    

    
  }

  const handleCheck = (event) => {
    setQuantity(event.target.value)
    
  }

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(e.target.getAttribute('id')).then(() => {
      console.log("COPIED!")
    })
  }

  const  handleAddtoCart = (e, key) => {

    setLoading(true)

    console.log("Hellooo")

    console.log(key, cartId)

    const firestore = firebase.firestore();

    const fetchData = async () => {

      const myProduct = firestore.collection("Products").doc(key);
      const doc = await myProduct.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {

        if(quantity === "0"){
          sendMessage("Select Quantity")
          setLoading(false)
        }else{

          const product = doc.data()

          const total = product["Price"] * quantity
          // console.log('Document data:', product["Price"]);
          const itemRef = firebase.database().ref('shoppingCarts/' + cartId + '/' + key);

          itemRef.once('value').then((snapshot) => {
            if(snapshot.exists()){
              itemRef.set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: product["Price"],
                quantity : quantity,
                total: total
              });
              console.log("Item exists");
              setLoading(false)

              sendMessage("Item Quantity Updated!")

              setQuantity("0")
            }else{
              itemRef.set({
                productDesc: product["Description"],
                productID: product["ProductID"],
                productPrice: product["Price"],
                quantity : quantity,
                total: total
              });

              setCartItems(cartItems + 1)

              setLoading(false)

              sendMessage("Item added to cart!")

              setQuantity("0")

            }
          })

        }

      }
    };

    fetchData();
  }

  const dashboard = () => { 
    const imgStyle = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100%",
    };

    const rowStyle = {
      textAlign:"center",
      width:"100%",
      marginTop: "auto"
    }

    const banner = {
      position: "absolute",
      left: 0,
      backgroundColor: "#efa83d",
      width: "100%",
      textAlign: "center",
    }

    const table = {
      paddingTop: "170px"
    }

    const price = {
      textAlign: "right",
      width: "1%",
      color: "green",
      height: "5px", 
      padding: "0px"
    }

    const floatingButton = {
      margin: 0,
      top: 80,
      right: 20,
      bottom: 'auto',
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const loader = {
      margin: 0,
      top: 'auto',
      right: 'auto',
      bottom: "50%",
      left: 'auto',
      position: 'fixed',
      zIndex: 1
    }

    const welcome = {
      fontSize: "5px"
    }
    
    return (

      <>
      <BodyWrapper>
        {loading?
          <CenteredDiv vertical>
            <Spinner large secondary style={loader}/>
          </CenteredDiv>
        :
          <>
          </>
        }
        <div style={banner}>
          <H1 style={welcome}>Welcome!</H1>
          <H2>To {leaderName.split(" ")[0]}'s Shop </H2>
          <H2>For inquiries, please call <a href="#" onClick={() => {navigator.clipboard.writeText(leaderPNo)}}>{leaderPNo}</a> </H2>
          <P>Order before 11pm, get items the following day</P>
          <P><i>(PS. We are closed on Sundays and public holidays)</i></P>
        </div>
        <Fab onClick={handleOpenCart} color="primary" style={floatingButton}>
          <Badge color="secondary">
            <ShoppingCartOutlinedIcon/>
          </Badge>        
        </Fab>
          <div style={table}>
            <Table aria-label="simple table">
              <TableHead>
            
            </TableHead>
            {leaderID === "CL380726" || leaderID === "CL953645" || leaderID === "CL490758"|| leaderID === "CL938990" || leaderID === "CL721798" || leaderID === "CL669953"|| leaderID === "CL681147" || leaderID === "CL504194" || leaderID === "CL485097"?
              specialItems.map(data =>
                <TableBody>
                  <TableRow key="row1" style={rowStyle}>
                    <TableCell colSpan="2"><img src={data.imgUrl} style={imgStyle}/></TableCell>
                  </TableRow>
                  <TableRow key="row2">
                    <TableCell style={{ height: "2px", padding: "0px"}}><H2><b>{data.desc}</b></H2></TableCell>
                    <TableCell style={price}><H2><b>{data.price}Ksh</b></H2></TableCell>
                  </TableRow>
                  <TableRow key="row3">
                    <TableCell colSpan="2">
                      <FormLabel>Select Quantity</FormLabel>
                      <RadioGroup
                        name="spacing"
                        aria-label="spacing"
                        defaultValue="0"
                        onChange={handleCheck}
                        row
                      >
                        {data.array.map((value) => (
                          <FormControlLabel
                            key={value}
                            value={value.toString()}
                            control={<Radio/>}
                            label={value.toString()}
                          />
                        ))}
                      </RadioGroup>
                      <Button style={{width: "100%"}} onClick={e => handleAddtoCart(e, data.key)}>ADD TO CART </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) 
            :
            items.map(data =>
              <TableBody>
                <TableRow key="row1" style={rowStyle}>
                  <TableCell colSpan="2"><img src={data.imgUrl} style={imgStyle}/></TableCell>
                </TableRow>
                <TableRow key="row2">
                  <TableCell style={{ height: "2px", padding: "0px"}}><H2><b>{data.desc}</b></H2></TableCell>
                  <TableCell style={price}><H2><b>{data.price}Ksh</b></H2></TableCell>
                </TableRow>
                <TableRow key="row4">
                  <TableCell colSpan="2">
                    <FormLabel>Select Quantity</FormLabel>
                    <RadioGroup
                      name="spacing"
                      aria-label="spacing"
                      defaultValue="0"
                      onChange={handleCheck}
                      row
                    >

                      {data.array.map((value) => (
                        <FormControlLabel
                          key={value}
                          value={value.toString()}
                          control={<Radio/>}
                          label={value.toString()}
                        />
                      ))}
                    </RadioGroup>
                    
                    <Button style={{width: "100%"}} onClick={e => handleAddtoCart(e, data.key)}>ADD TO CART </Button>
                  </TableCell>
                </TableRow>
                <TableRow key="row3">
                  <TableCell colSpan="2" style={{ height: "2px", padding: "0px"}}><H2>{data.qtySold} {data.name} already bought & delivered</H2></TableCell>
                </TableRow>
              </TableBody>
            )}
            </Table>
          </div>
      </BodyWrapper>

      </>
    );
  };

  const noCL = () => {

    return (
      <BodyWrapper>
        <H2>Link does not exist</H2>
        <P>Contact support for assistance</P>
      </BodyWrapper>
    )

  }

  const verifyTheURL = () => {

    return (
      <BodyWrapper>
        <CenteredDiv vertical>
          <H2>Verifying URL...</H2>
          <P>Please wait</P>
          <Spinner large secondary />
        </CenteredDiv>
      </BodyWrapper>
    )

  }

  if(CLexists){

    if(openCart){
      
      return <Redirect
      to={{
            pathname: "/shoppingCart",
            state: {  
              cartID: props.location.state.cartID,
              leaderID: props.location.state.LeaderID,
              referral: "",
              POD: true 
            }
      }}
      />
  
    }else{
      return dashboard()
    }

  }else{

    if(verifyURL){
      return verifyTheURL()
    }else{
      return noCL()
    }
  }

  
};

export default Dashboard;
