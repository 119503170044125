import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { P, H1, H2,  Button, Spinner, CenteredDiv, BodyWrapper } from "../components";
import firebase from "../firebase.js";
import "firebase/database";
import Table from '@material-ui/core/Table';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class Cart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      CartId: "", 
      CustomerID: "", 
      CustomerName: "", 
      CustomerPNo: "",
      LeaderID: "",
      CartItems: [],
      GrandTotal: 0,
      emptyCart: false,
      buyNow: false,
      continueShopping: false,
      loading: true,
      invID: "INV"+Math.floor(100000000 + Math.random() * 900000000),
      orderID: "OD"+Math.floor(100000000 + Math.random() * 900000000),
      transID: "TR"+Math.floor(100000000 + Math.random() * 900000000),
    }

  }    

  componentWillMount = () => {

    const firestore = firebase.firestore();

    let list = [...this.state.CartItems];

    const fetchAutoCartData = async () => {

      const autoCartID = this.props.match.params.autoCartID

      const dbAutoCartID = await firestore.collection("Carts").doc(autoCartID)
      
      dbAutoCartID.get().then((doc) => {

        if(doc.exists){

          const { CartId, CustomerID, CustomerName, LeaderID } = doc.data()

          this.setState({

            CartId: CartId, 
            CustomerID: CustomerID, 
            CustomerName: CustomerName,
            LeaderID: LeaderID
          }, () => {

            getCustomerPNO(CustomerID)
            fetchData()
          })

        }else{



        }

      })

    }

    const getCustomerPNO = async (id) => {

      const dbCustomer = await firestore.collection("Customers").doc(id)

      dbCustomer.get().then((doc) => {
        const {PhoneNumber} = doc.data()

        this.setState({
          CustomerPNo: PhoneNumber
        })


      })



    }

    const fetchData = () => {

      const cartID = this.state.CartId;

      const totals = []
      
      const shoppingCartRef = firebase.database().ref('shoppingCarts/' + cartID);
      shoppingCartRef.on('value', (snapshot) => {

        if (snapshot.exists()) {

          snapshot.forEach((childSnapshot) => {

            if(childSnapshot.key === "grandTotal"){
              console.log("IGNORE")
            }else{

              const data = childSnapshot.val();

              list.push(data)

              totals.push(data.total)

              updateList(data)
  
              console.log("DATA GOTTEN ITEM NO: ", data.productID)
            }

          })

          setGrandTotal(totals)

          
          this.setState({
            loading: false
          })
          

          // Code for adding grandtotal to cart record


        } else {
          console.log("No data available");
          // this.setState({
          //   emptyCart: true
          // })
        }
      
      });
        
    }

    const updateList = (data) => {

      this.setState({
        CartItems: list
      })

      

    }  

    const setGrandTotal = (array) => {

      const add = (arr) => {
          return arr.reduce((a, b) => a + b, 0);
      };

      const GrandTotal = add(array)

        this.setState({
          GrandTotal: GrandTotal
        }, () => {
          console.log("GTT: " , this.state.GrandTotal)
        })
    }
    
    fetchAutoCartData()
  }



  handleBuyItems = () => {

    this.setState({
      loading: true
    }, () => {

      const cartID = this.state.CartId;

      firebase.database().ref('shoppingCarts/' + cartID).update({
        grandTotal: this.state.GrandTotal,
      }).then(() => {
  
        this.createInvoice()
  
      })

    })

  }

  handleDelete = (e, productID) => {

    const cartID = this.state.CartId;

    const cartRef = firebase.database().ref('shoppingCarts/' + cartID).child(productID)

    cartRef.remove()

    window.location.reload();

  }

  handleContinueShopping = () => {
    this.setState({
      continueShopping: true
    })
  }

  createInvoice = () => {

    const firestore = firebase.firestore();

    const date = new Date();
    const today = date.getFullYear()+
                  '-'+
                  ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
                  '-'+
                  (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
                  ' T '+
                  (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
                  ":"+ 
                  (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));
      
    firestore.collection('Invoice').doc(this.state.invID).set({
      InvoiceID: this.state.invID,
      CartID: this.state.CartId,
      UserID: this.state.CustomerID,
      LeaderID: this.props.location.state.leaderID,
      transactionID: this.state.transID,
      paid: false,
      referralUpdated: false,
      POD: false,
      cancelled: false,
      fullNo: this.state.CustomerPNo,
      total: this.state.GrandTotal,
      CheckoutRequestID: "",
      orderID: this.state.orderID,
      STKRequestSent: false,
      created_at: today,
      updated_at: today,
    }).then(() => {

      console.log("INVOICE ID: ", this.state.invID)

      this.setState({
        loading: false,
        buyNow: true
      })
      
    })

  }

  render(){

      const cart = () => {
        return(

          <BodyWrapper>
            {this.state.CustomerName === ""? 
              <></> 
            :
              <H1>{this.state.CustomerName.split(" ")[0]}'s Cart!</H1>
            }
              {/* <P>
                Cart ID: {cartID}
              </P> */}

          {this.state.loading?
            <CenteredDiv vertical>
              <Spinner large secondary style={{ margin: 0, top: 'auto', right: 'auto', bottom: "50%", left: 'auto', position: 'fixed', zIndex: 1 }}/>
            </CenteredDiv>
          :
            <>
            </>
          }
            <>
              <hr width="100%" style={{color:'grey'}}/>
              <Table aria-label="simple table" >
                <TableHead>
                  <TableRow style={{height: "5px", width: "100%", backgroundColor: "#d3d3d3"}}>
                    <TableCell><b><H2>Description</H2></b></TableCell>
                    <TableCell><b><H2>Price</H2></b></TableCell>
                    <TableCell><b><H2>Qty</H2></b></TableCell>
                    <TableCell><b><H2>Total</H2></b></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {!this.state.emptyCart?
                <TableBody>
                {this.state.CartItems.map(data =>
                  <TableRow key={data.productID}>
                    <TableCell><P>{data.productDesc}</P></TableCell>
                    <TableCell><P>{data.productPrice}</P></TableCell>
                    <TableCell><P>{data.quantity}</P></TableCell>
                    <TableCell><b><P>{data.total}</P></b></TableCell>
                    <TableCell>                    
                      <Button variant="contained" onClick={e => this.handleDelete(e, data.productID)}>
                        <DeleteForeverIcon/>
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
                  <TableRow>
                    <TableCell colSpan="3" style={{textAlign: "right"}}><b><H2>GRAND TOTAL</H2></b></TableCell>
                    <TableCell colSpan="2"><b><H2>KES {this.state.GrandTotal}</H2></b></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="5" style={{textAlign: "center"}}><Button style={{width: "100%"}} onClick={this.handleContinueShopping}>CONTINUE SHOPPING</Button></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="5" style={{textAlign: "center"}}><Button style={{width: "100%"}} onClick={this.handleBuyItems}>PROCEED TO PAYMENT </Button></TableCell>
                  </TableRow>
                </TableBody>:
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="5" style={{textAlign: "center"}}> <b><P>Empty Cart</P></b></TableCell>
                  </TableRow>
                </TableBody>
                }
              </Table>
            </>
          </BodyWrapper>

        )
      }

      if(this.state.continueShopping){

        return <Redirect
          to={{
              pathname: "/addItems",
              state:  { 
                cartItems: this.state.CartItems,
                LeaderID: this.props.location.state.leaderID,
                cartID: this.state.CartId,
                AutoCartID: this.props.match.params.autoCartID
              }
          }}
        />

      }else if(!this.state.buyNow){
        return cart();
      } else{
        return <Redirect
          push
          to={{
              pathname: "/paymentPage",
              state:  { 
                invoiceID: this.state.invID,
                orderID: this.state.orderID,
                returnC: true,
                userID: this.state.CustomerID,
                referralID: ""
              }
          }}
        />
      }
  }
};

export default Cart;
