import React, { useState, useContext } from "react"
import { P, H1, H2, CenteredDiv, Spinner, BodyWrapper, Button } from "../components"
import { Checkmark } from 'react-checkmark'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import firebase from "../firebase.js"
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import "firebase/database"
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'

class PaymentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderID: this.props.location.state.orderID,
      requestSent: false,
      paid: false,
      cancelled: false,
      POD: false,
      total: 0,
      orderCreated: false,
      leaderID: "",
      leaderName: "",
      leaderPNo: "",
      feedback: "",
      displayFeedback: false,
      displayRegisterCL: false,
      displayThankyouNote: false,
      referralUpdated: false,
      QueueCreated: false,
      CLRequestID: "CLR" + Math.floor(100000000 + Math.random() * 900000000),
      ProductID: "",
      ProductName: "",
      ProductPrice: "",
      Quantity: "0",
      GBQID: "",
      UserID: "",
      GBID: "",
      popup: false,
      rate: "0",
      CName: "",
      CEmail: "",
      CAddress: "",
      CPNo: ""
    }


  }

  componentWillMount() {

    const firestore = firebase.firestore()

    const fetchData = async () => {

      console.log("INV ID: ", this.props.location.state.invoiceID)

      const data = await firestore.collection("Invoice").doc(this.props.location.state.invoiceID)

      data.get().then((doc) => {

        if (doc.exists) {

          console.log("EXISTS")
          console.log(this.props.location.state.invoiceID)

          const { CartID, orderID, GBID, LeaderID, POD, fullNo, total, cancelled, referralUpdated, UserID } = doc.data()

          console.log(orderID)

          this.setState({
            GBQID: CartID,
            UserID: UserID,
            GBID: GBID,
            CPNo: fullNo,
            total: parseInt(total),
            leaderID: LeaderID,
            POD: POD
          }, () => {
            fetchProductDetails(CartID)
          })


        }

      })


    }

    const fetchProductDetails = (cartID) => {
      const shoppingCartRef = firebase.database().ref('shoppingCarts/' + cartID)
      shoppingCartRef.once('value').then((snapshot) => {

        if (snapshot.exists()) {

          snapshot.forEach((childSnapshot) => {

            if (childSnapshot.key === "grandTotal") {
              this.setState({
                total: parseInt(childSnapshot.val())
              })
            } else {

              const data = childSnapshot.val()

              this.setState({
                ProductID: data.productID,
                ProductName: data.productDesc,
                ProductPrice: data.productPrice,
                Quantity: data.quantity
              }, () => {
                this.handleCheckGroupBuyRequest()
              })
            }

          })


          // Code for adding grandtotal to cart record


        } else {
          console.log("No data available")
          // this.setState({
          //   emptyCart: true
          // })
        }
      })
    }


    fetchData()
  }

  handleCheckGroupBuyRequest = () => {
    console.log("OYAA CONFIRM!")

    const firestore = firebase.firestore()

    const date = new Date()

    const today = date.getFullYear() +
      '-' +
      ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? (date.getDate()) : '0' + (date.getDate())) +
      'T' +
      (date.getHours() > 9 ? (date.getHours()) : '0' + (date.getHours())) +
      ":" +
      (date.getMinutes() > 9 ? (date.getMinutes()) : '0' + (date.getMinutes()))

    firestore.collection("GBQueue").where("GBID", "==", this.state.GBID).where("CustomerID", "==", this.state.UserID).get().then((doc) => {

      if (!doc.empty) {

        console.log(doc)

        console.log(this.state.GBID)

        this.setState({
          QueueCreated: true,
        })
        alert("Group Buy Queue already exists")
      } else {

        if (this.state.POD) {

          firestore.collection("GBQueue").doc(this.state.GBQID).set({
            GBID: this.state.GBID,
            CustomerID: this.state.UserID,
            ProductID: this.state.ProductID,
            Quantity: this.state.Quantity,
            CreatedAt: today,
            POD: true,
            Refund: false,
            STKRequestSent: false,
            Paid: false,
            Cancelled: false,
            Processed: false,
            CheckoutRequestID: ""
          }).then(() => {
            firestore.collection("GroupBuy").doc(this.state.GBID).get().then((doc) => {
              const { SoldQty } = doc.data()

              firestore.collection("GroupBuy").doc(this.state.GBID).update({
                SoldQty: SoldQty + parseInt(this.state.Quantity)
              }).then(() => {

                this.setState({
                  QueueCreated: true,
                }, () => {

                  alert("Added to queue")

                })
              }).then(() => {
                this.checkGBQuantity(this.state.GBID)
              })

            })

          })
        } else {

          firestore.collection("GBPrePay").doc(this.state.GBQID).set({
            GBID: this.state.GBID,
            CustomerID: this.state.UserID,
            ProductID: this.state.ProductID,
            Quantity: this.state.Quantity,
            CreatedAt: today,
            POD: true,
            Refund: false,
            STKRequestSent: false,
            Paid: false,
            Cancelled: false,
            Processed: false,
            CheckoutRequestID: ""
          }).then(() => {

            this.handlePayNow()

          })

        }

      }

    })

  }

  checkGBQuantity = (gbid) => {

    const url = "https://api.tushop.io/api/v1/GetGBStatus"

    console.log("ohaa")
    console.log(gbid)
    console.log(this.state.leaderID)
    fetch(url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          GBID: gbid,
          LeaderID: this.state.leaderID
        })
      }
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 500) {

          throw new Error('Invalid Creds!')
        } else {

          throw new Error('Something went wrong on api server!')
        }
      })
      .then(response => {
        console.log("OLAA")
        console.debug(response)

      }).catch(error => {
        console.log("OLAA22")
        console.error(error)
      })
  }

  handlePayNow = () => {

    const url = "https://api.tushop.io/api/v1/SendQuikkGBSTK"

    fetch(url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          GBQID: this.state.GBQID,
          amount: this.state.total,
          phoneNumber: this.state.CPNo
        })
      }
    ).then(response => {
      if (response.status === 201) {

        this.setState({
          requestSent: true
        }, () => {

          if (!this.state.paid) {

            this.checkPaymentStatus()

          }
        })
        return response.json()
      } else {
        throw new Error('Something went wrong on api server!')
      }
    }).then(response => {
      console.debug(response)
    }).catch(error => {
      console.error(error)
    })
  }

  checkPaymentStatus = () => {

    const firestore = firebase.firestore()

    setInterval(() => {

      console.log("HIOOOOOOO!!!")

      firestore.collection("GBQueue").doc(this.state.GBQID).get().then((doc) => {

        if (doc.exists) {

          const { GBID, Paid } = doc.data()

          if (Paid && !this.state.paid && !this.state.QueueCreated) {
            this.setState({
              paid: true,
              QueueCreated: true
            }, () => {
              this.checkGBQuantity(GBID)
            })
          }
        } else {

          firestore.collection("GBPrePay").doc(this.state.GBQID).get().then((doc2) => {

            const { Cancelled } = doc2.data()

            if (Cancelled) {

              this.setState({
                cancelled: true,
              })

            }

          })


          console.log("Queue not yet created!")
        }
      })
    }, 3000)
  }




  render() {

    const CLlink = "https://shop.tushop.io/groupBuy/" + this.state.leaderID
    const message = "Join me in buying together to get " + "*" + this.state.ProductName + "*" + " at " + "*" + this.state.ProductPrice + "sh!* Offer expires in *24hrs*. Add your name to the list and share link with your friends -> " + CLlink

    const whatsappLink = "https://api.whatsapp.com/send?phone=&text=" + message

    return (
      <BodyWrapper>
        <H1><p style={{ lineHeight: "1" }}>Group Buy Queue</p></H1>
        <>
          {!this.state.POD ?
            <>
              {this.state.requestSent ?
                <>
                  <H2>Payment Request sent!</H2>

                  <H2>Check your phone to complete payment.</H2>

                  <P><b>Invoice ID : </b> {this.props.location.state.invoiceID}</P>


                  {!this.state.paid ?
                    <CenteredDiv vertical >
                      {!this.state.cancelled ?
                        <>
                          <Spinner large secondary />

                          <H2 style={{ display: "flex" }}>Awaiting Payment confirmation</H2>
                        </>
                        :
                        <>
                          <CancelOutlinedIcon style={{ color: "red", fontSize: '700%' }} />
                          <H2>Payment Cancelled!</H2>
                        </>
                      }
                    </CenteredDiv>
                    :
                    <>
                      <CenteredDiv vertical>
                        <Checkmark size='xxLarge' />

                        <H2>Payment Received!</H2>

                        {!this.state.QueueCreated ?
                          <>
                            <Spinner large secondary />

                            <H2 style={{ display: "flex" }}>Creating Queue</H2>
                          </>
                          :
                          <>
                            <Checkmark size='xxLarge' />

                            <H2>Queue created!</H2>

                            <H2>Queue ID: {this.state.GBQID}</H2>

                            <Button><WhatsAppIcon /> <a href={whatsappLink} target="_blank">Share via WhatsApp</a></Button>

                            <P>Share with friends to be able to hit the group buy target</P>

                          </>
                        }
                      </CenteredDiv>

                    </>
                  }

                </>


                :
                <CenteredDiv>
                  <H2>Sending M-pesa payment Request</H2>

                  <H2>Please wait.</H2>
                </CenteredDiv>
              }
            </>
            :
            <>
              {!this.state.QueueCreated ?
                <CenteredDiv vertical >
                  <H2>Creating Queue</H2>
                  <Spinner large secondary />
                </CenteredDiv>
                :
                <>
                  <H2>Added to Queue</H2>
                  <Checkmark size='xxLarge' />

                  <Button><WhatsAppIcon /> <a href={whatsappLink} target="_blank">Share via WhatsApp</a></Button>

                  <P>Share with friends to be able to hit the group buy target</P>

                </>
              }
            </>
          }
        </>
      </BodyWrapper>

    )

  }
};

export default PaymentPage
