import React, { useState, useContext } from 'react'
import {
  P,
  H1,
  H2,
  CenteredDiv,
  Spinner,
  BodyWrapper,
  Button,
} from '../components'
import { Checkmark } from 'react-checkmark'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import firebase from '../firebase.js'
import 'firebase/database'
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import { UserContext } from '../contexts/userContext'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Rating from '@material-ui/lab/Rating'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'

class PaymentPage extends React.Component {

  static contextType = UserContext

  constructor(props) {

    super(props)
    this.state = {
      orderID: this.props.location.state.orderID,
      requestSent: false,
      paid: false,
      cancelled: false,
      POD: false,
      total: 0,
      orderCreated: false,
      leaderName: '',
      leaderPNo: '',
      feedback: '',
      displayFeedback: false,
      displayRegisterCL: false,
      displayThankyouNote: false,
      referralUpdated: false,
      proceedToHistory: false,
      CLRequestID: 'CLR' + Math.floor(100000000 + Math.random() * 900000000),
      popup: false,
      rate: 0,
      CName: '',
      CEmail: '',
      CAddress: '',
      CPNo: '',
      downtime500: false,
      downtime400: false,
    }
  }

  componentWillMount() {
    const firestore = firebase.firestore()

    this.context.userDispatch({ type: "signOut" })

    const fetchData = async () => {
      console.log('INV ID: ', this.props.location.state.invoiceID)

      const data = await firestore
        .collection('Invoice')
        .doc(this.props.location.state.invoiceID)

      data.get().then(doc => {
        if (doc.exists) {
          console.log('EXISTS')
          console.log(this.props.location.state.invoiceID)

          const {
            orderID,
            total,
            fullNo,
            POD,
            paid,
            STKRequestSent,
            cancelled,
            referralUpdated,
            UserID,
          } = doc.data()

          fetchCustomerDetails(UserID)

          this.setState(
            {
              referralUpdated: referralUpdated,
            },
            () => {
              if (paid) {
                console.log('LIPWAAAA')
                this.setState(
                  {
                    orderID: orderID,
                    total: total,
                    paid: paid,
                    fullNo: fullNo,
                    requestSent: STKRequestSent,
                  },
                  () => {
                    this.checkOrderStatus()
                  }
                )
              } else {
                if (POD) {
                  this.setState(
                    {
                      orderID: orderID,
                      total: total,
                      fullNo: fullNo,
                      POD: POD,
                    },
                    () => {
                      this.sendSTKRequest(fullNo, total)
                    }
                  )
                } else {
                  this.sendSTKRequest(fullNo, total)
                }
              }
            }
          )

          console.log(paid)
        }
      })
    }

    const fetchCustomerDetails = UID => {
      firestore
        .collection('Customers')
        .doc(UID)
        .get()
        .then(doc => {
          const { Name, Email, Address, PhoneNumber } = doc.data()

          this.setState({
            CName: Name,
            CEmail: Email,
            CAddress: Address,
            CPNo: PhoneNumber,
          })
        })
    }

    fetchData()
  }

  componentDidMount() {
    if (!this.state.paid) {
      this.interval = setInterval(() => this.confirmPayment(), 5000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  sendSTKRequest = (fullNo, total) => {
    if (this.state.POD) {
      const url = 'https://api.tushop.io/api/v1/CreatePODOrder/'

      fetch(url + this.props.location.state.invoiceID, { method: 'GET' })
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else if (response.status === 500) {
            alert(response.message)
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(response => {
          console.debug(response)
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      if (!this.state.requestSent) {
        const url = 'https://api.tushop.io/api/v1/SendQuikkSTK'

        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            InvID: this.props.location.state.invoiceID,
            amount: total,
            phoneNumber: fullNo,
          }),
        })
          .then(response => {
            if (response.status === 201) {
              this.setState({
                requestSent: true,
              })
              return response.json()
            } else if (response.status === 500) {
              this.setState({
                downtime500: true,
              })
              setTimeout(() => {
                alert(
                  'Session timeout, please cancel payment request and refresh'
                )
              }, 4000)
            } else if (response.status === 400) {
              this.setState({
                downtime400: true,
              })
              setTimeout(() => {
                alert('Transaction failed')
              }, 4000)
            } else {
              throw new Error('Something went wrong on api server!')
            }
          })
          .then(response => {
            console.debug(response)
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  }

  confirmPayment = async () => {
    const firestore = firebase.firestore()

    const data = await firestore
      .collection('Invoice')
      .doc(this.props.location.state.invoiceID)

    data.get().then(doc => {
      if (doc.exists) {
        const { paid, cancelled, STKRequestSent, POD } = doc.data()

        if (!POD) {
          if (STKRequestSent) {
            this.setState(
              {
                requestSent: true,
              },
              () => {
                if (paid) {
                  this.setState(
                    {
                      paid: paid,
                    },
                    () => {
                      if (!this.state.orderCreated) {
                        this.checkOrderStatus()
                      }
                    }
                  )
                } else if (cancelled) {
                  this.setState({
                    cancelled: cancelled,
                  })
                }
              }
            )
          }
        } else {
          if (!this.state.orderCreated) {
            this.checkOrderStatus()
          }
        }
      }
    })
  }

  checkOrderStatus = async () => {
    console.log('OLA2')

    const firestore = firebase.firestore()

    const data = await firestore.collection('Orders').doc(this.state.orderID)

    data.get().then(doc => {
      if (doc.exists) {
        const { LeaderName, leaderPNo } = doc.data()

        if (LeaderName === '' || leaderPNo === '') {
          this.checkOrderStatus()
        } else {
          data
            .update({
              Return: this.props.location.state.returnC,
            })
            .then(() => {
              this.setState(
                {
                  leaderName: LeaderName,
                  leaderPNo: leaderPNo,
                  orderCreated: true,
                },
                () => {
                  if (this.props.location.state.referralID !== '') {
                    this.updateReferrals()
                  }

                  setTimeout(() => {
                    this.setState({
                      displayFeedback: true,
                    })
                  }, 3000)
                }
              )
            })
        }
      }
    })
  }

  updateReferrals = async () => {
    const url = 'https://api.tushop.io/api/v1/UpdateReferrals/'

    await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerID: this.props.location.state.userID,
        referralID: this.props.location.state.referralID,
        invoiceID: this.props.location.state.invoiceID,
      }),
    })
      .then(response => {
        if (response.status === 201) {
          return response.json()
        } else {
          throw new Error('Something went wrong on api server!')
        }
      })
      .then(response => {
        this.setState({
          referralUpdated: true,
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  feedback = () => {
    const date = new Date()
    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    const handleSubmitFeedback = () => {
      if (this.state.feedback === '') {
        alert('Please input valid feedback')
      } else if (this.state.rate === 0) {
        alert('Please rate us on a scale of 1 to 5')
      } else {
        const firestore = firebase.firestore()

        const fbid = 'FB' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('Feedback')
          .doc(fbid)
          .set({
            UserID: this.props.location.state.userID,
            Feedback: this.state.feedback,
            Rate: this.state.rate,
            DateOfFeedback: today,
          })
          .then(() => {
            this.setState({
              displayFeedback: false,
              feedback: '',
              rate: '0',
            })
          })
      }
    }

    const handleCloseCart = () => {
      this.setState({
        displayFeedback: false,
        feedback: '',
        rate: '0',
      })
    }

    const handleCheck = (event, newValue) => {
      this.setState({
        rate: newValue,
      })
    }

    const valuetext = value => {
      return `${value}`
    }

    const handleRequestCL = () => {
      this.setState({
        displayFeedback: false,
        displayRegisterCL: true,
      })
    }

    return (
      <Dialog
        open={this.state.displayFeedback}
        onClose={handleCloseCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <H1>
            <p style={{ color: '#fe7c19' }}>Hey!</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <H2>Got a minute?</H2>
          <P>
            Please spare a moment to share your feedback about your experience
            shopping with Tushop
          </P>
          <br />
          <P style={{ textAlign: 'center' }}>Rate us</P>

          <P style={{
            textAlign: 'center',
          }}>
            <Rating
              name="hover-feedback"
              size="large"
              value={this.state.rate}
              onChange={(event, newValue) => {
                this.setState({
                  rate: newValue
                })
              }}
            />
          </P>
          <P>How can we do better? Please tell us below:</P>
          <TextareaAutosize
            aria-label='empty textarea'
            name='feedback'
            id='feedback'
            style={{ width: '100%' }}
            rowsMin={3}
            onChange={e => {
              this.setState({ feedback: e.target.value })
            }}
            placeholder='Feedback'
            value={this.state.feedback}
          />
          <P>
            <a onClick={handleRequestCL}>
              <p style={{ color: '#fe7c19', display: 'inline' }}>Click here!</p>
            </a>{' '}
            if you are interested in becoming a Community Leader
          </P>
          <P>
            <i>(Pssst You’ll earn commissions)</i> 🤑😛{' '}
          </P>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitFeedback}>SUBMIT</Button>

          <Button onClick={handleCloseCart} color='#fe7c19'>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  CLRequest = () => {
    const firestore = firebase.firestore()

    const date = new Date()
    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    const handleCloseDialog = () => {
      this.setState({
        displayFeedback: false,
      })
    }

    const handleSubmitCLRequest = () => {
      if (this.state.CName === '') {
        alert('Input Valid Name')
      } else if (this.state.CEmail === '') {
        alert('Input Valid Email')
      } else if (this.state.CPNo === '') {
        alert('Input Valid Phone Number')
      } else if (this.state.CAddress === '') {
        alert('Input Valid Address')
      } else {
        firestore
          .collection('CLRequests')
          .doc(this.state.CLRequestID)
          .set({
            Name: this.state.CName,
            Email: this.state.CEmail,
            PhoneNumber: this.state.CPNo,
            Address: this.state.CAddress,
            Date: today,
            Referral: false,
          })
          .then(() => {
            this.setState({
              displayRegisterCL: false,
              displayThankyouNote: true,
            })
          })
      }
    }

    return (
      <Dialog
        open={this.state.displayRegisterCL}
        onClose={handleCloseDialog}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <H1>
            <p style={{ color: '#fe7c19' }}>CL Sign up!</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <P>Confirm your Details below and submit your request</P>
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => this.setState({ CName: e.target.value })}
            name='Name'
            placeholder='Name'
            value={this.state.CName}
            variant='standard'
          />
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => this.setState({ CEmail: e.target.value })}
            name='Email'
            placeholder='Email'
            value={this.state.CEmail}
            variant='standard'
          />
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => this.setState({ CPNo: e.target.value })}
            name='Phone Number'
            placeholder='Phone Number'
            value={this.state.CPNo}
            variant='standard'
          />
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => this.setState({ CAddress: e.target.value })}
            name='Address'
            placeholder='Address'
            value={this.state.CAddress}
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitCLRequest}>SUBMIT</Button>

          <Button onClick={handleCloseDialog}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    )
  }

  postCLRequest = () => {
    const handleCloseDialog = () => {
      this.setState({
        displayThankyouNote: false,
      })
    }

    return (
      <Dialog
        open={this.state.displayThankyouNote}
        onClose={handleCloseDialog}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <H1>
            <p style={{ color: '#fe7c19' }}>Complete!</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <H2>Thank you for your interest</H2>
          <P>
            We are reviewing your details and will contact you shortly for the
            final stage of registration.
          </P>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    if (this.state.proceedToHistory) {
      return (
        <Redirect
          to={{
            pathname: '/purchaseHistory',
            state: {
              userId: this.props.location.state.userID,
              userPNo: this.state.CPNo,
            },
          }}
        />
      )
    }

    return (

      <BodyWrapper>
        {this.feedback()}
        {this.CLRequest()}
        {this.postCLRequest()}
        <H1>Payment</H1>
        <div style={{ placeItems: 'center', color: 'red' }}>
          {this.state.downtime500 === true ? (
            <>
              <H2 style={{ textAlign: 'center' }}>
                Session timeout, please cancel payment request and refresh{' '}
              </H2>
            </>
          ) : null}
          {this.state.downtime400 === true ? (
            <H2 style={{ textAlign: 'center' }}>Transaction failed </H2>
          ) : null}
        </div>
        {this.state.POD ? (
          <CenteredDiv vertical>
            {!this.state.orderCreated ? (
              <>
                <Spinner large secondary />

                <H2 style={{ display: 'flex' }}>Creating order</H2>
              </>
            ) : (
              <>
                <Checkmark size='xxLarge' />

                <H2>Order created!</H2>

                <H2>Order ID: {this.state.orderID}</H2>

                <P>
                  <b>Community Leader Name:</b> {this.state.leaderName}
                </P>
                <P>
                  <b>Community Leader Phone Number:</b> {this.state.leaderPNo}
                </P>

                <P>Contact your community leader for delivery</P>
              </>
            )}
          </CenteredDiv>
        ) : (
          <>
            {this.state.requestSent ? (
              <>
                <H2>Payment Request sent!</H2>

                <H2>Check your phone to complete payment.</H2>

                <P>
                  <b>Invoice ID : </b> {this.props.location.state.invoiceID}
                </P>

                {!this.state.paid ? (
                  <CenteredDiv vertical>
                    {!this.state.cancelled ? (
                      <>
                        <Spinner large secondary />

                        <H2 style={{ display: 'flex' }}>
                          Awaiting Payment confirmation
                        </H2>
                        <P>
                          If M-Pesa request is not sent,{' '}
                          <Button onClick={this.sendSTKRequest()}>
                            Click Here
                          </Button>{' '}
                          refresh page to resend request
                        </P>
                      </>
                    ) : (
                      <>
                        <CancelOutlinedIcon
                          style={{ color: 'red', fontSize: '700%' }}
                        />
                        <H2>Payment Cancelled!</H2>
                      </>
                    )}
                  </CenteredDiv>
                ) : (
                  <>
                    <CenteredDiv vertical>
                      <Checkmark size='xxLarge' />

                      <H2>Payment Received!</H2>

                      {!this.state.orderCreated ? (
                        <>
                          <Spinner large secondary />

                          <H2 style={{ display: 'flex' }}>Creating order</H2>
                        </>
                      ) : (
                        <>
                          <Checkmark size='xxLarge' />

                          <H2>Order created!</H2>

                          <H2>Order ID: {this.state.orderID}</H2>

                          <P>
                            <b>Community Leader Name:</b>{' '}
                            {this.state.leaderName}
                          </P>
                          <P>
                            <b>Community Leader Phone Number:</b>{' '}
                            {this.state.leaderPNo}
                          </P>

                          <P>Contact your community leader for delivery</P>
                        </>
                      )}
                    </CenteredDiv>
                  </>
                )}
              </>
            ) : (
              <CenteredDiv>
                <H2>Sending M-pesa payment Request</H2>

                <H2>Please wait.</H2>
              </CenteredDiv>
            )}
          </>
        )}

        <Button
          onClick={() => {
            this.setState({ proceedToHistory: true })
          }}
        >
          Purchase History
        </Button>

        {/* <H2>Cart Items: {this.state.CartItems}</H2>      */}
      </BodyWrapper>
    )
  }
}

export default PaymentPage
