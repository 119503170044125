import React, { useState, useContext } from "react"
import { P, H1, H2, Button, Input, Form, BodyWrapper, Spinner, CenteredDiv } from "../components"
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom"

import { MenuItem } from "@material-ui/core"
import Fab from "@material-ui/core/Fab"
import HomeIcon from "@material-ui/icons/Home"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
import firebase from "../firebase.js"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import "firebase/database"
import { withRouter } from "react-router-dom"
import { firestore } from "firebase-functions"

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // UIDs
      userID: "CS" + Math.floor(100000000 + Math.random() * 900000000),
      invID: "INV" + Math.floor(100000000 + Math.random() * 900000000),
      orderID: "OD" + Math.floor(100000000 + Math.random() * 900000000),
      transID: "TR" + Math.floor(100000000 + Math.random() * 900000000),
      total: 0,
      // Customer Details
      phoneNumber: "",
      fullNo: "",
      customerName: "",
      customerEmail: "",
      city: "",
      address: "",
      neighborhood: "",
      customerType: "",
      // Leader Details
      leaderID: "",
      leaderName: "",
      // Switches
      detailsNotFound: false,
      proceedToPay: false,
      proceedToOTP: false,
      proceedToGroupBuyQueue: false,
      proceedToCreateOrder: false,
      loading: false,
      returnC: false,
      displayDiscount: false,
      discountAdded: false,
      limitExceeded: false,
      pod: false,
      // Error Switches
      pNoError: false,
      cNameError: false,
      cEmailError: false,
      cCityError: false,
      neighborhoodError: false,
      addressError: false,
      typeError: false,
      // Arrays
      dropdown: [],
      CartItems: []
    }
  }

  componentWillMount() {
    const firestore = firebase.firestore()

    const fetchData = async () => {
      const cartID = this.props.location.state.cartID

      const leaderID = this.props.location.state.leaderID

      const shoppingCartTotal = await firebase.database().ref("shoppingCarts/" + cartID + "/grandTotal")

      shoppingCartTotal.on("value", (snapshot) => {
        const total = snapshot.val()

        this.setState(
          {
            total: parseInt(total),
            leaderID: leaderID
          },
          () => {
            checkLedgerLimit()
          }
        )
      })

      // if(cartID.charAt(0) === "C"){

      //   const shoppingCartTotal = await firebase.database().ref('shoppingCarts/' + cartID + '/grandTotal');

      //   shoppingCartTotal.on('value', (snapshot) => {

      //       const total = snapshot.val();

      //       this.setState({
      //           total: parseInt(total),
      //           leaderID: leaderID
      //       }, () => {
      //         checkLedgerLimit()
      //       })

      //   });
      // }else if(cartID.charAt(0) === "G"){

      //   const groupBuyQueue = await firestore.collection("GBQueue").doc(cartID)

      //   groupBuyQueue.get().then((doc) => {
      //     const { ProductID, Quantity } = doc.data()

      //     firestore.collection("Products").doc(ProductID).get().then((doc2) => {
      //       const { Price } = doc2.data();

      //       const total = parseInt(Price) * parseInt(Quantity)

      //       this.setState({
      //           total: parseInt(total),
      //           leaderID: leaderID
      //       }, () => {
      //         checkLedgerLimit()
      //       })

      //     })
      //   })
      // }
    }

    const fetchInvoiceData = async () => {
      const data = await firestore.collection("Products").get()

      const cartID = this.props.location.state.cartID

      console.log("Helloooo", this.props.location.state.cartID)

      console.log("this.state.CartItems")

      const grandTotalRef = firebase.database().ref("shoppingCarts/" + cartID + "/grandTotal")
      grandTotalRef.on("value", (snapshot) => {
        const data = snapshot.val()

        if (snapshot.exists()) {
          this.setState({
            total: parseInt(data)
          })
        } else {
          console.log("No data available")
        }
      })
    }

    fetchData()

    const checkLedgerLimit = async () => {
      const CLData = await firestore.collection("CommunityLeaders").doc(this.props.location.state.leaderID)

      CLData.get().then((doc) => {
        const { LedgerBalance, LedgerLimit } = doc.data()

        const LedgerTotal = LedgerBalance - this.state.total

        console.log("LedgerTOTAL ", LedgerTotal)

        console.log("LedgerLIMIT ", LedgerLimit)

        if (LedgerTotal < LedgerLimit) {
          console.log("TRUE")
          this.setState({
            limitExceeded: true
          })
        } else {
          console.log("FALSE")
          this.setState({
            limitExceeded: false
          })
        }
      })
    }

    const fetchSignedInCustomerData = () => {
      firestore
        .collection("Customers")
        .doc(this.props.location.state.userID)
        .get()
        .then((doc) => {
          const { Name, PhoneNumber, Neighborhood, Address, Type } = doc.data()

          this.setState({
            userID: doc.id,
            fullNo: PhoneNumber,
            phoneNumber: "0" + PhoneNumber.substring(3),
            customerName: Name,
            neighborhood: Neighborhood,
            address: Address,
            customerType: Type
          })
        })
    }

    if (this.props.location.state.cartID.charAt(0) === "C") {
      fetchInvoiceData()
    }

    if (this.props.location.state.userID !== "") {
      fetchSignedInCustomerData()
    }
  }

  handleCodeSubmit = (e) => {
    console.log("PNO!", this.state.phoneNumber)

    this.setState({
      leaderID: this.state.leaderID
    })

    const firestore = firebase.firestore()

    console.log("SUBMIIIITTTTT!!", this.state.phoneNumber)

    const pNo = this.state.phoneNumber

    const fullNo = "254" + pNo.substring(1)

    const fetchData = async () => {
      console.log("FULL NOOOOO!", fullNo)

      const data = await firestore.collection("Customers").where("PhoneNumber", "==", fullNo)

      data.get().then((doc) => {
        console.log("DOC!")

        if (!doc.empty) {
          doc.forEach((record) => {
            const { Name, Email, City, Address, Neighborhood, Type } = record.data()

            console.log("EXISTS")

            if (Type === "End-Customer") {
              this.setState({
                userID: record.id,
                customerName: Name,
                customerEmail: Email,
                city: City,
                address: Address,
                neighborhood: Neighborhood,
                detailsNotFound: false
              })
            } else {
              this.setState({
                userID: record.id,
                customerName: Name,
                customerEmail: Email,
                customerType: Type,
                city: City,
                address: Address,
                neighborhood: Neighborhood,
                detailsNotFound: false
              })
            }

            console.log("Match")
          })
        } else {
          this.setState({
            detailsNotFound: true
          })
          console.log("Mismatch")
          console.log(fullNo)
        }
      })
    }

    fetchData()
  };

  handleSubmit = (e, pod) => {
    console.log("CLICK")

    const date = new Date()
    const today = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " T " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

    this.setState(
      {
        loading: true
      },
      () => {
        if (this.state.customerName === "") {
          console.log("CNAME")
          this.setState({
            cNameError: true,
            loading: false
          })
        } else if (this.state.phoneNumber === "") {
          console.log("CNAME2")
          this.setState({
            pNoError: true,
            loading: false
          })
        } else if (this.state.neighborhood === "") {
          console.log("CNAME3")
          this.setState({
            neighborhoodError: true,
            loading: false
          })
        } else if (this.state.address === "") {
          console.log("CNAME4")
          this.setState({
            addressError: true,
            loading: false
          })
        } else if (this.state.customerType === "") {
          console.log("CNAME5")
          this.setState({
            typeError: true,
            loading: false
          })
        } else {
          console.log("CNAME6")

          const firestore = firebase.firestore()

          const pNo = this.state.phoneNumber

          const fullNo = "254" + pNo.substring(1)

          this.setState(
            {
              proceedToPay: false
            },
            () => {
              firestore
                .collection("Customers")
                .doc(this.state.userID)
                .update({
                  UserID: this.state.userID,
                  Name: this.state.customerName,
                  Address: this.state.address,
                  Neighborhood: this.state.neighborhood,
                  PhoneNumber: fullNo,
                  LeaderID: this.state.leaderID,
                  Type: this.state.customerType,
                  UpdatedAt: today
                })
                .then(() => {
                  this.setState(
                    {
                      fullNo: fullNo
                    },
                    () => {
                      this.checkOrdersQuantity(this.state.userID, pod)

                      // this.createInvoice(pod)
                    }
                  )
                })
            }
          )
        }
      }
    )
  };

  handleRegisterCustomer = (e, pod) => {
    const date = new Date()
    const today = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " T " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

    this.setState(
      {
        loading: true,
        returnC: false
      },
      () => {
        if (this.state.customerName === "") {
          this.setState({
            cNameError: true,
            loading: false
          })
        } else if (this.state.neighborhood === "") {
          this.setState({
            neighborhoodError: true,
            loading: false
          })
        } else if (this.state.address === "") {
          this.setState({
            addressError: true,
            loading: false
          })
        } else if (this.state.customerType === "") {
          this.setState({
            typeError: true,
            loading: false
          })
        } else {
          const firestore = firebase.firestore()

          const pNo = this.state.phoneNumber

          const fullNo = "254" + pNo.substring(1)

          firestore
            .collection("Customers")
            .doc(this.state.userID)
            .set({
              UserID: this.state.userID,
              Name: this.state.customerName,
              Address: this.state.address,
              City: this.state.city,
              Neighborhood: this.state.neighborhood,
              PhoneNumber: fullNo,
              LeaderID: this.state.leaderID,
              Referrals: 0,
              LedgerBalance: 0,
              OTP: 0,
              ReferredIDs: [],
              ReferralLink: "https://shop.tushop.io/referral/CL735461/" + this.state.userID,
              Type: this.state.customerType,
              CreatedAt: today,
              UpdatedAt: today
            })
            .then(() => {
              this.setState(
                {
                  fullNo: fullNo
                },
                () => {
                  this.sendCustomerSMS(pod)
                }
              )
            })
        }
      }
    )
  };

  handlePhoneChange = (e) => {
    if (e.target.value === "") {
      this.setState({
        userID: "CS" + Math.floor(100000000 + Math.random() * 900000000),
        customerName: "",
        customerEmail: "",
        customerType: "",
        city: "",
        address: "",
        neighborhood: "",
        detailsNotFound: false
      })
    } else if (e.target.value.length === 10) {
      this.setState(
        {
          phoneNumber: e.target.value
        },
        () => {
          this.handleCodeSubmit()
        }
      )
    } else {
      this.setState({
        phoneNumber: e.target.value
      })
    }
  };

  sendCustomerSMS = (pod) => {
    const url = "https://api.tushop.io/api/v1/sms/Send"

    const pNo = this.state.phoneNumber

    const fullNo = "+254" + pNo.substring(1)

    const message = "Good news! Get 50sh OFF your next TWO orders above 400sh on Tushop. Everything is at wholesale prices. Order today and get free home delivery -> https://shop.tushop.io/order/" + this.state.leaderID

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        message: message,
        to: fullNo
      })
    })
      .then((response) => {
        if (response.status === 201) {
          return response.json()
        } else {
          throw new Error("Something went wrong on api server!")
        }
      })
      .then((response) => {
        console.debug(response)
      })
      .catch((error) => {
        console.error(error)
      })
  };

  checkOrdersQuantity = (cusID, pod) => {
    const yesterdate = new Date()

    yesterdate.setDate(yesterdate.getDate() - 1)

    const yesterday = yesterdate.getFullYear() + "-" + (yesterdate.getMonth() + 1 > 9 ? yesterdate.getMonth() + 1 : "0" + (yesterdate.getMonth() + 1)) + "-" + (yesterdate.getDate() > 9 ? yesterdate.getDate() : "0" + yesterdate.getDate()) + "T" + (yesterdate.getHours() > 9 ? yesterdate.getHours() : "0" + yesterdate.getHours()) + ":" + (yesterdate.getMinutes() > 9 ? yesterdate.getMinutes() : "0" + yesterdate.getMinutes())

    const firestore = firebase.firestore()

    const rtdb = firebase.database()

    const totalInt = this.state.total

    console.log("HERE")

    firestore
      .collection("Orders")
      .where("CustomerID", "==", cusID)
      .get()
      .then((doc) => {
        console.log(doc.size)

        if (!doc.empty) {
          let count = 0

          const ref = rtdb.ref("shoppingCarts/" + this.props.location.state.cartID)

          ref
            .once("value", (snapshot) => {
              snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key
                // const childData = childSnapshot.val();

                if (childKey === "grandTotal" || childKey === "T900113") {
                  console.log("IGNORE")
                } else {
                  ++count
                }
              })
            })
            .then(() => {
              if (doc.size === 3) {
                firestore
                  .collection("Orders")
                  .where("CustomerID", "==", cusID)
                  .where("DateOfOrder", ">=", yesterday)
                  .get()
                  .then((doc2) => {
                    if (doc2.empty) {
                      console.log("HAPA")

                      this.setState(
                        {
                          total: totalInt - 100,
                          returnC: true
                        },
                        () => {
                          this.createInvoice(pod, true)
                        }
                      )
                    } else {
                      this.setState(
                        {
                          returnC: true
                        },
                        () => {
                          this.createInvoice(pod, false)
                        }
                      )
                    }
                  })
              } else {
                this.setState(
                  {
                    returnC: true
                  },
                  () => {
                    this.createInvoice(pod, false)
                  }
                )
              }
            })
        } else {

          this.createInvoice(pod, false)

        }
      })
  };

  createInvoice = (pod, discount) => {
    const firestore = firebase.firestore()

    const date = new Date()
    const today = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " T " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

    firestore
      .collection("Invoice")
      .doc(this.state.invID)
      .set({
        InvoiceID: this.state.invID,
        CartID: this.props.location.state.cartID,
        UserID: this.state.userID,
        LeaderID: this.state.leaderID,
        transactionID: this.state.transID,
        paid: false,
        referralUpdated: false,
        POD: pod,
        GBID: this.props.location.state.gbID,
        cancelled: false,
        fullNo: this.state.fullNo,
        total: this.state.total,
        CheckoutRequestID: "",
        orderID: this.state.orderID,
        returnC: this.state.returnC,
        STKRequestSent: false,
        created_at: today,
        updated_at: today
      })
      .then(() => {
        if (this.props.location.state.groupBuy) {
          this.setState({
            POD: pod,
            proceedToGroupBuyQueue: true
          })
        } else {
          if (pod && discount) {
            this.setState({
              displayDiscount: true,
              pod: true
            })
          } else if (pod && !discount && this.props.location.state.userID === "") {
            this.setState({
              proceedToOTP: true,
              pod: true
            })
          } else if (pod && !discount && this.props.location.state.userID !== "") {
            this.setState({
              proceedToPay: true,
              pod: true
            })
          } else if (!pod && discount) {
            this.setState({
              displayDiscount: true,
              pod: false
            })
          } else {
            this.setState({
              proceedToPay: true,
              pod: false
            })
          }
        }
      })
  };

  discountPopUp = () => {
    const handleCloseDialog = () => {
      this.setState({
        displayDiscount: false
      })
    }

    const handleProceed = () => {
      this.setState({
        proceedToPay: true
      })
    }

    const handleAddDiscountToCart = async () => {
      const firestore = firebase.firestore()

      const myProduct = firestore.collection("Products").doc("T900113")
      const doc = await myProduct.get()
      if (doc.exists) {
        const product = doc.data()

        const total = product["Price"]
        // console.log('Document data:', product["Price"]);
        const itemRef = firebase.database().ref("shoppingCarts/" + this.props.location.state.cartID + "/T900113")

        const grandTotalRef = firebase.database().ref("shoppingCarts/" + this.props.location.state.cartID + "/grandTotal")

        itemRef
          .set({
            productDesc: product["Description"],
            productID: product["ProductID"],
            productPrice: product["Price"],
            quantity: "1",
            total: product["Price"]
          })
          .then(() => {
            grandTotalRef.once("value").then((snapshot) => {
              const oldTotal = snapshot.val()

              const newTotal = parseInt(oldTotal) - 100

              grandTotalRef.set(newTotal.toString())
            })
          })
          .then(() => {
            handleProceed()
          })
      }
    }

    return (
      <Dialog open={this.state.displayDiscount} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {" "}
          <H1>
            <p style={{ color: "#fe7c19" }}>HEY!</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <H2>Thank you for shopping with Tushop</H2>
          <P>
            You have been awarded a <b style={{ color: "#BF40BF" }}> 100sh </b> Discount on your Grand Total.
          </P>
          <P>
            Was: <del>{this.state.total + 100}</del>
          </P>
          <P style={{ color: "green" }}>
            <b>Your Total: {this.state.total}</b>
          </P>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleAddDiscountToCart(e)
            }}
          >
            CLAIM
          </Button>
          <Button onClick={handleCloseDialog}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    )
  };

  detailsFields = () => {
    return (
      <div>
        <TextField id="standard-basic" style={{ width: "100%", marginTop: "20px" }} error={this.state.cNameError} helperText={this.state.cNameError ? "Input Valid Name!" : " "} onChange={(e) => this.setState({ customerName: e.target.value, cNameError: false })} name="customerName" placeholder="Customer Name" value={this.state.customerName} variant="standard" />

        <TextField
          id="standard-basic"
          style={{ width: "100%", marginTop: "20px" }}
          error={this.state.neighborhoodError}
          helperText={this.state.neighborhoodError ? "Input Valid Neighborhood!" : " "}
          onChange={(e) =>
            this.setState({
              neighborhood: e.target.value,
              neighborhoodError: false
            })
          }
          name="neighborhood"
          placeholder="Neighborhood (e.g Donholm, Fedha)"
          value={this.state.neighborhood}
          variant="standard"
        />

        <TextField id="standard-basic" style={{ width: "100%", marginTop: "20px" }} error={this.state.addressError} helperText={this.state.addressError ? "Input Valid Address!" : " "} onChange={(e) => this.setState({ address: e.target.value, addressError: false })} name="address" placeholder="Delivery Address" value={this.state.address} variant="standard" />
        <FormControl fullWidth variant="standard">
          <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
          <Select labelId="demo-simple-select-label" name="customerType" error={this.state.typeError} helperText={this.state.addressError ? "Input Valid Customer Type!" : " "} onChange={(e) => this.setState({ customerType: e.target.value, typeError: false })} value={this.state.customerType} variant="standard">
            <MenuItem>--------Select Your Customer Type--------</MenuItem>
            <MenuItem value="Individual">Individual/household</MenuItem>
            <MenuItem value="Duka">Duka</MenuItem>
            <MenuItem value="Wholesaler">Wholesaler</MenuItem>
            <MenuItem value="Mama Mboga">Mama Mboga</MenuItem>
            <MenuItem value="Smokie/Eggs vendor">Smokie/Eggs vendor</MenuItem>
            <MenuItem value="Hotels and Schools">Hotels and Schools</MenuItem>
            {/* <MenuItem>Test Item1</MenuItem>
              <MenuItem>Test Item2</MenuItem> */}
          </Select>
        </FormControl>
      </div>
    )
  };

  paymentDetails = () => {
    return (
      <BodyWrapper>
        {this.state.loading ? (
          <CenteredDiv vertical>
            <Spinner
              large
              secondary
              style={{
                margin: 0,
                top: "auto",
                right: "auto",
                bottom: "50%",
                left: "auto",
                position: "fixed",
                zIndex: 1
              }}
            />
          </CenteredDiv>
        ) : (
          <></>
        )}
        {this.discountPopUp()}
        <H2>
          <b>Amount: </b> KES {this.state.total}
        </H2>

        <P>Enter phone number to retrieve details</P>

        <TextField id="standard-basic" style={{ width: "100%", marginTop: "20px" }} onChange={(e) => this.handlePhoneChange(e)} name="phoneNumber" placeholder="Phone Number (07xx / 01xx)" inputProps={{ maxLength: 10 }} error={this.state.pNoError} helperText={this.state.pNoError ? "Input Valid Phone Number!" : " "} variant="standard" />

        <hr />
        <br />
        {this.state.phoneNumber.length === 10 ? (
          <>
            {!this.state.detailsNotFound ? (
              <div>
                {this.detailsFields()}
                <Button style={{ marginTop: "20px" }} onClick={(e) => this.handleSubmit(e, false)}>
                  MAKE PAYMENT
                </Button>

                {this.props.location.state.POD && !this.state.limitExceeded ? (
                  <Button style={{ marginTop: "20px" }} onClick={(e) => this.handleSubmit(e, true)}>
                    PAY ON DELIVERY
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div>
                <P>Details not found. Please input data to register as new user</P>
                <P>
                  <i>(NB: Only filled once)</i>
                </P>

                {this.detailsFields()}

                {this.props.location.state.POD && !this.state.limitExceeded ? (
                  <Button style={{ marginTop: "20px" }} onClick={(e) => this.handleRegisterCustomer(e, true)}>
                    REGISTER & PAY ON DELIVERY
                  </Button>
                ) : (
                  <></>
                )}
                <Button style={{ marginTop: "20px" }} onClick={(e) => this.handleRegisterCustomer(e, false)}>
                  REGISTER & PAY NOW
                </Button>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </BodyWrapper>
    )
  };

  deliveryDetails = () => {
    return (
      <BodyWrapper>
        {this.discountPopUp()}
        <H1>
          <p style={{ lineHeight: "1" }}>Delivery Address</p>
        </H1>
        <H2>Name: {this.state.customerName}</H2>
        <H2>Phone Number: {this.state.fullNo}</H2>
        <br />
        <P>Please provide your delivery address below</P>
        <div>
          <TextField
            id="standard-basic"
            style={{ width: "100%", marginTop: "20px" }}
            error={this.state.neighborhoodError}
            helperText={this.state.neighborhoodError ? "Input Valid Neighborhood!" : " "}
            onChange={(e) =>
              this.setState({
                neighborhood: e.target.value,
                neighborhoodError: false
              })
            }
            name="neighborhood"
            placeholder="Neighborhood (e.g Donholm, Fedha)"
            value={this.state.neighborhood}
            variant="standard"
          />

          <TextField id="standard-basic" style={{ width: "100%", marginTop: "20px" }} error={this.state.addressError} helperText={this.state.addressError ? "Input Valid Address!" : " "} onChange={(e) => this.setState({ address: e.target.value, addressError: false })} name="address" placeholder="Delivery Address" value={this.state.address} variant="standard" />
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="customerType"
              error={this.state.typeError}
              helperText={this.state.addressError ? "Input Valid Customer Type!" : " "}
              onChange={(e) =>
                this.setState({
                  customerType: e.target.value,
                  typeError: false
                })
              }
              value={this.state.customerType}
              variant="standard"
            >
              <MenuItem>--------Select Your Customer Type--------</MenuItem>
              <MenuItem value="Individual">Individual/household</MenuItem>
              <MenuItem value="Duka">Duka</MenuItem>
              <MenuItem value="Wholesaler">Wholesaler</MenuItem>
              <MenuItem value="Mama Mboga">Mama Mboga</MenuItem>
              <MenuItem value="Smokie/Eggs vendor">Smokie/Eggs vendor</MenuItem>
              {/* <MenuItem>Test Item1</MenuItem>
                <MenuItem>Test Item2</MenuItem> */}
            </Select>
          </FormControl>
          <br />
          <br />

          <Button style={{ marginTop: "20px" }} onClick={(e) => this.handleSubmit(e, false)}>
            {this.props.location.state.groupBuy ? "PAY AND ADD TO QUEUE" : "MAKE PAYMENT"}
          </Button>
          {this.props.location.state.groupBuy ? (
            <P>
              <i>NB: (Payment is automatically refunded if group buy target is not hit) </i>
            </P>
          ) : (
            <></>
          )}
          {this.props.location.state.POD && !this.state.limitExceeded ? (
            <Button style={{ marginTop: "20px" }} onClick={(e) => this.handleSubmit(e, true)}>
              {this.props.location.state.groupBuy ? "ADD TO QUEUE" : "PAY ON DELIVERY"}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </BodyWrapper>
    )
  };

  render() {
    const cartID = this.props.location.state.cartID

    if (!this.state.proceedToPay) {
      if (this.props.location.state.userID === "") {
        return this.paymentDetails()
      } else {
        if (this.state.proceedToGroupBuyQueue) {
          return (
            <Redirect
              to={{
                pathname: "/groupBuyQueue",
                state: {
                  invoiceID: this.state.invID
                }
              }}
            />
          )
        }

        return this.deliveryDetails()
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: "/paymentPage",
            state: {
              invoiceID: this.state.invID,
              orderID: this.state.orderID,
              returnC: this.state.returnC,
              userID: this.state.userID,
              referralID: this.props.location.state.referralID
            }
          }}
        />
      )
    }
  }
}

export default PaymentDetails
