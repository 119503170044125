import React, { useReducer, createContext } from "react"
const styleMode = window.localStorage.getItem("styleMode")

const initialState = {
  userId: null,
  cartId: null,
  cartItems: null,
  cartItemsQty: null,
  userPNo: null,
  userName: null,
  leaderId: null,
  goBackToShopping: null,
  userData: {
    email: null,
    firstName: null,
    lastName: null,
    pushTokenWeb: null
  },
  styleMode: styleMode ? styleMode : "main",
  verifying: false
}
export const UserContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case "userId":
      return { ...state, userId: action.payload }
    case "cartId":
      return { ...state, cartId: action.payload }
    case "leaderId":
      return { ...state, leaderId: action.payload }
    case "goBackToShopping":
      return { ...state, goBackToShopping: action.payload }
    case "cartItems":
      return { ...state, cartItems: action.payload }
    case "cartItemsQty":
      return { ...state, cartItemsQty: action.payload }
    case "userPNo":
      return { ...state, userPNo: action.payload }
    case "userName":
      return { ...state, userName: action.payload }
    case "verifying":
      return { ...state, verifying: action.payload }
    case "updateProfile":
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload
        }
      }
    case "styleMode":
      return { ...state, styleMode: action.payload }
    case "signOut":
      return { ...initialState }
    default:
      return state
  }
}

export const UserProvider = props => {
  const [userState, userDispatch] = useReducer(reducer, initialState)
  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {props.children}
    </UserContext.Provider>
  )
}
