import React, { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Dashboard from './containers/Dashboard'
import BundleBuy from './containers/BundleBuy'
import GroupBuy from './containers/GroupBuy'
import BulkBuy from './containers/BulkBuy'
import AddItems from './containers/AddItems'
import Cart from './containers/Cart'
import AutoCart from './containers/AutoCart'
import PaymentDetails from './containers/PaymentDetails'
import GroupBuyQueue from './containers/GroupBuyQueue'
import VerifyOTP from './containers/verifyOTP'
import Confirmed from './containers/Confirmed'
import PaymentPage from './containers/PaymentPage'
import PurchaseHistory from './containers/purchaseHistory'
import Header from './containers/Header'
import PrivacyPolicy from './containers/PrivacyPolicy'
import { UserContext } from './contexts/userContext'
import {
  CenteredDiv,
  H1,
  Toast,
  Spinner,
  MobileMenuBar,
  BodyWrapper,
  P,
} from './components'
import styled, { ThemeProvider } from 'styled-components'
import GlobalStyle from './themes/GlobalStyle'
import firebase from './firebase.js'
import 'firebase/firestore'
import { colors } from './themes'
import { sendPushNotification } from './helpers/cloudFunctions'
import Lander from './containers/Lander'
import Home from './containers/Home'

const AppWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
`

const MainRouter = () => {
  const [initializationComplete, setInitComplete] = useState(true)
  // const [pageDown, setPageDown] = useState(false)
  // const [newYears, setNewYears] = useState(false)
  const { userState, userDispatch } = useContext(UserContext)
  const userId = userState.userId
  const db = firebase.firestore()

  // useEffect(() => {

  //   const date = new Date()

  //   const today =
  //     date.getFullYear() +
  //     "-" +
  //     (date.getMonth() + 1 > 9
  //       ? date.getMonth() + 1
  //       : "0" + (date.getMonth() + 1)) +
  //     "-" +
  //     (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
  //     "T" +
  //     (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
  //     ":" +
  //     (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

  //   if (today >= "2022-12-23T06:00" && today <= "2022-12-27T07:00") {
  //     setPageDown(true)
  //     setNewYears(false)
  //   } else if (today >= "2022-12-30T06:00" && today <= "2023-01-02T07:00") {
  //     setPageDown(true)
  //     setNewYears(true)
  //   }




  // }, [])

  const pageDownChristmas = () => {
    return (
      <>
        <Header />
        <BodyWrapper>
          <H1>Merry Christmas!</H1>
          <P>
            The Tushop team is temporarily haulting operations due to the Christmas break.
          </P>
          <P>We will resume normal order operations on:</P>
          <P>
            <b>Tuesday 27th December 2022 @ 07:01 AM</b>
          </P>
          <P>Save the date!</P>
          <hr />
          <P>Hope to see you shopping with us again!</P>
          <img style={{ width: "100%" }} src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/tushop-white2.jpeg?alt=media&token=dabe04c4-abf2-4b77-be2b-195c7aa10bbb" />
        </BodyWrapper>
      </>

    )
  }

  const pageDownNewYears = () => {
    return (
      <>
        <Header />
        <BodyWrapper>
          <H1><h1 style={{ lineHeight: "30px" }} >Happy New Year 2023!</h1></H1>
          <P>
            The Tushop team is temporarily haulting operations due to the New Years break.
          </P>
          <P>
            We have exciting plans in place towards serving you better in 2023
          </P>
          <P>We will resume normal order operations on:</P>
          <P>
            <b>Monday 2nd January 2023 @ 07:01 AM</b>
          </P>
          <P>Save the date!</P>
          <hr />
          <P>Hope to see you shopping with us again!</P>

          <img style={{ width: "100%" }} src="https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/tushop-white2.jpeg?alt=media&token=dabe04c4-abf2-4b77-be2b-195c7aa10bbb" />
        </BodyWrapper>
      </>
    )
  }

  const paymentPage = props => {
    // userDispatch({ type: "signOut" })

    return <PaymentPage {...props} />
  }

  const nestedSwitch = () => {

    // if (pageDown) {
    //   if (newYears) {
    //     return pageDownNewYears()
    //   } else {
    //     return pageDownChristmas()
    //   }
    // } else {

    return (
      <>
        <Header />
        <Route
          render={({ location }) => (
            <TransitionGroup appear>
              <CSSTransition
                key={location.key}
                timeout={1000}
                classNames="fade"
              >
                {/* Test for deployment */}
                <Switch location={location}>
                  {/* <Route path={'/'} render={props => <Home />} /> */}
                  <Route
                    path={'/order/:leaderID'}
                    render={props => (
                      <Dashboard
                        signedUp="false"
                        GB="false"
                        goBack="false"
                        referral="false"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/shop/:leaderID'}
                    render={props => (
                      <Dashboard
                        signedUp="true"
                        GB="false"
                        goBack="false"
                        referral="false"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/signIn/:leaderID'}
                    render={props => <Lander {...props} />}
                  />
                  <Route
                    path={'/groupBuy/:leaderID'}
                    render={props => <GroupBuy {...props} />}
                  />
                  <Route
                    path={'/groupBuyQueue'}
                    render={props => <GroupBuyQueue {...props} />}
                  />
                  <Route
                    path={'/bulkBuy/:leaderID'}
                    render={props => <BulkBuy {...props} />}
                  />
                  <Route
                    path={'/bundle/:leaderID'}
                    render={props => <BundleBuy {...props} />}
                  />
                  <Route
                    path={'/addItems/:leaderID'}
                    render={props => (
                      <Dashboard
                        signedUp="false"
                        goBack="true"
                        GB="false "
                        referral="false"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/referral/:leaderID/:customerID'}
                    render={props => (
                      <Dashboard
                        signedUp="false"
                        goBack="false"
                        GB="false"
                        referral="true"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/purchaseHistory'}
                    render={props => <PurchaseHistory {...props} />}
                  />
                  <Route
                    path={'/shoppingCart'}
                    render={props => <Cart {...props} />}
                  />
                  <Route
                    path={'/MyshoppingCart/:autoCartID'}
                    render={props => <AutoCart {...props} />}
                  />
                  <Route
                    path={'/paymentDetails'}
                    render={props => <PaymentDetails {...props} />}
                  />
                  <Route
                    path={'/verifyOTP'}
                    render={props => <VerifyOTP {...props} />}
                  />
                  <Route path={'/paymentPage'} render={paymentPage} />
                  <Route
                    path={'/privacy-policy'}
                    render={() => <PrivacyPolicy />}
                  />
                  <Route path="*" render={props => <Home />} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </>
    )
    // }

  }

  const router = () => {
    return (
      <Router>
        <>
          <Toast />
          <Switch>
            <Route path={'/confirmed'} render={() => <Confirmed />} />
            <Route path="*" render={nestedSwitch} />
          </Switch>
        </>
      </Router>
    )
  }

  const renderApp = () => {
    const app = !initializationComplete ? (
      <CenteredDiv vertical horizontal>
        <Spinner large secondary />
      </CenteredDiv>
    ) : (
      router()
    )
    return app
  }

  const styleMode = window.localStorage.getItem('styleMode')
  return (
    <ThemeProvider
      theme={styleMode && styleMode === 'main' ? colors.dark : colors.main}
    >
      <>
        <GlobalStyle />
        <AppWrapper>{renderApp()}</AppWrapper>
      </>
    </ThemeProvider>
  )
}

export default MainRouter
