import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import "firebase/functions";
const config = {
  apiKey: "AIzaSyCh2TKYhCJTtd1ySvvhJPu7zkURp4mrthE",
  authDomain: "tushop-shopping-app.firebaseapp.com",
  databaseURL: "https://tushop-shopping-app-default-rtdb.firebaseio.com",
  projectId: "tushop-shopping-app",
  storageBucket: "tushop-shopping-app.appspot.com",
  messagingSenderId: "241381709741",
  appId: "1:241381709741:web:520cdede13aca745793324",
  measurementId: "G-SGPFRK403Q"
};
firebase.initializeApp(config);

  let messaging = null;

  if (firebase.messaging.isSupported()) {
  
    messaging = firebase.messaging();

  }
  
  export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then((firebaseToken) => {
          resolve(firebaseToken);
        })
        .catch((err) => {
          reject(err);
        });
    });

  export const onMessageListener = () =>
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
// if ("Notification" in window) {
//   const messaging = firebase.messaging();
//   messaging.usePublicVapidKey("BI98i98uFRheahYFlZbJ_0sVMBdyQPbcblwMvV0zzGE4ElAMMAJB6Y-scIMHKgcUT36atUrbwvp2wAyyKlUZhJ8");

//   messaging.onMessage(payload => {
//     console.log("Message received. ", payload);
//     // push message to UI
//   });

//   messaging.onTokenRefresh(() => {
//     const db = firebase.firestore();
//     messaging
//       .getToken()
//       .then(refreshedToken => {
//         db.collection("users")
//           .doc(firebase.auth().currentUser.uid)
//           .update({ pushTokenWeb: refreshedToken })
//           .then(() => {
//             console.log("Token updated.");
//           })
//           .catch(err => console.log(err));
//       })
//       .catch(err => {
//         console.log("Unable to retrieve refreshed token ", err);
//       });
//   });
// }

export default firebase;
