import React from "react"
import {
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom"
import { P, H1, H2, Button, Spinner, CenteredDiv, BodyWrapper } from "../components"
import firebase from "../firebase.js"
import "firebase/database"
import Table from '@material-ui/core/Table'
import { UserContext } from '../contexts/userContext'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import { Card, Divider, CardContent, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core'


class Cart extends React.Component {

  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      CartId: "",
      CustomerID: "",
      CustomerName: "",
      CustomerPNo: "",
      LeaderID: "",
      CartItems: [],
      ddfeeDropdown: [
        { address: "Westlands", fee: 300 },
        { address: "Mlolongo, Athi River and Mlolongo", fee: 250 },
        { address: "Pipeline, Kwa Ndege and Nyayo Estate", fee: 100 },
        { address: "Umoja, Donholm and Buruburu", fee: 100 },
        { address: "Kasarani and Zimmerman", fee: 210 },
        { address: "Kariobangi", fee: 150 },
        { address: "Githurai and Ruiru", fee: 260 },
        { address: "Langata", fee: 210 },
        { address: "Utawala, Kayole and Ruai", fee: 150 },
        { address: "Upperhill, South B and South C", fee: 150 },
        { address: "Rongai and Ngong", fee: 320 }
      ],
      GrandTotal: 0,
      emptyCart: false,
      buyNow: false,
      continueShopping: false,
      loading: true,
      DirectDelivery: true,
      ForcedDirectDelivery: false,
      ddfee: '',
      invID: "INV" + Math.floor(100000000 + Math.random() * 900000000),
      orderID: "OD" + Math.floor(100000000 + Math.random() * 900000000),
      transID: "TR" + Math.floor(100000000 + Math.random() * 900000000),
    }

  }

  componentWillMount = () => {

    console.log(this.props.location.state.cartID)

    const firestore = firebase.firestore()

    let list = [...this.state.CartItems]

    const fetchData = () => {

      const cartID = this.props.location.state.cartID

      this.context.userDispatch({ type: "cartItems", payload: cartID })
      this.context.userDispatch({ type: "cartItemsQty", payload: this.props.location.state.cartItemsQty })
      this.context.userDispatch({ type: "cartId", payload: this.props.location.state.cartID })
      this.context.userDispatch({ type: "leaderId", payload: this.props.location.state.leaderID })
      this.context.userDispatch({ type: "userId", payload: this.props.location.state.userID })

      const totals = []

      const shoppingCartRef = firebase.database().ref('shoppingCarts/' + cartID)
      shoppingCartRef.once('value').then((snapshot) => {

        if (snapshot.exists()) {

          snapshot.forEach((childSnapshot) => {

            if (childSnapshot.key === "grandTotal") {
              console.log("IGNORE")
            } else {

              const data = childSnapshot.val()

              console.log("data", data)
              getImgUrl(data)

              totals.push(data.total)

              console.log("DATA GOTTEN ITEM NO: ", data.productID)
            }

          })

          setGrandTotal(totals)


          this.setState({
            loading: false
          }, () => {
            getDDFee()
          })


          // Code for adding grandtotal to cart record


        } else {
          console.log("No data available")
          // this.setState({
          //   emptyCart: true
          // })
        }

      })

    }

    const getImgUrl = (data) => {
      firestore.collection("Products").doc(data.productID).get().then((doc) => {
        const { ImgUrls } = doc.data()

        const obj = {
          img: ImgUrls[0],
          productDesc: data.productDesc,
          productID: data.productID,
          productPrice: data.productPrice,
          quantity: data.quantity,
          total: data.total
        }

        list.push(obj)
        updateList(obj)

      })
    }

    const getDDFee = () => {

      console.log("LIST", this.state.CartItems)


      firestore.collection("CommunityLeaders")
        .doc(this.props.location.state.leaderID)
        .get()
        .then((doc) => {
          const { DDFee } = doc.data()

          if (DDFee === undefined || DDFee === 0 || DDFee === '') {

            console.log("No direct delivery fee set")

          } else {

            this.setState({
              ddfee: DDFee,
              DirectDelivery: true
            })
          }
        }).then(() => {
          // setTimeout(() => {

          this.state.CartItems.forEach((data) => {

            console.log("CART ITEMS", data)
            if (data.productID === "T806414009") {
              this.setState({
                DirectDelivery: false
              }, () => {
                firestore
                  .collection("CommunityLeaders")
                  .doc(this.props.location.state.leaderID)
                  .get()
                  .then((doc) => {

                    const { DDelivery } = doc.data()

                    if (DDelivery) {
                      this.setState({
                        ForcedDirectDelivery: true
                      })
                    }
                  })
              })
            } else {

              firestore
                .collection("CommunityLeaders")
                .doc(this.props.location.state.leaderID)
                .get()
                .then((doc) => {

                  const { DDelivery } = doc.data()

                  if (DDelivery) {
                    this.setState({
                      ForcedDirectDelivery: true
                    }, () => {
                      this.handleAddDirectDelivery()
                    })
                  }
                })

            }
          })
          // }, 2000)
        })
    }

    const updateList = (data) => {

      this.setState({
        CartItems: list
      })



    }

    const setGrandTotal = (array) => {

      const add = (arr) => {
        return arr.reduce((a, b) => a + b, 0)
      }

      const GrandTotal = add(array)

      this.setState({
        GrandTotal: GrandTotal
      }, () => {
        console.log("GTT: ", this.state.GrandTotal)
      })
    }

    fetchData()
  }



  handleBuyItems = () => {

    this.setState({
      loading: true
    }, () => {

      const cartID = this.props.location.state.cartID

      firebase.database().ref('shoppingCarts/' + cartID).update({
        grandTotal: this.state.GrandTotal,
      }).then(() => {

        this.createInvoice()

      })

    })

  }

  handleDelete = (e, productID) => {

    const cartID = this.props.location.state.cartID

    const cartRef = firebase.database().ref('shoppingCarts/' + cartID).child(productID)

    cartRef.remove()

    window.location.reload()

  }

  handleAddDirectDelivery = (e) => {

    const itemRef = firebase
      .database()
      .ref("shoppingCarts/" + this.props.location.state.cartID + "/T806414009")

    itemRef
      .once("value")
      .then(snapshot => {
        if (snapshot.exists()) {

          console.log("Direct Delivery Added")
        } else {

          itemRef.set({
            productDesc: "Direct Delivery",
            productID: "T806414009",
            productPrice: "99",
            quantity: "1",
            total: 99,
          }, () => {
            window.location.reload()
          })

        }
      })

  }

  handleContinueShopping = () => {
    this.setState({
      continueShopping: true
    })
  }

  createInvoice = () => {

    const firestore = firebase.firestore()

    const date = new Date()
    const today = date.getFullYear() +
      '-' +
      ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? (date.getDate()) : '0' + (date.getDate())) +
      ' T ' +
      (date.getHours() > 9 ? (date.getHours()) : '0' + (date.getHours())) +
      ":" +
      (date.getMinutes() > 9 ? (date.getMinutes()) : '0' + (date.getMinutes()))

    firestore.collection('Invoice').doc(this.state.invID).set({
      InvoiceID: this.state.invID,
      CartID: this.props.location.state.cartID,
      UserID: this.state.CustomerID,
      LeaderID: this.props.location.state.leaderID,
      transactionID: this.state.transID,
      paid: false,
      referralUpdated: false,
      POD: false,
      cancelled: false,
      fullNo: this.state.CustomerPNo,
      total: this.state.GrandTotal,
      CheckoutRequestID: "",
      orderID: this.state.orderID,
      STKRequestSent: false,
      created_at: today,
      updated_at: today,
    }).then(() => {

      console.log("INVOICE ID: ", this.state.invID)

      this.context.userDispatch({ type: "cartItems", payload: this.state.CartItems })
      this.context.userDispatch({ type: "cartItemsQty", payload: this.props.location.state.cartItemsQty })
      this.context.userDispatch({ type: "cartId", payload: this.props.location.state.cartID })
      this.context.userDispatch({ type: "leaderId", payload: this.props.location.state.leaderID })
      this.context.userDispatch({ type: "userId", payload: this.props.location.state.userID })

      this.setState({
        loading: false,
        buyNow: true
      })

    })

  }

  render() {

    const cart = () => {
      return (

        <BodyWrapper>
          <hr width="100%" style={{ color: 'white' }} />
          <H1>My Cart</H1>

          {this.state.loading ?
            <CenteredDiv vertical>
              <Spinner large secondary style={{ margin: 0, top: 'auto', right: 'auto', bottom: "50%", left: 'auto', position: 'fixed', zIndex: 1 }} />
            </CenteredDiv>
            :
            <>
              <Card style={{ backgroundColor: '#E5E4E2' }}>
                <CardContent>
                  {this.state.CartItems.map(data =>
                    <>
                      <Card>
                        <CardContent>
                          <div class="row">
                            <div class="col-xs-3">
                              <img src={data.img} style={{ width: "100%" }} />
                            </div>
                            <div class="col-xs-7">
                              <P>{data.quantity} X {data.productDesc} @ {data.productPrice}sh</P>
                              <P style={{ textAlign: "right" }}><b>TOTAL: </b> {data.total}sh</P>

                            </div>
                            <div class="col-xs-2">
                              {this.props.location.state.cartID.charAt(3) === "D" || data.productID === "T806414009" && this.state.ForcedDirectDelivery ?
                                <></>
                                :
                                <Button variant="contained" onClick={e => this.handleDelete(e, data.productID)}>
                                  <DeleteForeverIcon />
                                </Button>
                              }
                            </div>
                          </div>

                        </CardContent>
                      </Card>
                      <hr width="100%" style={{ color: 'white' }} />
                    </>
                  )}

                  {this.state.DirectDelivery ?
                    <Card>
                      <CardContent>
                        <div class="row">
                          <div class="col-xs-10">
                            <H2>Add Doorstep Delivery?</H2>
                            <P>At an extra fee of <b>99sh</b>, we can have your order delivered directly to your doorstep. </P>
                          </div>
                          <div class="col-xs-2">
                            <Button onClick={e => this.handleAddDirectDelivery(e)}>+</Button>
                          </div>
                        </div>
                      </CardContent>
                    </Card>

                    :
                    <></>
                  }
                  <hr width="100%" style={{ color: 'white' }} />

                  <Card style={{ backgroundColor: '#E5E4E2' }}>
                    <CardContent>
                      <div class="row">
                        <div class="col-xs-10">
                          <P style={{ textAlign: "right" }}><b>GRAND TOTAL: </b> {this.state.GrandTotal}sh</P>
                        </div>
                        <div class="col-xs-2">
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </CardContent>
              </Card>
              <hr width="100%" style={{ color: 'white' }} />
              <Card style={{ backgroundColor: '#E5E4E2' }}>
                <CardContent>
                  {this.props.location.state.cartID.charAt(3) === "D" ?
                    <></>
                    :
                    <>
                      <Button style={{ width: "100%" }} onClick={this.handleContinueShopping}>CONTINUE SHOPPING</Button>
                      <hr width="100%" style={{ color: 'white' }} />
                    </>
                  }
                  <Button style={{ width: "100%" }} onClick={this.handleBuyItems}>PROCEED TO PAYMENT </Button>

                </CardContent>
              </Card>
            </>
          }
        </BodyWrapper>

      )
    }

    if (this.state.continueShopping) {

      return <Redirect
        to={{
          pathname: "/addItems/" + this.props.location.state.leaderID,
          state: {
            cartItems: this.state.CartItems,
            cartItemsQty: this.props.location.state.cartItemsQty,
            cartID: this.props.location.state.cartID,
            signedUp: this.props.location.state.signedUp,
            userId: this.props.location.state.userID
          }
        }}
      />

    } else if (!this.state.buyNow) {
      return cart()
    } else {

      console.log(this.props.location.state.userID)
      return <Redirect
        to={{
          pathname: "/paymentDetails",
          state: {
            cartID: this.props.location.state.cartID,
            hva: this.props.location.state.hva,
            leaderID: this.props.location.state.leaderID,
            referralID: this.props.location.state.referral,
            POD: this.props.location.state.POD,
            signedUp: this.props.location.state.signedUp,
            userID: this.props.location.state.userID,
            groupBuy: false,
            gbID: ""
          }
        }}
      />
    }
  }
};

export default Cart
