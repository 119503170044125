import React, { useContext, useState } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  withRouter
} from "react-router-dom"
import { Overlay, Hamburger } from "../components"
import MenuOverlay from "../containers/MenuOverlay"
import { UserContext } from "../contexts/userContext"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import styled from "styled-components"
import { metrics } from "../themes"
import Logo from "../components/logo.png"

const Header = styled.div`
  transform: translateY(${props => "-" + props.scrollTop}px);
  z-index: 30;
  position: relative;
  flex:1
  background-color: #fe7c19;
  width: 100%;
  height: ${metrics.headerHeight - 1}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.inactive};

`

const HeaderInner = styled.div`
  width: ${metrics.bodyWidth}px;
  padding: 0px ${metrics.baseUnit * 2}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: inline-flex;
  }
`

const CompanyLogo = styled.button`
  color: ${props => props.theme.mainText};
  background-color: transparent;
  pointer-events: ${props => (props.samePath ? "none" : "initial")};
  border: 0;
  outline: none;
  padding: 0;
  align-items: center;
  cursor: pointer;
  outline: 0;
  font-size: ${metrics.smallText}px;
`

const HeaderWithRouter = props => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [back, setBack] = useState(null)
  const { userState } = useContext(UserContext)
  const userId = userState.userId
  const cartId = userState.cartId
  const cartItems = userState.cartItems
  const cartItemsQty = userState.cartItemsQty
  const leaderId = userState.leaderId

  const pushTo = path => {
    props.location.pathname !== path && props.history.push(path)
  }

  const toggleMenu = () => {
    back ? setBack(false) : setBack(true)
  }

  const handleGoBack = () => {

    console.log("OYAA")

    console.log(leaderId)

    toggleMenu()

    setTimeout(() => {

      setBack(false)

    }, 3000)

  }

  const menuButtonState = () => {
    return cartId === null ? <ArrowBackIosIcon onClick={e => { window.history.back() }} /> : <ArrowBackIosIcon onClick={handleGoBack} />
  }


  const menu = () => {
    return (
      <Overlay>
        <MenuOverlay setMenuOpen={() => setMenuOpen(false)} />
      </Overlay>
    )
  }

  return (
    <>
      {menuOpen && menu()}
      <Header {...props}>
        <HeaderInner>
          {menuButtonState()}
          {back ?
            <Redirect
              to={{
                pathname: "/addItems/" + leaderId,
                state: {
                  cartItems: cartItems,
                  cartItemsQty: cartItemsQty,
                  cartID: cartId,
                  signedUp: true,
                  userId: userId
                }
              }}
            />
            :
            <></>
          }
          <CompanyLogo
          // onClick={() => (userId ? pushTo("/dashboard") : pushTo("/"))}
          >
            <img src={Logo} style={{ alignSelf: "center", width: 100 }} />
          </CompanyLogo>
        </HeaderInner>
      </Header>
    </>
  )
}

export default withRouter(HeaderWithRouter)
